import { backgroundColorSchemeMap } from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';

export const visitDetailsWrapper = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 60px',
  minWidth: '1420px',
};

export const visitDetailsTitleWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  mt: '60px',
  mb: '20px',
};

export const visitDetailsVisitStatusChangingConfirmationWrapperStyles = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: '20px',
  marginBottom: '16px',
  backgroundColor: COLORS.yellow[200],
  zIndex: 1,
};

export const visitDetailsTitleStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  height: '40px',
  '& img': { width: '20px', height: '20px' },
};

export const visitCaregiverAndDateWrapper = {
  display: 'flex',
  gap: '24px',
  paddingBottom: '20px',
  borderBottom: `1px solid ${COLORS.blue[200]}`,
  '& .MuiAutocomplete-root': {
    width: '240px',
  },
};

export const visitTasksManagementWrapper = {
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  mt: '40px',
};

export const tasksManagementTableStyles = isEmpty => [
  {
    '& .MuiDataGrid-virtualScroller': {
      maxHeight: '100%',
      padding: isEmpty ? '32px 0' : '1px',
      overflowX: 'auto !important',
      overflowY: 'auto !important',
    },
    '& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-hovered': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell': {
      padding: '26px 21px 24px 21px',
    },
    '& .MuiDataGrid-footerContainer': {
      margin: 0,
    },
  },
];

export const getTaskActionsStyles = () => ({
  width: '40px',
  height: '40px',
  border: `1px solid ${COLORS.blue[200]}`,
  borderRadius: '10px',
  opacity: 1,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: COLORS.blue[50],
  },
  '&.Mui-disabled': {
    '&:hover': {
      backgroundColor: '#ffffff',
    },
    opacity: 0.6,
    cursor: 'default',
  },
});

const colorScheme = {
  error: backgroundColorSchemeMap.errorAlert,
  success: backgroundColorSchemeMap.active,
  warning: backgroundColorSchemeMap.warning,
};

export const getAlertStyles = (outlined, type) => ({
  display: 'flex',
  justifyContent: ['success'].includes(type) ? 'center' : 'start',
  alignItems: 'start',
  backgroundColor:
    outlined && type === 'warning' ? 'transparent' : colorScheme[type].backgroundColor,
  borderRadius: '10px',
  mb: '24px',
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  border: outlined && type === 'warning' ? `1px solid ${COLORS.yellow[700]}` : 'none',
  color: colorScheme[type].color,
  '& .MuiAlert-icon': {
    display: 'flex',
    alignItems: 'center',
    height: '42px',
  },
});

export const disabledButton = {
  '&.MuiButton-root.Mui-disabled': {
    backgroundColor: 'transparent',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};

export const tableActionsWrapper = {
  display: 'flex',
  gap: '16px',
  mt: '4px',
  height: 'fit-content',
};
