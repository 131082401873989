import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { ClientProfilesContext } from '../../../../ClientDetails/context';
import React, { useContext } from 'react';
import {
  DATE_ONLY_STANDARD_FORMAT,
} from '../../../../../shared/constants';

export default function TeamsInfo() {
  const { clientData } = useContext(ClientProfilesContext);
  const valuePlaceholder = clientData?.teamDetails?.name || 'No Team';;

  return (
    <Box
      sx={{ display: 'flex', maxWidth: '600px', flexWrap: 'wrap', gap: 1, flexDirection: 'column' }}
    >
      <Box>
        <Typography variant="body1" sx={{ fontStyle: clientData?.teamDetails?.name ? 'normal' : 'italic' }}>
          {valuePlaceholder}
        </Typography>
      </Box>
      {clientData?.pendingChanges?.team && (
        <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column', marginTop: '10px' }}>
          {Object.keys(clientData?.pendingChanges?.team).map(date => (
            <Box sx={{ display: 'flex', gap: 1, flexDirection: 'row' }} key={date}>
              <Typography variant="body1">
                {moment(date).format(DATE_ONLY_STANDARD_FORMAT)}:
              </Typography>
              {clientData?.pendingChanges?.team[date]?.length ? (
                <Typography key={date} sx={{ marginRight: '2px' }} variant="body1">
                  {clientData?.pendingChanges?.teamDetails?.[date]?.name}
                </Typography>
              ) : (
                <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                  {valuePlaceholder}
                </Typography>
              )}
            </Box>
          ))}
        </Box>
        )}
    </Box>
  );
}
