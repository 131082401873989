import { Box, Button } from '@mui/material';
import { useGetWeekendsSchedulingDaysQuery } from '../../../../../../../../../../api/Administration/api';
import { api } from '../../../../../../../../../../api';
import { useGetConfigQuery } from '../../../../../../../../../../api/Config/api';
import AvailabilityLoader from '../../../../../../../../../../pages/ClientDetails/components/AvailabilityLoader';
import AvailabilityWindowsBody from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/AvailabilityWindowsBody';
import CheckAvailabilitySchedulePattern from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/CheckAvailabilitySchedulePattern';
import {
  getRecurrentAvailabilityContentWrapperStyles,
  recurrentAvailabilityWrapper,
} from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/RecurrentAvailability/styles';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../../../components/Alert';
import { checkAvailabilityWrapperStyles } from '../styles';
import ConstantTableItem from './ArrivalWindowsTableItmes/ConstantTableItem';
import { WebSocketContext } from '../../../../../../../../../../components/WebsocketProvider/WebsocketProvider';

function RecurrentAvailability({
  availabilityScheduling,
  clearSelectedTimeSlots = () => {},
  errorData = '',
  setAvailabilityScheduling = () => {},
  setErrorData = () => {},
  setStepDataLoading = () => {},
  setTemporaryVisitData = () => {},
  setVisitData = () => {},
  temporaryVisitData = {},
  updatedCareProgram = [],
  visitData = {},
  visitSummaryHeight = 0,
  effectiveDate = '',
  setCompletedSteps = () => {},
}) {
  const [subscribe, unsubscribe] = useContext(WebSocketContext);
  const { setAlert } = useContext(AlertContext);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [operationId, setOperationId] = useState(null);

  useEffect(() => {
    setCompletedSteps([0]);
    setTemporaryVisitData(visitData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkDb = async () => {
    const payload = {
      id: operationId,
    };
    const apiData = await api('GET', 'crud', 'checksData', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      if (apiData?.data?.status === 'finished') {
        setAvailabilityData(apiData?.data?.data);
        setIsLoading(false);
      }
    }
  };

  const getData = async () => {
    const payload = {
      careProgram: {
        ...visitData,
        clientId: id,
        effectiveDateStart: effectiveDate.slice(0, 11) + '00:00:00',
        days: Object.keys(temporaryVisitData.days).map(day => parseInt(day)),
      },
      action: 'startAvailabilty',
    };
    const apiData = await api('POST', 'check', 'careProgram', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setOperationId(apiData?.data?.operationId);
    }
  };

  useEffect(() => {
    if (!operationId) {
      getData();
    }
    // eslint-disable-next-line
  }, [operationId]);

  const {
    data: filtersData,
    isLoading: isFiltersLoading,
    error: getFiltersDataError,
  } = useGetConfigQuery(['timeOfDays']);
  const { data: disabledWeekDays, error: getWeekdaysError } = useGetWeekendsSchedulingDaysQuery();

  useEffect(() => {
    if (getFiltersDataError || getWeekdaysError) {
      const errorRequestData = getFiltersDataError || getWeekdaysError;
      setAlert({
        errorData: errorRequestData,
        type: 'error',
      });
    }
  }, [getFiltersDataError, getWeekdaysError, setAlert]);

  useEffect(() => {
    if (availabilityData) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [setAvailabilityScheduling, availabilityData]);

  useEffect(() => {
    if (!availabilityScheduling) {
      setAvailabilityScheduling(availabilityData);
    }
  }, [availabilityData, availabilityScheduling, setAvailabilityScheduling]);

  useEffect(() => {
    setStepDataLoading(isLoading);
  }, [isLoading, setStepDataLoading]);

  const handleChangeDays = e => {
    if (errorData) {
      setErrorData(null);
    }
    const name = e.target.name;
    let tempDays = { ...temporaryVisitData.days };
    const keys = Object.keys(tempDays);
    if (keys.includes(String(name))) {
      delete tempDays[name];
    } else {
      tempDays[name] = {};
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      days: tempDays,
    });
    return false;
  };

  const handleChangeTimeOfDay = e => {
    if (errorData) {
      setErrorData(null);
    }
    const { name } = e.target;
    let timesOfDaysDetails = [...temporaryVisitData.timesOfDaysDetails];
    const dayNames = timesOfDaysDetails.map(({ name }) => name);
    if (dayNames.includes(name)) {
      timesOfDaysDetails = timesOfDaysDetails.filter(item => item.name !== name);
    } else {
      timesOfDaysDetails.push(filtersData.timeOfDays.find(item => item.name === name));
    }
    setTemporaryVisitData({
      ...temporaryVisitData,
      timesOfDays: timesOfDaysDetails.map(({ id }) => id),
      timesOfDaysDetails: timesOfDaysDetails,
    });
    return false;
  };

  const recheckAvailability = async () => {
    setIsLoading(true);
    setVisitData(temporaryVisitData);
    clearSelectedTimeSlots();
    setAvailabilityData(null);
    setAvailabilityScheduling(null);
    setOperationId(null);
  };

  const tableHeaderItems = useMemo(() => ['Arrival Window', 'Availabile'], []);

  const tableContentStyles = useMemo(
    () => getRecurrentAvailabilityContentWrapperStyles(visitSummaryHeight),
    [visitSummaryHeight],
  );

  useEffect(() => {
    subscribe('checkAvailability', messageData => {
      if (messageData.operationId === operationId) {
        setAvailabilityData(messageData.data);
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe('checkAvailability');
    };
  // eslint-disable-next-line
  }, [subscribe, unsubscribe]);

  return (
    <Box sx={checkAvailabilityWrapperStyles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CheckAvailabilitySchedulePattern
          disabledRecheckAvailability={
            !Object.keys(temporaryVisitData?.days)?.length ||
            !temporaryVisitData?.timesOfDays?.length ||
            isLoading
          }
          filtersData={filtersData}
          disabledDays={disabledWeekDays?.schema}
          handleChangeDays={handleChangeDays}
          handleChangeTimeOfDay={handleChangeTimeOfDay}
          isFiltersLoading={isFiltersLoading}
          isLoading={isLoading}
          recheckAvailability={recheckAvailability}
          visitData={temporaryVisitData}
        />
        {isLoading ? (
          <>
            <Button onClick={checkDb}>Refresh</Button>
            <AvailabilityLoader />
          </>
        ) : (
          <Box sx={recurrentAvailabilityWrapper}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              {tableHeaderItems.map((item, index) => (
                <ConstantTableItem
                  key={item}
                  isArrivalWindowItem={!index}
                  isHeader
                  name={item}
                  showDivider={!!index && tableHeaderItems.length - 1 !== index}
                />
              ))}
            </Box>
            <Box sx={tableContentStyles}>
              {availabilityScheduling?.timeOfDays && <AvailabilityWindowsBody />}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

RecurrentAvailability.propTypes = {
  availabilityScheduling: instanceOf(Object),
  clearSelectedTimeSlots: func,
  errorData: string,
  setAvailabilityScheduling: func,
  setErrorData: func,
  setStepDataLoading: func,
  setTemporaryVisitData: func,
  setVisitData: func,
  temporaryVisitData: instanceOf(Object),
  updatedCareProgram: instanceOf(Object),
  visitData: instanceOf(Object),
  visitSummaryHeight: number,
  effectiveDate: string,
  setCompletedSteps: func,
};

export default RecurrentAvailability;
