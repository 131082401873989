import { COLORS } from '../../shared/styles/Theme';

export const customTableRowStyles = [
  {
    '& .MuiDataGrid-row.has-no-team': {
      background: COLORS.red[100],
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowX: 'auto !important',
    },
  },
];

export const tableRowOverflowHidden = {
  display: 'flex',
  overflow: 'hidden',
};

export const tableRowOverflowEllipsis = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};
