import { Box } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router';
import CustomSelect from '../../../../../components/Select';
import { detailsButtons } from '../../../constants';
import { schedulingToolbarTableWrapper, selectTabStyles } from '../../styles';
import Filters from './Filters';

export default function FiltersPanel({
  debouncedSearch = () => {},
  filterData = {},
  setFilterData = () => {},
  setTeamFilter = () => {},
  teamsArray = [],
  teamsFilter = {},
}) {
  const navigate = useNavigate();
  return (
    <Box sx={schedulingToolbarTableWrapper}>
      <Filters
        debouncedSearch={debouncedSearch}
        filterData={filterData}
        setFilterData={setFilterData}
        teamsArray={teamsArray}
        teamsFilter={teamsFilter}
        setTeamFilter={setTeamFilter}
      />
      <CustomSelect
        id="scheduling"
        isLoading={false}
        addStyles={selectTabStyles}
        isMultiselect={false}
        options={detailsButtons}
        selectedValue={"PTO & Callouts"}
        setValue={(param) =>
          navigate(`/scheduling/${param.name.toLowerCase()}`)
        }
      />
    </Box>
  );
}

FiltersPanel.propTypes = {
  debouncedSearch: func,
  filterData: instanceOf(Object),
  setFilterData: func,
  setTeamFilter: func,
  teamsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
};
