import moment from 'moment';
import { ScheduleCaregiverManagementContext } from '../../../../pages/Scheduling/context';
import { instanceOf, oneOfType, string, func } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DATE_ONLY_STANDARD_FORMAT } from '../../../../shared/constants';
import { roundUpFifteenMinutes } from '../../../../shared/utils/common';
import AddOneTimeAvailability from './AddOneTimeAvailability';
import CreateCallOut from './CreateCallOut';
import { callOutInitialState, callOutSteps } from './constants';

export default function ScheduleCaregiverManagementProvider({
  calloutDate = '',
  children = [] || {},
  caregiversList = [],
  reFetchCalendarEvents = () => {},
}) {
  const [addingCallOutStep, setAddingCallOutStep] = useState(null);
  const [callOutData, setCallOutData] = useState(callOutInitialState);
  const [showOneTimeAvailabilityData, setShowOneTimeAvailabilityData] = useState(null);
  const [chosenCaregiver, setChosenCaregiver] = useState();

  const updateChosenCaregiver = useCallback(
    caregiver => {
      setChosenCaregiver(caregiver);
      const isFutureDateCallout = moment().isBefore(
        moment(calloutDate, DATE_ONLY_STANDARD_FORMAT),
        'days',
      );
      setCallOutData({
        ...callOutInitialState,
        callOutStartTime: isFutureDateCallout ? null : roundUpFifteenMinutes(moment()),
        callOutStartDate: moment(calloutDate, DATE_ONLY_STANDARD_FORMAT),
        startingNow: !isFutureDateCallout,
        caregiver: caregiver,
      });
    },
    [calloutDate],
  );

  const addCallOut = useCallback(
    () => setAddingCallOutStep(callOutSteps.addCallOut),
    [setAddingCallOutStep],
  );

  const showPossibilityView = () => setAddingCallOutStep(callOutSteps.checkPossibility);

  const addOneTimeAvailability = useCallback(data => {
    setShowOneTimeAvailabilityData({
      caregiverId: data.id,
    });
  }, []);

  const closeOneTimeAvailability = useCallback(() => {
    setShowOneTimeAvailabilityData(null);
  }, []);

  const closeCallOutCreation = useCallback(() => {
    setAddingCallOutStep(null);
  }, []);

  const memoizedProviderValue = useMemo(
    () => ({
      addCallOut,
      addOneTimeAvailability,
      closeOneTimeAvailability,
      addingCallOutStep,
      callOutData,
      calloutDate,
      closeCallOutCreation,
      setCallOutData,
      showOneTimeAvailabilityData,
      showPossibilityView,
      chosenCaregiver,
      setChosenCaregiver,
      caregiversList,
      reFetchCalendarEvents,
      updateChosenCaregiver,
    }),
    [
      caregiversList,
      addCallOut,
      addOneTimeAvailability,
      closeOneTimeAvailability,
      addingCallOutStep,
      callOutData,
      calloutDate,
      closeCallOutCreation,
      showOneTimeAvailabilityData,
      chosenCaregiver,
      reFetchCalendarEvents,
      updateChosenCaregiver,
    ],
  );
  return (
    <ScheduleCaregiverManagementContext.Provider value={memoizedProviderValue}>
      <CreateCallOut />
      {showOneTimeAvailabilityData && <AddOneTimeAvailability />}
      {children}
    </ScheduleCaregiverManagementContext.Provider>
  );
}

ScheduleCaregiverManagementProvider.propTypes = {
  calloutDate: string,
  children: oneOfType([instanceOf(Array), instanceOf(Object)]),
  reFetchCalendarEvents: func,
};
