import { Box, Button } from '@mui/material';
import CallOutIcon from '../../../../../../../img/icons/callout.png';
import ClockIcon from '../../../../../../../img/icons/clock-icon.svg';
import moment from 'moment';
import { func, instanceOf, string } from 'prop-types';
import React, { useMemo } from 'react';
import { H12_TIME_FORMAT } from '../../../../../../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../../../../shared/utils/common';
import { v4 as uuidv4 } from 'uuid';
import ArrivalWindow from '../ArrivalWindow';
import CaregiverInfo from '../CaregiverInfo';
import Holiday from '../Holidays';
import TimeRange from '../TimeRange';
import ContentWrapper from './Wrapper';
import { dialogContentWrapper, textButtonStyles } from './styles';

const iconsMap = {
  availabilities: ClockIcon,
  callout: CallOutIcon,
  timeOff: CallOutIcon,
};

export default function SettingsView({
  date = '',
  oneTimeAvailabilityData = {},
  setOneTimeAvailabilityData = () => {},
  setHasErrors = () => {},
  isLoading = false,
}) {
  const addDateRange = (params) => {
    const periods = oneTimeAvailabilityData?.oneTimeAvailabilities
      ? [...oneTimeAvailabilityData?.oneTimeAvailabilities]
      : [];
    const periodIndex = periods.findIndex(
      ({ temporaryId }) => temporaryId === params.temporaryId,
    );
    periods[periodIndex] = params;
    setOneTimeAvailabilityData({
      ...oneTimeAvailabilityData,
      oneTimeAvailabilities: periods,
    });
  };

  const deleteRange = (id) => {
    setOneTimeAvailabilityData({
      ...oneTimeAvailabilityData,
      oneTimeAvailabilities: oneTimeAvailabilityData.oneTimeAvailabilities?.filter(
        ({ temporaryId }) => temporaryId !== id,
      ),
    });
    setHasErrors(false);
  };

  const addAnotherTimeRange = () =>
    setOneTimeAvailabilityData({
      ...oneTimeAvailabilityData,
      oneTimeAvailabilities: [
        ...oneTimeAvailabilityData?.oneTimeAvailabilities,
        { temporaryId: uuidv4() },
      ],
    });

  const periodsList = useMemo(
    () => oneTimeAvailabilityData?.oneTimeAvailabilities,
    [oneTimeAvailabilityData],
  );

  const oneTimeAvailabilities = useMemo(() => {
    const alreadyCreatedAvailabilities =
      oneTimeAvailabilityData?.oneTimeAvailabilities?.filter(
        (availability) => !availability.temporaryId,
      );
    const newAvailabilities =
      oneTimeAvailabilityData?.oneTimeAvailabilities?.filter(
        (availability) => availability.temporaryId,
      );
    return {
      alreadyCreatedAvailabilities,
      newAvailabilities,
    };
  }, [oneTimeAvailabilityData]);
  return (
    <ContentWrapper>
      <Box sx={dialogContentWrapper}>
        <CaregiverInfo 
          caregiverName={`${oneTimeAvailabilityData?.firstName || ''} ${oneTimeAvailabilityData?.lastName || ''}`.trim()}
          date={date}
        />
        {!!oneTimeAvailabilityData?.holidays?.length &&
          oneTimeAvailabilityData?.holidays?.map(({ id, name }) => (
            <Holiday key={id} name={name} />
          ))}
        {!!oneTimeAvailabilityData?.availability && (
          <TimeRange
            endTime={moment(
              oneTimeAvailabilityData?.availability?.endTime,
              H12_TIME_FORMAT,
            )}
            icon={iconsMap.availabilities}
            startTime={moment(
              oneTimeAvailabilityData?.availability?.startTime,
              H12_TIME_FORMAT,
            )}
            title="Basic Availability"
          />
        )}
        {!!oneTimeAvailabilities?.alreadyCreatedAvailabilities?.length &&
          oneTimeAvailabilities?.alreadyCreatedAvailabilities?.map(
            ({ endTime, startTime }) => (
              <TimeRange
                key={`${startTime}-${endTime}`}
                endTime={moment(endTime, H12_TIME_FORMAT)}
                icon={iconsMap.availabilities}
                startTime={moment(startTime, H12_TIME_FORMAT)}
                title="One-time Availability"
              />
            ),
          )}
        {!!oneTimeAvailabilityData?.callouts?.length &&
          oneTimeAvailabilityData?.callouts
            ?.filter(({ status }) => status?.toLowerCase() !== 'cancelled')
            ?.map(({ requestEnd, requestStart, id }) => (
              <TimeRange
                key={id}
                endTime={convertUtcToTeamTimeZoneMomentObject(requestEnd)}
                icon={iconsMap.callout}
                startTime={convertUtcToTeamTimeZoneMomentObject(requestStart)}
                title="Callout"
              />
            ))}
        {!!oneTimeAvailabilityData?.timeOffs?.length &&
          oneTimeAvailabilityData?.timeOffs
            ?.filter(({ status }) => status?.toLowerCase() !== 'cancelled')
            ?.map(({ requestEnd, requestStart, id }) => (
              <TimeRange
                key={id}
                endTime={convertUtcToTeamTimeZoneMomentObject(requestEnd)}
                icon={iconsMap.timeOff}
                startTime={convertUtcToTeamTimeZoneMomentObject(requestStart)}
                title="PTO"
              />
            ))}
        {oneTimeAvailabilities?.newAvailabilities?.map(
          ({ startTime, endTime, temporaryId }) => (
            <ArrivalWindow
              key={temporaryId}
              deleteRange={deleteRange}
              endTime={endTime}
              endTimeDisabled={false}
              id={temporaryId}
              periods={periodsList}
              setHasErrors={setHasErrors}
              startTime={startTime}
              updateRange={addDateRange}
              date={date}
              isLoading={isLoading}
            />
          ),
        )}
        <Button
          variant="text"
          sx={textButtonStyles}
          disableFocusRipple
          disableRipple
          onClick={addAnotherTimeRange}
        >
          + One-Time Availability
        </Button>
      </Box>
    </ContentWrapper>
  );
}

SettingsView.propTypes = {
  date: string,
  oneTimeAvailabilityData: instanceOf(Object),
  setOneTimeAvailabilityData: func,
  setHasErrors: func,
  isLoading: instanceOf(Boolean),
};
