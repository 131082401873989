export const taskInitialState = {
  categoryTypes: '',
  isCustom: false,
  days: [],
  details: '',
  levelOfAssistance: '',
  name: '',
  timeOfDays: [],
  visitTypes: [],
  priority: '',
};

export const oneTimeTaskInitialState = {
  categoryTypes: '',
  isCustom: false,
  details: '',
  levelOfAssistance: '',
  name: '',
  priority: '',
};

export const standardTasksCategory = ['Personal Care', 'Lifestyle Support', 'Household Support'];

export const carePlanTasks = [
  {
    name: 'Bathing Assistance',
    key: 'bathingAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Dressing Assistance',
    key: 'dressingAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Exercise and Stretching Support',
    key: 'exerciseAndStretchingSupport',
    category: 'Personal Care',
  },
  {
    name: 'Feeding Assistance',
    key: 'feedingAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Hair Care Assistance',
    key: 'hairCareAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Incontinence Care Assistance',
    key: 'incontinenceCareAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Mobility/Ambulation Assistance',
    key: 'mobilityAmbulationAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Nail Care Assistance',
    key: 'nailCareAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Oral Hygiene Assistance',
    key: 'oralHygieneAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Prosthetics Care Assistance',
    key: 'prostheticsCareAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Shaving Assistance',
    key: 'shavingAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Skin Care Assistance',
    key: 'skinCareAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Toileting Assistance',
    key: 'toiletingAssistance',
    category: 'Personal Care',
  },
  {
    name: 'Transfer Assistance',
    key: 'transferAssistance',
    category: 'Personal Care',
  },

  // catergori2
  {
    name: 'Change Bed Linens',
    key: 'changeBedLinens',
    category: 'Household Support',
  },
  {
    name: 'Clean Floors',
    key: 'cleanFloors',
    category: 'Household Support',
  },
  {
    name: 'Clean out the Refrigerator',
    key: 'cleanOutTheRefrigerator',
    category: 'Household Support',
  },
  {
    name: 'Clean the Bathroom',
    key: 'cleanTheBathroom',
    category: 'Household Support',
  },
  {
    name: 'Clean the Kitchen',
    key: 'cleanTheKitchen',
    category: 'Household Support',
  },
  {
    name: 'Dust Furniture and Surfaces',
    key: 'dustFurnitureAndSurfaces',
    category: 'Household Support',
  },
  {
    name: 'General Housekeeping',
    key: 'generalHousekeeping',
    category: 'Household Support',
  },
  {
    name: 'Gardening Support',
    key: 'gardeningSupport',
    category: 'Household Support',
  },
  {
    name: 'Laundry',
    key: 'laundry',
    category: 'Household Support',
  },
  {
    name: 'Make the Bed',
    key: 'makeTheBed',
    category: 'Household Support',
  },
  {
    name: 'Mail Organization',
    key: 'mailOrganization',
    category: 'Household Support',
  },
  {
    name: 'Organize & Tidy',
    key: 'organizeAndTidy',
    category: 'Household Support',
  },
  {
    name: 'Organize Closets',
    key: 'organizeClosets',
    category: 'Household Support',
  },
  {
    name: 'Prepare Grocery List',
    key: 'prepareGroceryList',
    category: 'Household Support',
  },
  {
    name: 'Take out the Trash',
    key: 'takeOutTheTrash',
    category: 'Household Support',
  },
  {
    name: 'Vacuum Carpets',
    key: 'vacuumCarpets',
    category: 'Household Support',
  },
  {
    name: 'Wash & Put Away Dishes',
    key: 'washAndPutAwayDishes',
    category: 'Household Support',
  },
  {
    name: 'Wash Windows',
    key: 'washWindows',
    category: 'Household Support',
  },

  // 3
  {
    name: 'Accompany on a Walk',
    key: 'accompanyOnAwalk',
    category: 'Lifestyle Support',
  },
  {
    name: 'Assist with Reading and Writing',
    key: 'assistWithReadingAndWriting',
    category: 'Lifestyle Support',
  },
  {
    name: 'Bill Paying Assistance',
    key: 'billPayingAssistance',
    category: 'Lifestyle Support',
  },
  {
    name: 'Companionship and Conversation',
    key: 'companionshipAndConversation',
    category: 'Lifestyle Support',
  },
  {
    name: 'Drive to Appointments/Errands',
    key: 'driveToAppointmentsErrands',
    category: 'Lifestyle Support',
  },
  {
    name: 'Encourage Fluids',
    key: 'encourageFluids',
    category: 'Lifestyle Support',
  },
  {
    name: 'Engagement in Recreational Activities',
    key: 'engagementInRecreationalActivities',
    category: 'Lifestyle Support',
  },
  {
    name: 'Errand Assistance',
    key: 'errandAssistance',
    category: 'Lifestyle Support',
  },
  {
    name: 'Grocery Shopping',
    key: 'groceryShopping',
    category: 'Lifestyle Support',
  },
  {
    name: 'Light Gardening Assistance',
    key: 'lightGardeningAssistance',
    category: 'Lifestyle Support',
  },
  { name: 'Meal Delivery', key: 'mealDelivery', category: 'Lifestyle Support' },
  {
    name: 'Meal Preparation',
    key: 'mealPreparation',
    category: 'Lifestyle Support',
  },
  {
    name: 'Medication Reminder',
    key: 'medicationReminder',
    category: 'Lifestyle Support',
  },
  {
    name: 'Monitor Diet and Eating Habits',
    key: 'monitorDietAndEatingHabits',
    category: 'Lifestyle Support',
  },
  {
    name: 'Monitor Safety in Home',
    key: 'monitorSafetyInHome',
    category: 'Lifestyle Support',
  },
  {
    name: 'Pet Care Assistance',
    key: 'petCareAssistance',
    category: 'Lifestyle Support',
  },
  {
    name: 'Schedule Appointments Assistance',
    key: 'scheduleAppointmentsAssistance',
    category: 'Lifestyle Support',
  },
  {
    name: 'Take Blood Pressure',
    key: 'takeBloodPressure',
    category: 'Lifestyle Support',
  },
  { name: 'Take Pulse Ox', key: 'takePulseOx', category: 'Lifestyle Support' },
  {
    name: 'Technology Assistance',
    key: 'technologyAssistance',
    category: 'Lifestyle Support',
  },
  {
    name: 'Transportation',
    key: 'transportation',
    category: 'Lifestyle Support',
  },
];
