import { TabList } from '@mui/lab';
import { Box, Tab, Typography, Button } from '@mui/material';
import { func } from 'prop-types';
import React, { useState, useContext } from 'react';
import { REPORTING_TABS } from '../../constants';
import { useDownloadClientReportMutation } from '../../../../../src/api/Reporting/api';
import AlertContext from '../../../../components/Alert';
import * as XLSX from 'xlsx';

export default function ReportingTitle({ handleChange = () => {} }) {
  const { setAlert } = useContext(AlertContext);
  const [downloadClientReport, { data, error, isLoading, isError }] = useDownloadClientReportMutation();

  const generateExcel = () => {
    downloadClientReport()
      .then(response => {
        const encodedCsvData = response.data.csvContent;
        const csvData = atob(encodedCsvData);
  
        const worksheet = XLSX.utils.aoa_to_sheet(CSVToArray(csvData));
  
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
  
        const excelData = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
  
        const blob = new Blob([s2ab(excelData)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = 'caregiverClientReport.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error("Error generating report:", error);
        const errorMessage = error?.data?.message || 'An unexpected error occurred.';
        
        setAlert({
          message: errorMessage,
          type: 'error',
        });
      });
  };
  
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  };
  
  const CSVToArray = (csv) => {
    const lines = csv.split("\n");
    return lines.map(line => line.split(","));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        mb: '32px',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography variant="h1">Reporting</Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={generateExcel}
          sx={{ ml: '28px' }}
          disableElevation
          disabled={isLoading}
        >
          {isLoading ? 'Downloading...' : 'DOWNLOAD CLIENT REPORT'}
        </Button>
      </Box>

      <TabList
        onChange={handleChange}
        sx={{ display: 'flex', '& .MuiTabs-flexContainer': { gap: '24px' } }}
      >
        {REPORTING_TABS.map(({ key, title }) => (
          <Tab disableRipple key={`${title}-${key}`} label={title} value={key} />
        ))}
      </TabList>
    </Box>
  );
}

ReportingTitle.propTypes = {
  handleChange: func,
};
