import { Box, Link, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  SHORT_DATE_FORMAT,
  TIME_OFF_REQUEST_DATE_FORMAT,
  TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
  backgroundColorSchemeMap,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import RequestsActions from './RequestsActions';
import CaregiverTeams from './components/CaregiverTeams';
import { badgeAndActionsCellStyles, lastUpdateCellWrapper } from './styles';
import EditIconButton from '../../../../components/VisitInfoTypeProvider/components/EditIconButton';

export const INITIAL_FILTERS = {
  datesRange: {},
  caregiver: '',
  teams: [],
  status: [],
};

export const TIME_OFF_REQUESTS_STATUSES = ['approved', 'pending', 'denied', 'canceled'];

const PAGE_SIZE = 20;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const DEFAULT_SORTING_MODEL = { field: 'from', sort: 'asc' };

export const getBadgeColorScheme = status => {
  const statusesKeys = {
    approved: 'active',
    pending: 'partiallySuccessful',
    rejected: 'error',
  };
  const statusKey = statusesKeys[status?.toLowerCase()];
  return backgroundColorSchemeMap[statusKey];
};
export const getColumns = editPtoRequest => [
  {
    field: 'caregiver',
    headerName: 'Caregiver',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row }) => (
      <Link
        component={RouterLink}
        to={`/caregivers/${row?.caregiverId}/pto-callouts/current`}
        underline="none"
      >
        <Typography variant="body1" sx={{ color: COLORS.blue[700], cursor: 'pointer' }}>
          {row?.caregiverName}
        </Typography>
      </Link>
    ),
    sortable: false,
  },
  {
    field: 'team',
    headerName: 'Team',
    minWidth: 175,
    flex: 1,
    renderCell: ({ row }) => (
      <CaregiverTeams
        primaryTeams={row?.caregiverTeams}
        secondaryTeams={row?.caregiverSecondaryTeams}
      />
    ),
    sortable: false,
  },
  {
    field: 'dateCreated',
    headerName: 'Date Submitted',
    minWidth: 140,
    flex: 1,
    renderCell: ({ value }) => (
      <Box
        sx={{
          ...lastUpdateCellWrapper,
          justifyContent: value ? 'start' : 'center',
        }}
      >
        {value ? convertUtcToTeamTimeZoneMomentObject(value).format(SHORT_DATE_FORMAT) : '-'}
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'from',
    headerName: 'Start Date & Time',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row.allDay
        ? `${moment(row?.from)?.format(SHORT_DATE_FORMAT)} (All day)`
        : moment(row?.from)?.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'to',
    headerName: 'End Date & Time',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row.allDay
        ? `${moment(row?.to)?.format(SHORT_DATE_FORMAT)} (All day)`
        : moment(row?.to)?.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'ptoTypeDetails',
    headerName: 'Request Type',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (row?.isCallout ? 'Callout' : row?.ptoTypeDetails?.name),
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 240,
    sortable: false,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 450,
    flex: 3,
    renderCell: ({ row, value }) => (
      <Box sx={badgeAndActionsCellStyles}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Badge colorScheme={getBadgeColorScheme(row?.status)} label={row?.status} />
        </Box>
        {row.status !== 'Denied' && moment(row.from).isSameOrAfter(moment(), 'date') && (
          <EditIconButton
            onEdit={() =>
              editPtoRequest({
                ...row,
                requestDateStart: moment(row.from).set({ hours: 0, minutes: 0, seconds: 0 }),
                requestTimeStart: row.allDay === true ? undefined : moment(row.from),
                requestDateEnd: moment(row.to).set({ hours: 23, minutes: 59, seconds: 59 }),
                requestTimeEnd: row.allDay === true ? undefined : moment(row.to),
              })
            }
            tooltip="Edit PTO Request"
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RequestsActions row={row} />
        </Box>
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'lastUpdated',
    headerName: 'Last Updated',
    minWidth: 350,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={lastUpdateCellWrapper}>
        {row?.dateCreated ? (
          <Stack sx={{ width: '160px' }}>
            <Typography sx={{ maxWidth: '160px' }}>
              {row?.updatedBy ? row?.updatedBy : row?.createdByEmail ? row?.createdByEmail : 'N/A'}
            </Typography>
            <Typography sx={{ maxWidth: '100px' }}>
              {row?.dateCreated
                ? convertUtcToTeamTimeZoneMomentObject(row.dateCreated).format(
                    TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
                  )
                : ''}
            </Typography>
          </Stack>
        ) : (
          '-'
        )}
      </Box>
    ),
    sortable: false,
  },
];

export { PAGE_SIZE, PAGE_SIZE_OPTIONS };
