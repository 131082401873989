import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const VISITS_API_KEY = 'visitsApi';

const visitsApi = createApi({
  reducerPath: VISITS_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: builder => ({
    checkVisitAvailability: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/careprogram/${clientId}/availability`,
        method: 'POST',
        body: payload,
      }),
    }),
    getCarePlanTasksList: builder.query({
      query: ({ clientId }) => ({
        url: `careplan/${clientId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'carePlanTasks', id: 'ALL' }],
    }),
    checkOneTimeVisitAvailability: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/careprogram/${clientId}/one-time/availability`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: response => {
        const unAvailable = [];
        const available = response?.schedules.filter(item => {
          if (!item.available) {
            unAvailable.push(item);
          }
          return item.available;
        });

        return {
          availableSchedule: available,
          unAvailableSchedule: unAvailable,
        };
      },
    }),
    getVisitAvailableTasks: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/careplan/${clientId}/tasks`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => [{ type: 'availableTasks', id: 'ALL' }],
    }),
    getOneTimeVisitAvailableTasks: builder.query({
      query: ({ clientId, payload }) => ({
        url: `/careplan/${clientId}/tasks/one-time`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => [{ type: 'availableTasks', id: 'ALL' }],
    }),
    updateCarePlanTasks: builder.mutation({
      query: ({ clientId, payload }) => ({
        url: `careplan/${clientId}`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: () => [
        { type: 'availableTasks', id: 'ALL' },
        { type: 'clientVisits', id: 'ALL' },
        { type: 'carePlanTasks', id: 'ALL' },
      ],
    }),
    updateOneTimeVisit: builder.mutation({
      query: visit => ({
        url: `crud/visits`,
        method: 'PATCH',
        body: visit,
      }),
      invalidatesTags: () => [{ type: 'visitDetails', id: 'ALL' }],
    }),
    getVisitDetailsHistoryTasks: builder.query({
      query: (visitId) => ({
        url: `history/visits/`,
        method: 'GET',
        params: {
          index: 'id-index',
          pk: 'id',
          pkValue: visitId,
        },
      }),
      transformResponse: response => response.filter(changesData => changesData.changes !== 'null'),
      providesTags: () => [{ type: 'visitDetailsHistory', id: 'ALL' }],
    }),
  }),
});

export const {
  useCheckOneTimeVisitAvailabilityQuery,
  useCheckVisitAvailabilityQuery,
  useGetCarePlanTasksListQuery,
  useGetVisitDetailsHistoryTasksQuery,
  useGetOneTimeVisitAvailableTasksQuery,
  useGetVisitAvailableTasksQuery,
  useUpdateCarePlanTasksMutation,
  useUpdateOneTimeVisitMutation,
} = visitsApi;

export default visitsApi;
