import { Box, Typography } from '@mui/material';
import StarIcon from '../../../../img/icons/star-icon.svg';
import { bool, instanceOf } from 'prop-types';
import React from 'react';
import { teamsTooltipComponent } from '../../constants';
import { detailsInfoKeysStyles } from '../styles';
import AdditionalInfoAndStatuses from './AdditionalInfoAndStatuses';
import CaregiverInfo from './CaregiverInfo';
import DetailsWrapper from './DetailsWrapper';
import NotAllocatedHeaderComponent from './NotAllocatedHeaderComponent';
import { calculateHourColor, calculateRateColor, getHourString } from '../../utils';

function CustomHeader({
  caregiver = {},
  editMode = false,
  isDateHoliday = {},
  selectedDate = {},
  resourceStatistics = {},
}) {
  return (
    <Box sx={{ position: 'relative', height: '100%' }}>
      {caregiver.title === 'Not Allocated Visits' ? (
        <NotAllocatedHeaderComponent caregiver={caregiver} editMode={editMode} />
      ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            className="CaregiversBox"
            sx={{
              display: 'flex',
              padding: '10px 10px 10px 10px',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <CaregiverInfo caregiver={caregiver} editMode={editMode} />
              <AdditionalInfoAndStatuses
                caregiver={caregiver}
                editMode={editMode}
                isDateHoliday={isDateHoliday}
                selectedDate={selectedDate}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                ml: '8px',
              }}
            >
              {caregiver?.primaryTeamsDetails?.length > 0
                ? teamsTooltipComponent(caregiver.primaryTeamsDetails)
                : 'N/A'}
              <img src={StarIcon} alt="" />
              {caregiver?.secondaryTeamsDetails?.length > 0 &&
                teamsTooltipComponent(caregiver.secondaryTeamsDetails)}
            </Box>
            <Box className="collapsedHeader" sx={{ ml: '6px' }}>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Scheduled Hours:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: calculateHourColor(
                        resourceStatistics?.hoursScheduled,
                        resourceStatistics?.minutesScheduled,
                        480,
                        480,
                      ),
                    }}
                  >
                    {getHourString(
                      resourceStatistics?.hoursScheduled,
                      resourceStatistics?.minutesScheduled,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '2px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}>Billable Hours:</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: calculateHourColor(
                        resourceStatistics?.hoursBooked,
                        resourceStatistics?.minutesBooked,
                        resourceStatistics?.scheduledTotalMinutes * 0.7,
                        resourceStatistics?.scheduledTotalMinutes * 0.5,
                      ),
                    }}
                  >
                    {getHourString(
                      resourceStatistics?.hoursBooked,
                      resourceStatistics?.minutesBooked,
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', mb: '3px' }}>
                <Typography sx={{ ...detailsInfoKeysStyles }}> Utilization %: </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: calculateRateColor(resourceStatistics?.billableRate, 60, 75),
                  }}
                >
                  {resourceStatistics?.billableRate}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <DetailsWrapper resourceStatistics={resourceStatistics} caregiver={caregiver} />
        </Box>
      )}
    </Box>
  );
}
export default CustomHeader;

CustomHeader.propTypes = {
  caregiver: instanceOf(Object),
  isDateHoliday: instanceOf(Object),
  selectedDate: instanceOf(Object),
  editMode: bool,
  resourceStatistics: instanceOf(Object),
};
