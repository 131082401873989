export const customSortingButton = {
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  minWidth: '10px',
  maxHeight: '40px',
  padding: '0',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

export const visitTypeCellWrapper = {
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'space-between',
  gap: '24px',
  mr: '10px',
};

export const taskPriorityCellWrapper = {
  display: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
};
