import { Box } from '@mui/material';
import CustomBreadcrumbs from '../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import CustomStepper from '../../../../../../../pages/ClientDetails/components/CustomStepper';
import {
  ClientDetailsContext,
  CreateOneTimeVisitContext,
} from '../../../../../../../pages/ClientDetails/context';
import { createOneTimeVisitSteps } from '../../../../../../../pages/ClientDetails/views/Calendar/constants';
import React, { useContext, useState } from 'react';
import ErrorAlert from '../../../../../../../components/ErrorAlert';
import CheckAvailability from './CheckAvailability';
import VisitParameters from './VisitParameters';
import { createOneTimeVisitMultistepHeader, createOneTimeVisitMultistepWrapper } from './styles';
import SchedulingConflicts from './SchedulingConflicts/SchedulingConflicts';

export default function CreateOneTimeVisitMultistep() {
  const [errorData, setErrorData] = useState(null);

  const {
    closeDialog,
    completedSteps,
    isSelectedTimeOutOfRange,
    pastTimeError,
    setIsSelectedTimeOutOfRange,
    setPastTimeError,
    setStep,
    setStepDataLoading,
    setVisitParams,
    step,
    stepDataLoading,
    visitParams,
    submitStep,
    alternativeArrivalWindows,
    setAlternativeArrivalWindows,
    availabilityScheduledVisits,
    setAvailabilityScheduledVisits,
    getCalendarData,
    setManualLoading,
    handleChangesAdded,
  } = useContext(CreateOneTimeVisitContext);

  const { resetToInitialView } = useContext(ClientDetailsContext);

  const getStepView = () => {
    if (step === 0) {
      return (
        <VisitParameters
          isSelectedTimeOutOfRange={isSelectedTimeOutOfRange}
          pastTimeError={pastTimeError}
          setIsSelectedTimeOutOfRange={setIsSelectedTimeOutOfRange}
          setPastTimeError={setPastTimeError}
          setVisitParams={setVisitParams}
          visitParams={visitParams}
        />
      );
    }
    if (step === 1) {
      return (
        <SchedulingConflicts
          alternativeArrivalWindows={alternativeArrivalWindows}
          setAlternativeArrivalWindows={setAlternativeArrivalWindows}
          setStepDataLoading={setStepDataLoading}
          setVisitData={setVisitParams}
          visitData={visitParams}
          setAvailabilityScheduledVisits={setAvailabilityScheduledVisits}
        />
      );
    }
    if (step === 2) {
      return (
        <CheckAvailability
          setStepDataLoading={setStepDataLoading}
          submitStep={submitStep}
          visitParams={visitParams}
          resetToInitialView={resetToInitialView}
          setVisitParams={setVisitParams}
          setStep={setStep}
          availabilityScheduledVisits={availabilityScheduledVisits}
          getCalendarData={getCalendarData}
          setManualLoading={setManualLoading}
        />
      );
    }
    return false;
  };

  return (
    <Box sx={createOneTimeVisitMultistepWrapper}>
      <Box sx={createOneTimeVisitMultistepHeader}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          resetToInitialView={resetToInitialView}
          title="Add Visit"
          handleChangesAdded={handleChangesAdded}
        />
        <CustomStepper
          changeStep={setStep}
          completedSteps={completedSteps}
          currentStep={step}
          disabled={stepDataLoading}
          steps={createOneTimeVisitSteps}
          disabledStepNavigation={step > 1}
        />
      </Box>
      {!!errorData && (
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          <ErrorAlert error={errorData} closeError={() => setErrorData(null)} />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{getStepView()}</Box>
    </Box>
  );
}
