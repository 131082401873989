import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Box, Button } from '@mui/material';
import {
  CaregiverDetailsContext,
  CaregiverProfileContext,
} from '../../../../../pages/CaregiverDetails/context';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { customEditButton } from './styles';

export default function ProfileActions() {
  const { tabSubView } = useParams();
  const {
    handleEditClick,
    hasChanges,
    profileData,
    updateCaregiverData,
    pendingTeamChanges,
    setPendingTeamChanges,
  } = useContext(CaregiverProfileContext);
  const { isEdit, onCloseProfileEditing } = useContext(CaregiverDetailsContext);

  return (
    tabSubView === 'current' && (
      <Box>
        {!isEdit ? (
          <Button
            color="secondary"
            disabled={!profileData}
            onClick={handleEditClick}
            startIcon={<EditTwoToneIcon />}
            sx={customEditButton}
            variant="outlined"
          >
            EDIT
          </Button>
        ) : (
          <Box>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setPendingTeamChanges({});
                onCloseProfileEditing();
              }}
              disableElevation
            >
              CANCEL
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => updateCaregiverData(profileData)}
              sx={{ ml: '28px' }}
              disableElevation
              disabled={!hasChanges && !Object.keys(pendingTeamChanges ?? {}).length}
            >
              SAVE
            </Button>
          </Box>
        )}
      </Box>
    )
  );
}
