import { Box, Typography } from '@mui/material';
import carePlanIcon from '../../../../img/icons/visitTypes/repeatedVisit.svg';
import React from 'react';
import { TASKS_TYPES, tasksTypeIcons } from '../../../../shared/constants';
import CustomTooltip from '../../../../components/CustomTooltip';
import StatusAction from './StatusAction';
import TaskActions from './TaskActions';
import TaskDetails from './TaskDetails';
import { taskPriorityCellWrapper } from '../../../../pages/ClientDetails/views/CarePlan/components/styles';
import Badge from '../../../Badge';
import {
  getBadgeColorScheme,
  priorityOptions,
} from '../../../../pages/ClientDetails/views/CarePlan/constants';

export const getTasksColumns = tasksType => [
  {
    field: 'name',
    headerName: 'Task',
    minWidth: 300,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        {tasksType === TASKS_TYPES.careProgramTasks && (
          <CustomTooltip title="Care Plan visit">
            <img src={carePlanIcon} alt="" />
          </CustomTooltip>
        )}
        <CustomTooltip title={row?.name}>
          <Typography
            sx={{
              fontSize: '20px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              wordBreak: 'break-word',
              lineHeight: '24px',
            }}
            variant="h4"
          >
            {row.name}
          </Typography>
        </CustomTooltip>
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'category',
    headerName: 'Category',
    minWidth: 220,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <Typography
          sx={{
            fontSize: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical',
            wordBreak: 'break-word',
            lineHeight: '20px',
          }}
          variant="h5"
        >
          {row.categoryName}
        </Typography>
      </Box>
    ),
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 340,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => <TaskDetails row={row} />,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 200,
    flex: 1,
    sortable: false,
    renderCell: ({ row, value }) => <StatusAction id={row.id} status={value} />,
  },
  {
    field: 'priority',
    headerName: 'Priority',
    minWidth: 250,
    flex: 1,
    renderCell: ({ row, value }) => (
      <Box sx={taskPriorityCellWrapper}>
        <Box sx={{ display: 'flex', height: 'min-content' }}>
          <Badge
            colorScheme={getBadgeColorScheme(row?.priority)}
            label={row?.priority ?? 'Unknown'}
          />
        </Box>
      </Box>
    ),
    sortComparator: (v1, v2) => priorityOptions.indexOf(v1) - priorityOptions.indexOf(v2),
  },
  {
    field: 'isCustom',
    headerName: 'Task Type',
    minWidth: 400,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <CustomTooltip title={row?.isCustom ? 'Custom task' : 'Care Program task'}>
          <Box
            component="img"
            src={tasksTypeIcons[row?.isCustom ? 'custom' : 'common']}
            sx={{ height: '20px', mt: '4px', mr: '20px' }}
            alt="img"
          />
        </CustomTooltip>
        <TaskActions row={row} tasksType={tasksType} />
      </Box>
    ),
    sortable: false,
  },
];
