import { Box, Typography } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  TASKS_TYPES,
  visitStatusesMap,
} from '../../../../shared/constants';
import { VisitInfoAddTaskContext, VisitInfoTypeContext } from '../../../../shared/context';
import { COLORS } from '../../../../shared/styles/Theme';
import Loader from '../../../../components/Loader';
import { constraintsIconsTypes, visitDetailsViewsMap } from '../../constants';
import { emptyPageWrapper } from '../../styles';
import ConstraintsAlert from '../ConstraintsAlert';
import CustomBreadcrumbs from './Breadcrumbs';
import Title from './Title';
import VisitDetailsInfo from './VisitDetailsInfo';
import VisitHistory from './VisitHistory';
import { visitDetailsWrapper } from './styles';
import AlertContext from '../../../Alert';
import { useGetConfigQuery } from '../../../../api/Config/api';

export default function VisitDetails() {
  const [showedTasksType, setShowedTasksType] = useState(TASKS_TYPES.careProgramTasks);
  const [showedTasks, setShowedTasks] = useState([]);
  const [detailsView, setDetailsView] = useState(visitDetailsViewsMap.details);
  const {
    closeDialog,
    initialVisitData,
    temporaryVisitData,
    updateTemporaryVisitData,
    updatingVisitDetails,
    visitInfoLoading,
  } = useContext(VisitInfoTypeContext);
  const { addNewTask } = useContext(VisitInfoAddTaskContext);

  const breadcrumbsTitle = useMemo(() => {
    const timeRange = temporaryVisitData?.isExactTime
      ? moment(temporaryVisitData?.arrivalStart).format(H12_TIME_FORMAT)
      : `${moment(temporaryVisitData?.arrivalStart).format(H12_TIME_FORMAT)} - ${moment(
          temporaryVisitData?.arrivalEnd,
        ).format(H12_TIME_FORMAT)}`;

    return `${temporaryVisitData?.visitTypeDetails?.name}, ${moment(
      temporaryVisitData?.date,
    ).format(SHORT_DATE_FORMAT)} ${timeRange}`;
  }, [
    temporaryVisitData?.arrivalStart,
    temporaryVisitData?.arrivalEnd,
    temporaryVisitData?.date,
    temporaryVisitData?.isExactTime,
    temporaryVisitData?.visitTypeDetails?.name,
  ]);

  const { setAlert } = useContext(AlertContext);

  const {
    data: configData,
    isLoading: isLoadingConfigs,
    error: configError,
  } = useGetConfigQuery(['categoryTypes'], {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (configError) {
      setAlert({
        errorData: configError,
        type: 'error',
      });
    }
  }, [configError, setAlert]);

  const setNewStatus = useCallback(
    ({ newStatus, reason }) => {
      let newData = { status: newStatus };
      if (newStatus === visitStatusesMap.inProgress) {
        newData = {
          status: newStatus,
          actualTimeStart: moment().format(H12_TIME_FORMAT),
          actualTimeEnd: moment()
            .add(temporaryVisitData?.duration, 'minutes')
            .format(H12_TIME_FORMAT),
        };
      }
      if (newStatus === visitStatusesMap.completed) {
        newData = {
          status: newStatus,
          duration: moment
            .duration(moment().diff(moment(temporaryVisitData?.actualTimeStart, H12_TIME_FORMAT)))
            .asMinutes(),
          actualTimeEnd: moment().format(H12_TIME_FORMAT),
        };
      }
      if (newStatus === visitStatusesMap.canceled) {
        newData = {
          status: newStatus,
          cancellationReason: reason,
        };
      }
      updateTemporaryVisitData(newData);
    },
    [temporaryVisitData?.duration, temporaryVisitData?.actualTimeStart, updateTemporaryVisitData],
  );

  const addVisitTask = useCallback(
    () =>
      addNewTask({
        ...temporaryVisitData,
      }),
    [addNewTask, temporaryVisitData],
  );

  useEffect(() => {
    if (temporaryVisitData?.tasks?.length) {
      if (configData?.categoryTypes) {
        if (showedTasksType === TASKS_TYPES.careProgramTasks) {
          setShowedTasks(
            temporaryVisitData.tasks
              .filter(task => task.careplanId?.length > 0)
              .map(task => {
                const taskDetails = temporaryVisitData?.taskDetails?.find(
                  taskDetails => taskDetails?.id === task.careplanId,
                );
                const linkedCategory = configData.categoryTypes.find(
                  categoryType =>
                    categoryType.id === task.categoryTypes ||
                    categoryType.id === taskDetails?.categoryTypes,
                );
                return {
                  ...taskDetails,
                  ...task,
                  categoryName: linkedCategory ? linkedCategory?.name : 'Unknown Category',
                };
              }),
          );
        } else if (showedTasksType === TASKS_TYPES.visitTasks) {
          setShowedTasks(
            temporaryVisitData.tasks
              .filter(task => !task.careplanId)
              .map(task => {
                const linkedCategory = configData.categoryTypes.find(
                  categoryType => categoryType.id === task.categoryTypes,
                );
                return {
                  ...task,
                  categoryName: linkedCategory ? linkedCategory?.name : 'Unknown Category',
                };
              }),
          );
        }
      }
    } else {
      setShowedTasks([]);
    }
  }, [
    configData?.categoryTypes,
    showedTasksType,
    temporaryVisitData?.taskDetails,
    temporaryVisitData?.tasks,
  ]);

  if (visitInfoLoading || updatingVisitDetails) {
    return (
      <Box sx={emptyPageWrapper}>
        <Loader />
      </Box>
    );
  }
  if (!temporaryVisitData?.id) {
    return (
      <Box sx={emptyPageWrapper}>
        <Typography variant="h4" style={{ color: COLORS.blue[900] }}>
          The visit you’re looking for doesn’t exist.
        </Typography>
      </Box>
    );
  }

  const isFutureVisit = moment(initialVisitData?.date, ISO_DATE_ONLY_FORMAT).isAfter(
    moment(),
    'day',
  );
  const isNotAllocatedVisit = !initialVisitData?.caregiverId;

  return (
    <Box sx={visitDetailsWrapper}>
      <CustomBreadcrumbs closeDialog={closeDialog} title={breadcrumbsTitle} />
      <Title
        cost={temporaryVisitData?.visitCost}
        detailsView={detailsView}
        disabledAll
        jobId={temporaryVisitData?.externalId}
        initialStatus={initialVisitData?.status}
        repeated={temporaryVisitData?.repeated}
        setDetailsView={setDetailsView}
        setNewStatus={setNewStatus}
        status={temporaryVisitData?.status}
        cancellationReason={temporaryVisitData?.cancellationReason}
        title={temporaryVisitData?.visitTypeDetails?.name}
        type={temporaryVisitData?.visitTypeDetails?.name?.toLowerCase()}
        isFutureVisit={isFutureVisit}
        isNotAllocatedVisit={isNotAllocatedVisit}
      />
      {initialVisitData?.violatedHardConstraints?.length > 0 && (
        <ConstraintsAlert
          alertData={{
            reasons: initialVisitData.violatedHardConstraints,
            title: 'Hard Constraints',
            type: 'error',
          }}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      {initialVisitData?.violatedSoftConstraints?.length > 0 && (
        <ConstraintsAlert
          alertData={{
            reasons: initialVisitData.violatedSoftConstraints,
            title: 'Soft Constraints',
            type: 'error',
          }}
          iconType={constraintsIconsTypes.triangle}
        />
      )}
      {detailsView === visitDetailsViewsMap.details ? (
        <VisitDetailsInfo
          addVisitTask={addVisitTask}
          initialVisitData={initialVisitData}
          setShowedTasksType={setShowedTasksType}
          showedTasks={showedTasks}
          showedTasksType={showedTasksType}
          temporaryVisitData={temporaryVisitData}
          updateTemporaryVisitData={updateTemporaryVisitData}
          isLoadingConfigs={isLoadingConfigs}
        />
      ) : (
        <VisitHistory date={initialVisitData?.date} visitId={initialVisitData.id} />
      )}
    </Box>
  );
}
