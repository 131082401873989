export const ptoEventWrapper = {
  border: 'none',
  display: 'flex',
  height: '100%',
  padding: '20px',
  cursor: 'default',
  flexDirection: 'column',
  gap: '10px',
};

export const ptoDetailContainer = {
  background: '#FFFFFF',
  color: '#000000',
  padding: '5px',
  display: 'flex',
  height: 'min-content',
  borderRadius: '4px',
};
