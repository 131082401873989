import { Box, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
// import CustomSelect from '../../../components/Select';
import { useGetTerritoryQuery, useGetTeamsQuery } from '../../../api/Administration/api';
// import SelectFilter from '../../../components/SelectFilter';
import { bool, func, instanceOf } from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { capitalizeFirstLetter } from '../../../shared/utils/common';
import AlertContext from '../../../components/Alert';
import LocationsFilter from '../../../components/FiltersComponents/LocationFilter';
import CustomSelect from '../../../components/Select';
import { detailsButtons } from '../constants';
import {
  customSwitcher,
  editModeWrapper,
  schedulingToolbarTableWrapper,
  schedulingToolbarWrapper,
  selectTabStyles,
} from './styles';

export default function SchedulingToolbar({
  editMode = false,
  isVerification = false,
  setEditMode = () => {},
  setTeamsFiler = () => {},
  setVerificationMode = () => {},
  teamsFilter = [],
  verificationMode = false,
}) {
  const { tab } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const {
    data: territories,
    error: territoriesError,
    isLoading: isTerritoriesLoading,
  } = useGetTerritoryQuery();

  const { data: teams, error: teamsError, isLoading: istTeamsLoading } = useGetTeamsQuery();

  const [location, setLocation] = useState([]);
  
  useEffect(() => {
        if (territories?.length > 0 && teams?.length > 0) {
            const tempLocations = [];
            territories.forEach(territory => {
                const territoryIsActive = !territory.status || territory.status.toLowerCase() === 'active';
    
                if (territoryIsActive) {
                    let thisTeams = [];
                    teams.forEach(team => {
                        const teamIsActive = !team.status || team.status.toLowerCase() === 'active'; 
                        if (team.territory === territory.id && teamIsActive) {
                            thisTeams.push(team);
                        }
                    });
                    if (thisTeams.length > 0) {
                        tempLocations.push({
                            ...territory,
                            teams: thisTeams,
                        });
                    }
                }
            });
            setLocation(tempLocations);
        }
      }, [territories, teams]);

  useEffect(() => {
    if (territoriesError || teamsError) {
      setAlert({
        errorData: territoriesError || teamsError,
        type: 'error',
      });
    }
  }, [territoriesError, teamsError, setAlert]);

  const locationsList = useMemo(() => [...location], [location]);

  const handleEditModeChange = event => {
    setEditMode(event.target.checked);
  };
  const handleVerificationModeChange = event => {
    setVerificationMode(event.target.checked);
  };
  const viewIsCalendar = tab === 'calendar';

  const changeTab = param => {
    return navigate(`/scheduling/${param.name.toLowerCase()}`);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {viewIsCalendar ? (
        <Box
          className="editMode"
          sx={{
            ...editModeWrapper,
            width: '100%',
          }}
        >
          <Box sx={schedulingToolbarWrapper}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <FormControlLabel
                control={
                  <Switch
                    sx={customSwitcher}
                    checked={editMode}
                    color="secondary"
                    onChange={handleEditModeChange}
                  />
                }
                label="Edit mode"
              />
              <LocationsFilter
                locationsList={locationsList}
                setFilters={setTeamsFiler}
                filters={teamsFilter}
                isLoading={isTerritoriesLoading || istTeamsLoading}
              />
            </Box>
            {!editMode && (
              <CustomSelect
                id="scheduling"
                isLoading={false}
                addStyles={selectTabStyles}
                isMultiselect={false}
                options={detailsButtons}
                selectedValue={capitalizeFirstLetter(tab)}
                setValue={changeTab}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box sx={schedulingToolbarTableWrapper}>
          <FormControlLabel
            control={
              <Switch
                sx={customSwitcher}
                checked={verificationMode}
                color="secondary"
                onChange={handleVerificationModeChange}
              />
            }
            label="Verification mode"
          />
          <CustomSelect
            id="scheduling"
            isLoading={false}
            disabled={isVerification}
            addStyles={selectTabStyles}
            isMultiselect={false}
            options={detailsButtons}
            selectedValue={capitalizeFirstLetter(tab)}
            setValue={changeTab}
          />
        </Box>
      )}
    </Box>
  );
}

SchedulingToolbar.propTypes = {
  editMode: bool,
  isVerification: bool,
  setEditMode: func,
  setTeamsFiler: func,
  setVerificationMode: func,
  teamsFilter: instanceOf(Object),
  verificationMode: bool,
};
