import { Box, Typography } from '@mui/material';
import { func, instanceOf } from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import OptimizerCheck from '../../../../../../../../components/Optimizer/OptimizerCheck';
import { COLORS } from '../../../../../../../../shared/styles/Theme';
import moment from 'moment';
import AlertContext from '../../../../../../../../components/Alert';
import { api } from '../../../../../../../../api';

function CheckAvailability({
  setStepDataLoading = () => {},
  submitStep = () => {},
  visitParams = {},
  resetToInitialView = () => {},
  setVisitParams = () => {},
  setStep = () => {},
  availabilityScheduledVisits = {},
  getCalendarData = () => {},
  setManualLoading = () => {},
}) {
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    setStepDataLoading(true);
  }, [setStepDataLoading]);

  useEffect(() => {
    if (moment(visitParams?.date).isSame(moment(), 'day')) {
      const visitToCreate =
        availabilityScheduledVisits?.[`${visitParams?.arrivalStart}-${visitParams?.arrivalEnd}`];
      if (visitToCreate) {
        saveVisit(visitToCreate);
        setVisitParams(visitToCreate);
      }
      submitStep();
    } else {
      setOpenCheckOptimizer(true);
    }
  }, []);

  const saveVisit = async payload => {
    setManualLoading(true);
    const apiData = await api('POST', 'crud', 'visits', payload);
    if (apiData.error) {
      setAlert({
        apiData,
        type: 'error',
      });
    } else {
      setManualLoading(false);
      getCalendarData();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {/* TODO: Update this? */}
      <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
        Optimized
      </Typography>
      <OptimizerCheck
        data={{
          added: [visitParams],
          edited: [],
          deleted: [],
        }}
        dataObjName="visitObj"
        submitFunction={data => {
          const visitToCreate = data?.find(visit => visit?.id === visitParams?.id);
          if (visitToCreate) {
            setVisitParams(visitToCreate);
            submitStep();
          } else {
            resetToInitialView();
          }
        }}
        cancelFunction={() => setStep(1)}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
        returnAPIData={true}
        cancelConfirmation={true}
      />
    </Box>
  );
}

CheckAvailability.propTypes = {
  setStepDataLoading: func,
  submitStep: func,
  visitParams: instanceOf(Object),
  resetToInitialView: func,
  setVisitParams: func,
  setStep: func,
  availabilityScheduledVisits: instanceOf(Object),
  getCalendarData: func,
  setManualLoading: func,
};

export default React.memo(CheckAvailability);
