export const visitsTableStyles = [
  {
    '& .MuiDataGrid-cell': {
      padding: '10px 16px',
    },
    '& .MuiDataGrid-columnHeader:first-of-type .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-virtualScroller': {
      boxSizing: 'border-box',
      maxHeight: 'calc(100vh - 420px)',
      overflowX: 'auto !important',
      overflowY: 'auto !important',
      padding: '1px',
      scrollbarColor: '#888 #f1f1f1',
      scrollbarWidth: 'thin',
    },
  },
];

export const customAccordionStyles = {
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: '0 0 20px 0',
  },
  '&:last-of-type, &.Mui-expanded:last-of-type': {
    borderRadius: 0,
  },
  '& .MuiCollapse-root': {
    width: '100%',
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const detailSectionTitle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const customAccordionSummaryStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  padding: 0,
  margin: '12px 0',
  cursor: 'pointer',
  '&.MuiAccordionSummary-root, &.MuiAccordionSummary-root.Mui-expanded': {
    minHeight: '32px',
  },
  '& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded': {
    justifyContent: 'space-between',
    margin: 0,
    paddingRight: '5px',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  borderRadius: '4px',
  width: '100%',
};

export const visitAccordionSummaryTitle = {
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  ml: '5px',
  cursor: 'pointer',
  gap: '8px',
  justifyContent: 'center',
};

export const visitAccordionDetailsContainer = {
  padding: '5px 20px 0px 20px',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  margin: 'auto',
};
