import { Box, Collapse } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../../../../../../api/Clients/api';
import moment from 'moment';
import CustomBreadcrumbs from '../../../../../../../../../pages/ClientDetails/components/Breadcrumbs';
import CustomStepper from '../../../../../../../../../pages/ClientDetails/components/CustomStepper';
import {
  ClientDetailsContext,
  CreateVisitContext,
  EditCareProgramContext,
} from '../../../../../../../../../pages/ClientDetails/context';
import AlternativeArrivalWindows from '../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/AlternativeArrivalWindows';
import VisitSummary from '../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/VisitSummary';
import { createVisitSteps } from '../../../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import AlertContext from '../../../../../../../../../components/Alert';
import ErrorAlert from '../../../../../../../../../components/ErrorAlert';
import RecurrentAvailability from './RecurrentAvailability';
import VisitParameters from './VisitParameters';
import { visitMultistepBreadcrumbs, visitMultistepWrapper } from './styles';
import { getSummaryData } from './utils';

export default function CreateVisitMultistep() {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const {
    alternativeArrivalWindows,
    availabilityScheduling,
    clearSelectedTimeSlots,
    completedSteps,
    selectedTimeSlots,
    setAlternativeArrivalWindows,
    setAvailabilityScheduling,
    setSelectedTimeSlots,
    setStep,
    setStepDataLoading,
    setVisitData,
    step,
    stepDataLoading,
    visitData,
    setCompletedSteps,
    submitCancelCreation,
  } = useContext(CreateVisitContext);
  const { closeDialog, effectiveDate, initialCareProgramData, updatedCareProgram } =
    useContext(EditCareProgramContext);
  const { resetToInitialView } = useContext(ClientDetailsContext);

  const [errorData, setErrorData] = useState(null);
  const [isExactTime, setIsExactTime] = useState(false);
  const [temporaryVisitData, setTemporaryVisitData] = useState({});
  const [visitSummaryHeight, setVisitSummaryHeight] = useState(0);

  const visitSummaryRef = useRef(null);

  const { data: userData, error: getUserDataByIdError } = useGetUserDataByIdQuery(id);
  useEffect(() => {
    if (visitData) {
      setTemporaryVisitData(visitData);
    }
  }, [visitData]);

  useEffect(() => {
    if (getUserDataByIdError) {
      setAlert({
        errorData: getUserDataByIdError,
        type: 'error',
      });
    }
  }, [getUserDataByIdError, setAlert]);

  const getStepView = () => {
    if (step === 0) {
      return (
        <VisitParameters
          isExactTime={isExactTime}
          setIsExactTime={setIsExactTime}
          setVisitData={setVisitData}
          visitData={visitData}
          setSelectedTimeSlots={setSelectedTimeSlots}
          setCompletedSteps={setCompletedSteps}
          setStepDataLoading={setStepDataLoading}
        />
      );
    }
    if (step === 1) {
      return (
        <RecurrentAvailability
          availabilityScheduling={availabilityScheduling}
          clearSelectedTimeSlots={clearSelectedTimeSlots}
          errorData={errorData}
          setAvailabilityScheduling={setAvailabilityScheduling}
          setErrorData={setErrorData}
          setStepDataLoading={setStepDataLoading}
          setTemporaryVisitData={setTemporaryVisitData}
          setVisitData={setVisitData}
          temporaryVisitData={temporaryVisitData}
          updatedCareProgram={updatedCareProgram}
          visitData={visitData}
          visitSummaryHeight={visitSummaryHeight}
          effectiveDate={moment(effectiveDate).toISOString()}
          setCompletedSteps={setCompletedSteps}
        />
      );
    }
    if (step === 2) {
      return (
        <AlternativeArrivalWindows
          alternativeArrivalWindows={alternativeArrivalWindows}
          selectedTimeSlots={selectedTimeSlots}
          setAlternativeArrivalWindows={setAlternativeArrivalWindows}
          setErrorData={setErrorData}
          setSelectedTimeSlots={setSelectedTimeSlots}
          setStepDataLoading={setStepDataLoading}
          setVisitData={setVisitData}
          updatedCareProgram={updatedCareProgram}
          visitData={visitData}
          temporaryVisitData={temporaryVisitData}
          effectiveDate={moment(effectiveDate).toISOString()}
        />
      );
    }
    return false;
  };

  const changeStep = value => {
    setStep(value);
    setErrorData(null);
    if (value === 1 && selectedTimeSlots) {
      setSelectedTimeSlots(null);
    }
    if (value === 1 && step === 2 && selectedTimeSlots) {
      setAlternativeArrivalWindows(null);
    }
  };

  return (
    <Box sx={visitMultistepWrapper}>
      <Box sx={visitMultistepBreadcrumbs}>
        <CustomBreadcrumbs
          closeDialog={closeDialog}
          isVisitAdding
          resetToInitialView={resetToInitialView}
          title="Care Program"
          visitType={[2, 3].includes(step) ? '' : visitData?.visitType?.name}
          handleChangesAdded={() => true}
          submitCancelCreation={submitCancelCreation}
        />
        <CustomStepper
          changeStep={changeStep}
          completedSteps={completedSteps}
          currentStep={step}
          disabled={stepDataLoading}
          steps={createVisitSteps}
        />
      </Box>
      <Collapse in={!!errorData}>
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          <ErrorAlert error={errorData} closeError={() => setErrorData(null)} />
        </Box>
      </Collapse>
      {step > 0 && (
        <VisitSummary
          data={getSummaryData({
            effectiveDate,
            initialCareProgramData,
            selectedTimeSlots,
            temporaryVisitData,
            userData,
            visitData,
          })}
          setVisitSummaryHeight={setVisitSummaryHeight}
          visitSummaryRef={visitSummaryRef}
          visitData={visitData}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>{getStepView()}</Box>
    </Box>
  );
}
