import { Box, Breadcrumbs, Typography } from '@mui/material';
import { useGetUserDataByIdQuery } from '../../../../api/Clients/api';
import { bool, func, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { COLORS } from '../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import BreadcrumbsButton from '../../../../components/BreadcrumbsButton';
import CustomDialog from '../../../../components/Dialog';
import UnsavedChangesContent from '../../../../components/Dialog/UnsavedChangesContent';

export default function CustomBreadcrumbs({
  closeDialog = () => {},
  isVisitAdding = false,
  resetToInitialView = () => {},
  title = '',
  visitType = '',
  handleChangesAdded = () => {},
  submitCancelCreation = () => {},
}) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const { data: userData, error } = useGetUserDataByIdQuery(id);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationType, setConfirmationType] = useState(false);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);
  const userName = () => `${userData?.firstName} ${userData?.lastName}`;

  const navigateToBreadcrumb = useCallback(
    (overrideDataCheck, type) => {
      setConfirmationType(type);
      const isChangesAdded = handleChangesAdded();
      if (!overrideDataCheck && isChangesAdded) {
        setOpenConfirmationModal(true);
      } else {
        closeDialog();
        if (type === 'move-to-profile') {
          resetToInitialView();
        } else if (type === 'move-to-clients') {
          navigate('/clients');
        } else if (type === 'move-to-client') {
          submitCancelCreation();
        }
      }
    },
    [handleChangesAdded, closeDialog, resetToInitialView, navigate, submitCancelCreation],
  );

  const unsavedChangedSubmitCallback = useCallback(() => {
    navigateToBreadcrumb(true, confirmationType);
    setOpenConfirmationModal(false);
  }, [confirmationType, navigateToBreadcrumb]);

  return (
    <Breadcrumbs
      aria-label="breadcrumbs"
      sx={{ '& .MuiBreadcrumbs-li .MuiTypography-root': { cursor: 'pointer' } }}
    >
      <CustomDialog
        cancelButtonName="Cancel"
        cancelCallback={() => setOpenConfirmationModal(false)}
        submitButtonName="confirm"
        submitCallback={unsavedChangedSubmitCallback}
        title="Confirmation"
        openDialog={openConfirmationModal}
      >
        <UnsavedChangesContent />
      </CustomDialog>
      <BreadcrumbsButton
        name="Clients"
        onLinkClick={() => navigateToBreadcrumb(false, 'move-to-clients')}
      />
      <BreadcrumbsButton
        name={userName()}
        onLinkClick={() => navigateToBreadcrumb(false, 'move-to-profile')}
      />
      {isVisitAdding ? (
        <BreadcrumbsButton
          name={title}
          onLinkClick={() => navigateToBreadcrumb(false, 'move-to-client')}
        />
      ) : (
        <Typography sx={{ color: COLORS.blue[900] }} variant="body1">
          {title}
        </Typography>
      )}
      {isVisitAdding && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& img': {
              height: '14px',
            },
          }}
        >
          {getIconComponent(visitType)}
          <Typography sx={{ color: COLORS.blue[900], ml: '8px' }} variant="body1">
            Add Visit
          </Typography>
        </Box>
      )}
    </Breadcrumbs>
  );
}

CustomBreadcrumbs.propTypes = {
  closeDialog: func,
  isVisitAdding: bool,
  resetToInitialView: func,
  title: string,
  visitType: string,
};
