import { Box } from '@mui/material';
import warningIcon from '../../../../../../../../img/icons/warning-icon.svg';
import ArrivalWindow from '../../../../../../../../pages/ClientDetails/components/ArrivalWindow';
import { bool, func, string } from 'prop-types';
import React from 'react';
import {
  visitArrivalTimeTypes,
  visitArrivalTimeTypesNames,
} from '../../../../../../../../shared/constants';
import ArrivalTypeSelector from '../../../../../../../../components/ArrivalTypeSelector';
import CustomTimeSelect from '../../../../../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import CustomDialogAlert from '../../../../../../../../components/CustomDialogAlert';
import ErrorAlert from '../../../../../../../../components/ErrorAlert';

const timeExclusionList = [
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
];

function ArrivalSlots({
  arrivalEnd = '',
  arrivalStart = '',
  changeArrivalTimeRange = () => {},
  changeArrivalTimeType = () => {},
  changeExactTime = () => {},
  isSelectedTimeOutOfRange = false,
  pastTimeError = null,
  visitArrivalTimeType = '',
}) {
  return (
    <>
      <ArrivalTypeSelector
        arrivalTimeOptions={visitArrivalTimeTypes}
        arrivalTimeType={visitArrivalTimeType}
        changeArrivalTimeType={changeArrivalTimeType}
      />
      <CustomDialogAlert
        customStyles={{ mt: '12px' }}
        icon={warningIcon}
        showAlert={isSelectedTimeOutOfRange}
        title="Out of business hours"
        type="warning"
      />
      <Box sx={{ width: '100%', height: '12px' }} />
      {pastTimeError && (
        <>
          <ErrorAlert error={pastTimeError} />
          <Box sx={{ width: '100%', height: '12px' }} />
        </>
      )}
      {visitArrivalTimeType === visitArrivalTimeTypesNames.exactTime ? (
        <CustomTimeSelect
          onSelectTimeValue={changeExactTime}
          placeHolder="Time"
          selectedTime={arrivalStart}
          exclusionList={timeExclusionList}
        />
      ) : (
        <ArrivalWindow
          arrivalEnd={arrivalEnd}
          arrivalStart={arrivalStart}
          changeArrivalTimeRange={changeArrivalTimeRange}
          isRequired
          timeExclusionList={timeExclusionList}
        />
      )}
    </>
  );
}

ArrivalSlots.propTypes = {
  arrivalEnd: string,
  arrivalStart: string,
  changeArrivalTimeRange: func,
  changeArrivalTimeType: func,
  changeExactTime: func,
  isSelectedTimeOutOfRange: bool,
  pastTimeError: string,
  visitArrivalTimeType: string,
};

export default React.memo(ArrivalSlots);
