import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetVisitDetailsHistoryTasksQuery } from '../../../../../api/Visits/api';
import moment from 'moment';
import { string } from 'prop-types';
import React, { useContext } from 'react';
import { HISTORY_UPDATE_FORMAT } from '../../../../../shared/constants';
import AlertContext from '../../../../../components/Alert';
import CustomTable from '../../../../../components/Table';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  historyColumns,
} from './constants';

export default function VisitHistory({ date = '', visitId = '' }) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);

  const {
    data: visitDetailsHistory,
    isLoading,
    error: visitDetailsHistoryError,
  } = useGetVisitDetailsHistoryTasksQuery(visitId);

  if (visitDetailsHistoryError) {
    setAlert({
      errorData: visitDetailsHistoryError,
      type: 'error',
    });
  }

 const updatedEvents = visitDetailsHistory
  ?.map((item, index) => {
    return {
      ...item,
      dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  return (
    <Box>
      <CustomTable
        apiRef={apiRef}
        columns={historyColumns}
        headerHeight={44}
        isLoading={isLoading}
        rows={sortedUpdatedEvents}
        pageSize={PAGE_SIZE}
        pageSizeOptions={PAGE_SIZE_OPTIONS}
      />
    </Box>
  );
}

VisitHistory.propTypes = {
  date: string,
  visitId: string,
};
