import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetAllTimeOffRequestsQuery } from '../../../../api/Scheduler/api';
import moment from 'moment';
import { func, instanceOf } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import FiltersPanel from './components/FiltersPanel';
import {
  DEFAULT_SORTING_MODEL,
  INITIAL_FILTERS,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  TIME_OFF_REQUESTS_STATUSES,
  getColumns,
} from './constants';
import { SchedulerTimeOffRequestsContext } from './context';
import { customTableStyles } from './styles';
import Loader from '../../../../components/Loader';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';
import {
  useCreateTimeOffRequestMutation,
  useUpdateTimeOffRequestMutation,
} from '../../../../api/TimeOffRequest/api';
import CreatePtoAndCallouts from './components/CreatePtoAndCallouts/CreatePtoAndCallouts';
import { timeOffRequestTypes } from '../../../CaregiverDetails/view/PtoAndCallouts/constants';
import { useGetConfigQuery } from '../../../../api/Config/api';

export default function PtoAndCallouts({
  teamsArray = [],
  teamsFilter = {},
  setTeamFilter = () => {},
}) {
  const apiRef = useGridApiRef();
  const { setAlert } = useContext(AlertContext);

  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [timeOffRequestsFilters, setTimeOffRequestsFilters] = useState(INITIAL_FILTERS);
  const [timeOffRequestsList, setTimeOffRequestsList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(false);
  const [editedRequestData, setEditedRequestData] = useState(undefined);
  const [openAddRequestDialogType, setOpenAddRequestDialogType] = useState(null);
  const [editingCaregiverId, setEditingCaregiverId] = useState();
  const [checkData, setCheckData] = useState(null);
  const [closeActionDialogs, setCloseActionDialogs] = useState(false);

  const creatingRequestInitialData = null;

  const { data: timeOffRequestsTypes, error: getTimeOffRequestsTypesError } = useGetConfigQuery([
    'ptoTypes',
  ]);

  useEffect(() => {
    setTimeOffRequestsFilters(prevValue => ({
      ...prevValue,
      status: TIME_OFF_REQUESTS_STATUSES?.map(status => status),
      teams: teamsFilter?.teams?.map(team => team?.name),
      datesRange: {
        startDate: moment(),
        endDate: moment().add(1, 'months'),
      },
    }));
  }, [setTimeOffRequestsFilters, teamsFilter]);

  const {
    data: timeOffRequestsListRaw,
    error,
    isLoading: timeOffRequestsListLoading,
    isFetching: timeOffRequestsListFetching,
    refetch: refetchTimeOffRequests,
  } = useGetAllTimeOffRequestsQuery();

  const closeAddRequestDialog = useCallback(() => {
    setOpenAddRequestDialogType(null);
  }, [setOpenAddRequestDialogType]);

  const [
    createTimeOffRequest,
    { error: createTimeOffRequestError, isLoading: isCreatingTimeOffRequest },
  ] = useCreateTimeOffRequestMutation();

  const [
    updateTimeOffRequest,
    { error: updateTimeOffRequestError, isLoading: isUpdatingTimeOffRequest },
  ] = useUpdateTimeOffRequestMutation();

  useEffect(() => {
    if (timeOffRequestsListRaw) {
      let tempTimeOffRequestsList = timeOffRequestsListRaw.filter(item =>
        timeOffRequestsFilters?.status.includes(item.status?.toLowerCase()),
      );
      tempTimeOffRequestsList = tempTimeOffRequestsList.filter(item =>
        timeOffRequestsFilters?.teams?.length
          ? timeOffRequestsFilters?.teams.some(
              team =>
                item.caregiverPrimaryTeams.map(primTeam => primTeam?.name).includes(team) ||
                item.caregiverSecondaryTeams.map(primTeam => primTeam?.name).includes(team),
            )
          : false,
      );
      if (timeOffRequestsFilters?.caregiver?.length) {
        tempTimeOffRequestsList = tempTimeOffRequestsList.filter(item =>
          item.caregiverName.toLowerCase().includes(timeOffRequestsFilters.caregiver.toLowerCase()),
        );
      }
      if (
        timeOffRequestsFilters?.datesRange?.startDate &&
        timeOffRequestsFilters?.datesRange?.endDate
      ) {
        tempTimeOffRequestsList = tempTimeOffRequestsList.filter(
          item =>
            (moment(item.from).isSameOrAfter(
              timeOffRequestsFilters?.datesRange?.startDate,
              'date',
            ) &&
              moment(item.from).isSameOrBefore(
                timeOffRequestsFilters?.datesRange?.endDate,
                'date',
              )) ||
            (moment(item.to).isSameOrAfter(timeOffRequestsFilters?.datesRange?.startDate, 'date') &&
              moment(item.to).isSameOrBefore(timeOffRequestsFilters?.datesRange?.endDate, 'date')),
        );
      }
      setTimeOffRequestsList([...tempTimeOffRequestsList]);
      if (isLoading) {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line
  }, [timeOffRequestsFilters, timeOffRequestsListRaw]);

  useEffect(() => {
    if (error) {
      setAlert({
        errorData: error,
        type: 'error',
      });
    }
  }, [error, setAlert]);

  useEffect(() => {
    if (
      error ||
      createTimeOffRequestError ||
      updateTimeOffRequestError ||
      getTimeOffRequestsTypesError
    ) {
      const errorData =
        error ||
        createTimeOffRequestError ||
        updateTimeOffRequestError ||
        getTimeOffRequestsTypesError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    createTimeOffRequestError,
    error,
    setAlert,
    updateTimeOffRequestError,
    getTimeOffRequestsTypesError,
  ]);

  const debouncedSearch = useDebouncedCallback(e => {
    setTimeOffRequestsFilters({
      ...timeOffRequestsFilters,
      [e.target.name]: e.target.value,
    });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const editPtoRequest = data => {
    setEditingCaregiverId(data.caregiverId);
    setEditedRequestData(data);
    setOpenAddRequestDialogType(timeOffRequestTypes.settingParams);
  };

  const updateSorting = useCallback(item => setSortOption(item[0]), []);

  const columns = getColumns(editPtoRequest);

  const startCheckOptimizer = ptoData => {
    setCheckData(ptoData);
    setOpenCheckOptimizer(true);
  };

  const confirmRequestStatusChanges = async ptoData => {
    setOpenAddRequestDialogType(null);
    setCloseActionDialogs(true);
    const editedPto = [];
    const addedPto = [];
    for (let pto in ptoData) {
      delete ptoData[pto].requestDateEnd;
      delete ptoData[pto].requestDateStart;
      delete ptoData[pto].requestTimeEnd;
      delete ptoData[pto].requestTimeStart;
      delete ptoData[pto].createdDate;
      if (ptoData[pto]?.id) {
        editedPto.push(ptoData[pto]);
      } else {
        addedPto.push(ptoData[pto]);
      }
    }
    setEditedRequestData(undefined);
    setEditingCaregiverId(undefined);
    if (editedPto.length) {
      updateTimeOffRequest(editedPto);
    }
    if (addedPto.length) {
      createTimeOffRequest(addedPto);
    }
    refetchTimeOffRequests();
  };

  const setStatusToPending = async ptoData => {
    setOpenAddRequestDialogType(null);
    updateTimeOffRequest(ptoData);
    setEditingCaregiverId(undefined);
    refetchTimeOffRequests();
  };

  const rejectPendingRequest = async ptoData => {
    await updateTimeOffRequest(ptoData);
    refetchTimeOffRequests();
  };

  const memoizedProviderValue = useMemo(
    () => ({
      openAddRequestDialogType,
      setCloseActionDialogs,
      closeActionDialogs,
      editedRequestData,
      setEditedRequestData,
      setEditingCaregiverId,
      rejectPendingRequest,
      timeOffRequestsTypes,
      closeAddRequestDialog,
      creatingRequestInitialData,
      startCheckOptimizer,
    }),
    // eslint-disable-next-line
    [
      openAddRequestDialogType,
      setCloseActionDialogs,
      closeActionDialogs,
      editedRequestData,
      setEditedRequestData,
      setEditingCaregiverId,
      timeOffRequestsTypes,
      closeAddRequestDialog,
      creatingRequestInitialData,
    ],
  );

  return (
    <SchedulerTimeOffRequestsContext.Provider value={memoizedProviderValue}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box>
          {!!openAddRequestDialogType && editingCaregiverId && (
            <CreatePtoAndCallouts
              caregiverData={editingCaregiverId}
              startCheckOptimizer={startCheckOptimizer}
            />
          )}
          <OptimizerCheck
            data={[checkData]}
            dataObjName="ptoObj"
            submitFunction={confirmRequestStatusChanges}
            cancelFunction={setStatusToPending}
            open={openCheckOptimizer}
            openToggle={setOpenCheckOptimizer}
            cancelButtonName="Set to Pending"
            submitButtonName="Approve"
          />
          <FiltersPanel
            debouncedSearch={debouncedSearch}
            filterData={timeOffRequestsFilters}
            setFilterData={setTimeOffRequestsFilters}
            teamsArray={teamsArray}
            teamsFilter={teamsFilter}
            setTeamFilter={setTeamFilter}
          />
          <Box sx={{ padding: '0 40px' }}>
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={customTableStyles}
              headerHeight={44}
              noRowsOverlay={() => getNoData({ title: 'No PTO or Callouts found' })}
              pageSize={PAGE_SIZE}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              rows={timeOffRequestsList}
              isLoading={
                timeOffRequestsListLoading ||
                isCreatingTimeOffRequest ||
                isUpdatingTimeOffRequest ||
                timeOffRequestsListFetching
              }
            />
          </Box>
        </Box>
      )}
    </SchedulerTimeOffRequestsContext.Provider>
  );
}

PtoAndCallouts.propTypes = {
  teamsArray: instanceOf(Array),
  teamsFilter: instanceOf(Object),
  setTeamFilter: func,
};
