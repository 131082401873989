import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React, { useCallback } from 'react';
import { COLORS } from '../../../../../shared/styles/Theme';
import { hourlyRates, visitsSummaryData } from '../constants';
import moment from 'moment';

function VisitsSummary({ data = {} }) {
  const getVisitDetails = useCallback(
    key => {
      const filteredData = data?.filter(visit => {
        if (visit.effectiveDateEnd) {
          return (
            moment().isSameOrAfter(moment(visit.effectiveDateStart), 'date') &&
            moment().isBefore(moment(visit.effectiveDateEnd), 'date')
          );
        }
        return moment().isSameOrAfter(moment(visit.effectiveDateStart), 'date');
      });
      const weeklyVisits = filteredData?.reduce(
        (acc, visit) => acc + Object.keys(visit.days ?? {}).length,
        0,
      );
      const weeklyHours =
        filteredData?.reduce(
          (acc, visit) => acc + visit.duration * Object.keys(visit.days ?? {}).length,
          0,
        ) / 60;
      let hourlyRate = hourlyRates.SIXTY;

      if (weeklyHours >= 20) {
        hourlyRate = hourlyRates.FIFTY;
      } else if (weeklyHours >= 15) {
        hourlyRate = hourlyRates.FIFTY_FIVE;
      } else if (weeklyHours >= 10) {
        hourlyRate = hourlyRates.SIXTY;
      } else if (weeklyHours >= 5) {
        hourlyRate = hourlyRates.SIXTY_FIVE;
      } else if (weeklyHours >= 0.5) {
        hourlyRate = hourlyRates.SEVENTY;
      }

      switch (key) {
        case 'weeklyVisits':
          return weeklyVisits;
        case 'weeklyHours':
          return weeklyHours.toFixed(2);
        case 'WeeklyCost':
          return `$${(weeklyHours * hourlyRate).toFixed(2)}`;
        case 'effectiveHourlyRate':
          return `$${hourlyRate.toFixed(2)}`;
        default:
          break;
      }
      return false;
    },
    [data],
  );

  return (
    <Box sx={{ display: 'flex', mr: '32px', gap: '28px' }}>
      {visitsSummaryData?.map(visit => (
        <Box
          key={visit.name}
          sx={{
            borderLeft: `1px solid ${COLORS.blue[200]}`,
            paddingLeft: '16px',
            height: '40px',
            '&:first-of-type': {
              borderLeft: 'none',
            },
          }}
        >
          <Typography sx={{ mb: '6px' }} variant="body2">
            {visit.name}
          </Typography>
          <Typography variant="h5">{getVisitDetails(visit.key)}</Typography>
        </Box>
      ))}
    </Box>
  );
}

VisitsSummary.propTypes = {
  data: instanceOf(Object),
};

export default React.memo(VisitsSummary);
