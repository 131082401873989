import { Box, Typography } from '@mui/material';
import React from 'react';
import HistoryBulletList from '../../components/HistoryBulletList';

export const INITIAL_ACTIVITY_FILTERS = {
  search: '',
  user: '',
  activity: [],
};
export const activityColumns = (clients) => [
  {
    field: 'activity',
    headerName: 'Activity',
    minWidth: 240,
    sortable: false,
    renderCell: ({ row }) => (
      <Typography>
        {row?.activity}
      </Typography>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 540,
    sortable: false,
    renderCell: ({ row }) => <HistoryBulletList changes={row} />
  },
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 340,
    renderCell: ({ row }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{row?.dateChanged}</Typography>
      </Box>
    ),
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: (params) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params?.row?.updatedBy}</Typography>
      </Box>
    ),
  },
];
