import { Box } from '@mui/material';
import moment from 'moment';
import { CaregiverProfileContext } from '../../../../../pages/CaregiverDetails/context';
import React, { useContext, useState } from 'react';
import TeamsSelect from '../TeamsSelect';
import { sortBy } from 'lodash';

export default function PrimaryRegionsSelector() {
  const {
    editingProfileDataLibrary,
    profileData,
    pendingTeamChanges,
    setPendingTeamChanges,
    teamDate,
  } = useContext(CaregiverProfileContext);
  const [optionIsOpen, setOptionIsOpen] = useState(false);

  const onSelectChange = (newDetails, newIds) => {
    const primaryTeams = { ...pendingTeamChanges?.primaryTeams } || {};
    primaryTeams[moment(teamDate).format('YYYY-MM-DD')] = newIds;
    const primaryTeamsDetails = { ...pendingTeamChanges?.primaryTeamsDetails } || {};
    primaryTeamsDetails[moment(teamDate).format('YYYY-MM-DD')] = newDetails;
    setPendingTeamChanges(prev => ({
      ...prev,
      primaryTeams,
      primaryTeamsDetails,
    }));
  };

  return (
    <Box sx={{ maxWidth: '250px', minWidth: '250px' }}>
      <TeamsSelect
        id="primaryTeams"
        options={sortBy(editingProfileDataLibrary?.teams, ['name'])}
        placeHolder="Primary teams"
        selectedValue={
          pendingTeamChanges?.primaryTeamsDetails &&
          pendingTeamChanges?.primaryTeamsDetails[moment(teamDate).format('YYYY-MM-DD')]
            ? pendingTeamChanges?.primaryTeamsDetails[moment(teamDate).format('YYYY-MM-DD')]
            : profileData?.primaryTeamsDetails
        }
        setValue={onSelectChange}
        isOpen={optionIsOpen}
        setIsOpen={setOptionIsOpen}
        onClose={() => {
          setOptionIsOpen(false);
        }}
      />
    </Box>
  );
}
