import { Box } from '@mui/material';
import { instanceOf } from 'prop-types';
import moment from 'moment';
import React from 'react';
import styles from './style.module.css';
import { WEEK_DAYS, FULL_DATE_H12_STANDARD_FORMAT, DATE_ONLY_STANDARD_FORMAT } from '../../shared/constants';

const formatKeyName = key => {
  if (!key || typeof key !== 'string') return '';
  return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
};

const getDayNameById = id => {
  const day = WEEK_DAYS.find(day => day.id === id);
  return day ? day.name : id;
};

const formatValue = (value, fieldKey, valueKey, details = [], idField = 'id', nameField = 'name') => {

  if (fieldKey == "date" || fieldKey == "effectiveDateStart") {
    return moment(value).format(DATE_ONLY_STANDARD_FORMAT);
  }
  
  if (fieldKey === 'days' && value) {
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "None";
        } else {
            return value.map(getDayNameById).join(", ");
        }
    } else if (typeof value === 'object') {
        if (Array.isArray(value.days)) {
            if (value.days.length === 0) {
                return "None";
            } else {
                return value.days.map(getDayNameById).join(", ");
            }
        } else if (Object.keys(value).length === 0) {
            return "None";
        } else {  
            const days = Object.keys(value).map(dayKey => {
                const dayId = Number(dayKey);
                const day = getDayNameById(dayId);
                const { arrivalStart, arrivalEnd } = value[dayKey];
                return (
                    <div key={dayId}>
                        {day}: (Start: {moment(arrivalStart, "HH:mm:ss").format("h:mm A")}, End: {moment(arrivalEnd, "HH:mm:ss").format("h:mm A")})
                    </div>
                );
            });
            return <div className={ valueKey === "oldValue" ? styles.oldTime : valueKey === "newValue" ? styles.newTime : ''}>{days}</div>;            
        }
    }
  }
  
  if (fieldKey === 'oneTimeAvailabilities' && value) {
    return value
      .map(item => {
        const startDate = item.effectiveStartDate;
        const startTime = item.startTime;
        const endTime = item.endTime;
        return `Effective Start Date: ${moment(startDate).format(DATE_ONLY_STANDARD_FORMAT)}, Start Time: ${moment(startTime, "HH:mm:ss").format("h:mm A")}, End Time: ${moment(endTime, "HH:mm:ss").format("h:mm A")}`;
      })
      .join(', ');
  }

  if (fieldKey === 'availabilities' && value) {
    const availabilityStrings = Object.entries(value)
      .filter(([_, availabilities]) => Array.isArray(availabilities))
      .map(([dayIndex, availabilities]) => {
        const dayName = WEEK_DAYS.find(day => day.id === Number(dayIndex))?.name || '';
  
        return availabilities
          .map(item =>
            `Day: ${dayName}, Effective Start Date: ${moment(item.effectiveStartDate).format(DATE_ONLY_STANDARD_FORMAT)}, Start Time: ${moment(item.startTime, "HH:mm:ss").format("h:mm A")}, End Time: ${moment(item.endTime, "HH:mm:ss").format("h:mm A")}`
          )
          .join('|');
      })
      .join('|');
  
    return availabilityStrings || "None";
  }

  if (Array.isArray(value)) {
    return value
      .map(item => {
        if (item && item[idField] && item[nameField]) {
          return item[nameField];
        }
        return JSON.stringify(item); 
      })
      .join(', ');
  }

  if (typeof value === 'object' && value !== null) {
    if (value?.name) {
      return value?.name.toString(); 
    }
    return Object.entries(value)
      .map(([key, val]) => `${key}: ${formatValue(val, key, "", details, idField, nameField)}`)
      .join(', ');
  }

  return value?.toString() || 'None';
};



const getHistoryList = arr => (
  <ul className={styles.bulletList}>
    {arr?.data?.map((item, index) => {
      const key = `${arr.action}-${index}-${arr.id}`;

      if (arr.action === 'edited') {
        return (
          <li
            key={key}
            className={`${styles.bulletListItem} ${
              !item?.newValue || Object.values(item?.newValue).includes('None')
                ? styles.removedType
                : ''
            }`}
          >
            <span className={styles.boldWeight}>Record details edited: </span>
            <ul>
              {Object.keys(item?.newValue || {}).map(fieldKey => (
                <li key={fieldKey}>
                  {formatKeyName(fieldKey)} was updated from
                  <span className={styles.oldTime}>
                    {fieldKey === "availabilities" ? (
                      <ul className={styles.subBulletList}>
                        {formatValue(item?.oldValue?.[fieldKey], fieldKey, "oldValue")
                          .split('|')
                          .map((entry, i) => (
                            <li key={i} className={styles.oldTime}>{entry}</li>
                          ))}
                      </ul>
                    ) : (
                      formatValue(item?.oldValue?.[fieldKey], fieldKey, "oldValue") || "None"
                    )}
                  </span>
                  to
                  <span className={styles.newTime}>
                    {fieldKey === "availabilities" ? (
                      <ul className={styles.subBulletList}>
                        {formatValue(item?.newValue?.[fieldKey], fieldKey, "newValue")
                          .split('|')
                          .map((entry, i) => (
                            <li key={i} className={styles.newTime}>{entry}</li>
                          ))}
                      </ul>
                    ) : (
                      formatValue(item?.newValue?.[fieldKey], fieldKey, "newValue") || "None"
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </li>
        );
      }
      
      if (arr.action === 'removed') {
        return (
            <li key={key} className={`${styles.bulletListItem} ${styles.removedType}`}>
                <span className={styles.boldWeight}>Record details removed: </span>
                <ul> 
                    {Object.keys(item?.oldValue || {}).map(fieldKey => (
                        <li key={fieldKey}>
                            {formatKeyName(fieldKey)}: 
                            <span className={styles.removedType}>
                                {formatValue(item?.oldValue?.[fieldKey], fieldKey, "") || 'None'}
                            </span>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }
      
    if (arr.action === 'added') {
      return (
          <li key={key} className={`${styles.bulletListItem} ${styles.addedType}`}>
              <span className={styles.boldWeight}>Record details added: </span>
              <ul>
                  {Object.keys(item?.newValue || {}).map(fieldKey => (
                      <li key={fieldKey}>
                          {formatKeyName(fieldKey)}: 
                          <span className={styles.newTime}>
                              {formatValue(item?.newValue?.[fieldKey], fieldKey, "") || 'None'}
                          </span>
                      </li>
                  ))}
              </ul>
          </li>
      );
    }

      return null;
    })}
  </ul>
);

export default function HistoryBulletList({ changes = [] }) {
  return <Box sx={{ paddingLeft: '20px' }}>{getHistoryList(changes)}</Box>;
}

HistoryBulletList.propTypes = {
  changes: instanceOf(Object),
};
