import { Box } from '@mui/material';
import preferredCaregiverIcon from '../../../../img/icons/preferred-icon.svg';
import primaryCaregiverIcon from '../../../../img/icons/primary-visit-icon.svg';
import shadowCaregiverIcon from '../../../../img/icons/shadow-visit-black-icon.svg';
import { instanceOf } from 'prop-types';
import React from 'react';
import { useParams } from 'react-router';
import { getIconComponent, getOneTimeOrRepeatedIcon } from '../../../../shared/utils/common';
import CustomTooltip from '../../../../components/CustomTooltip';

export default function EventCardInfoIcons({ eventData = {} }) {
  const { id } = useParams();
  const isPrimaryCaregiver = eventData?.caregiverId === id;
  const isPreferredCaregiver = eventData?.caregiverId === id && eventData?.shadowCaregiverId;
  const isShadowCaregiver = eventData?.shadowCaregiverId && eventData?.shadowCaregiverId === id;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: eventData.duration < 45 ? 'row' : 'column',
        marginTop: '8px',
        alignItems: eventData.duration < 45 ? 'center' : 'start',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <CustomTooltip title="Care Program Visit">
          {getOneTimeOrRepeatedIcon(eventData?.repeat)}
        </CustomTooltip>
        <CustomTooltip title={eventData?.visitTypeDetails?.name}>
          {getIconComponent(eventData?.visitTypeDetails?.name?.toLowerCase())}
        </CustomTooltip>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '6px',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {isPreferredCaregiver && (
          <CustomTooltip title="Preferred Caregiver">
            <img src={preferredCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {isPrimaryCaregiver && (
          <CustomTooltip title="Primary Caregiver">
            <img src={primaryCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
        {isShadowCaregiver && (
          <CustomTooltip title="Shadow Visit">
            <img src={shadowCaregiverIcon} alt="arrivalIcon" />
          </CustomTooltip>
        )}
      </Box>
    </Box>
  );
}

EventCardInfoIcons.propTypes = {
  eventData: instanceOf(Object),
};
