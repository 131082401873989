import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
// import Chevron from '../../../../../../../../../../img/icons/chevron-right.svg?react';
import moment from 'moment';
import VisitSlotTitle from '../../../../../../../../../../pages/ClientDetails/views/CareProgram/components/CareProgramManagementProvider/components/CreateCareProgramVisit/CreateVisitMultistep/AlternativeArrivalWindows/VisitSlotTitle';
import { bool, func, instanceOf, number } from 'prop-types';
import React, { useState } from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  H24_TIME_FORMAT,
  H12_TIME_FORMAT,
} from '../../../../../../../../../../shared/constants';
import SelectAlternativeSlot from './SelectAlternativeSlot';
import { customAccordionStyles, customAccordionSummaryStyles } from './styles';

export default function VisitAccordion({
  deleteVisitFromSchedule = () => {},
  expanded = [],
  isUnscheduled = false,
  setExpanded = () => {},
  updateVisitData = () => {},
  visit = {},
  visitsCount = 0,
}) {
  const [newArrivalRange, setNewArrivalRange] = useState(null);
  const onExpand = () => {
    if (expanded.includes(visit.date)) {
      setExpanded(expanded.filter(item => item !== visit.date));
    } else {
      setExpanded([...expanded, visit.date]);
    }
  };

  const visitTimeSlot = visit.exactTimeSlot
    ? visit.exactTimeSlot
    : visit.arrivalStart === visit.arrivalEnd
    ? `${visit.arrivalStart}`
    : `${visit.arrivalStart} - ${visit.arrivalEnd}`;

  const confirmNewArrivalTime = () => {
    let newTimeSlots = {
      arrivalStart: moment(newArrivalRange.dateChangeData.arrivalStart, H12_TIME_FORMAT).format(
        H24_TIME_FORMAT,
      ),
      arrivalEnd: moment(newArrivalRange.dateChangeData.arrivalEnd, H12_TIME_FORMAT).format(
        H24_TIME_FORMAT,
      ),
    };
    if (newArrivalRange.dateChangeData.date) {
      newTimeSlots = {
        date: newArrivalRange.dateChangeData.date,
      };
    }
    updateVisitData(visit.date, newTimeSlots, newArrivalRange.data);
  };

  return (
    <Accordion
      elevation={0}
      key={visit.date}
      expanded={expanded.includes(visit.date)}
      sx={customAccordionStyles}
    >
      <AccordionSummary
        // expandIcon={<Chevron onClick={onExpand} />}
        aria-controls="panel1bh-content"
        sx={customAccordionSummaryStyles}
      >
        <VisitSlotTitle
          confirmNewArrivalTime={confirmNewArrivalTime}
          deleteVisitFromSchedule={deleteVisitFromSchedule}
          disabledNewAWConfirmation={!newArrivalRange}
          disableDeleteVisit={visitsCount < 2}
          hasNoAvailableWindows={
            !visit?.available && !visit?.availableWindows?.length && !visit?.otherDates?.length
          }
          isExpanded={expanded.includes(visit.date)}
          isUnscheduled={isUnscheduled}
          onExpand={onExpand}
          title={`${moment(visit.date, ISO_DATE_ONLY_FORMAT).format(
            SHORT_DATE_FORMAT,
          )}, ${visitTimeSlot}`}
        />
      </AccordionSummary>
      {!isUnscheduled && <Box sx={{ height: '20px' }} />}
      {isUnscheduled && (
        <AccordionDetails sx={{ padding: '0 16px 16px 0' }}>
          {!!visit?.availableWindows?.length && (
            <SelectAlternativeSlot
              availableSlots={visit?.availableWindows}
              isExactTime={!!visit?.exactTimeSlot}
              setNewVisitSlot={setNewArrivalRange}
              selectedNewSlot={newArrivalRange}
              title="Available arrival windows:"
            />
          )}
          {!!visit?.otherDates?.length && (
            <SelectAlternativeSlot
              availableSlots={visit?.otherDates}
              isExactTime={!!visit?.exactTimeSlot}
              setNewVisitSlot={setNewArrivalRange}
              selectedNewSlot={newArrivalRange}
              title="Other dates:"
            />
          )}
          {!visit?.availableWindows?.length && !visit?.otherDates?.length && (
            <Typography variant="body1" sx={{ mb: '12px', ml: '26px' }}>
              {`Unfortunately, we couldn't find alternative timeslots within 4h
                  range and on previous/next working day. Please try to choose a
                  different timeslot.`}
            </Typography>
          )}
        </AccordionDetails>
      )}
    </Accordion>
  );
}

VisitAccordion.propTypes = {
  deleteVisitFromSchedule: func,
  expanded: instanceOf(Array),
  isUnscheduled: bool,
  setExpanded: func,
  updateVisitData: func,
  visit: instanceOf(Object),
  visitsCount: number,
};
