import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCaregiverInfoQuery } from '../../../../api/Caregivers/api';
import {
  useUpdateTimeOffRequestMutation,
  useDeleteTimeOffRequestMutation,
  useGetCaregiverTimeOffRequestsQuery,
  useCreateTimeOffRequestMutation,
} from '../../../../api/TimeOffRequest/api';
import { TimeOffRequestsContext } from '../../../../pages/CaregiverDetails/context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import CustomTable from '../../../../components/Table';
import CreatePtoAndCallouts from './components/CreatePtoAndCallouts';
import PtoHistory from './components/PtoHistory';
import ViewActions from './components/ViewActions';
import { getColumns, timeOffRequestTypes } from './constants';
import { getTimeOffRequestsTableStyles } from './styles';
import { useGetConfigQuery } from '../../../../api/Config/api';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';
import { sortBy } from 'lodash';

function PtoAndCallouts() {
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const { id } = useParams();
  const { tabSubView } = useParams();
  const { data: userData, error: getUserDataError } = useGetCaregiverInfoQuery(id);
  const [openAddRequestDialogType, setOpenAddRequestDialogType] = useState(null);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);
  const [closeActionDialogs, setCloseActionDialogs] = useState(false);
  const [editedRequestData, setEditedRequestData] = useState(undefined);
  const [cancelButtonName, setCancelButtonName] = useState('Cancel');
  const [submitButtonName, setSubmitButtonName] = useState('Set to Pending');
  const [cancelButtonStatus, setCancelButtonStatus] = useState('Pending');

  const creatingRequestInitialData = null;

  const { data: timeOffRequestsTypes, error: getTimeOffRequestsTypesError } = useGetConfigQuery([
    'ptoTypes',
  ]);

  const [timeOffRequests, setTimeOffRequests] = useState([]);
  const {
    data: timeOffRequestsRaw,
    isFetching,
    error: getCaregiverTimeOffRequestsError,
    refetch: refetchTimeOffRequests,
  } = useGetCaregiverTimeOffRequestsQuery(id);

  useEffect(() => {
    if (timeOffRequestsRaw) {
      setTimeOffRequests(sortBy(timeOffRequestsRaw, ['from']).reverse());
    }
  }, [timeOffRequestsRaw]);

  const [createTimeOffRequest, { createTimeOffRequestError, isLoading: isCreatingTimeOffRequest }] =
    useCreateTimeOffRequestMutation();

  const [deleteTimeOffRequest, { error: deleteTimeOffRequestError }] =
    useDeleteTimeOffRequestMutation();

  const [updateTimeOffRequest, { error: updateTimeOffRequestError }] =
    useUpdateTimeOffRequestMutation();

  useEffect(() => {
    if (!isCreatingTimeOffRequest) {
      refetchTimeOffRequests();
    }
  }, [isCreatingTimeOffRequest, refetchTimeOffRequests]);

  useEffect(() => {
    if (
      getCaregiverTimeOffRequestsError ||
      getTimeOffRequestsTypesError ||
      updateTimeOffRequestError ||
      deleteTimeOffRequestError ||
      createTimeOffRequestError ||
      getUserDataError
    ) {
      const errorData =
        getTimeOffRequestsTypesError ||
        getCaregiverTimeOffRequestsError ||
        updateTimeOffRequestError ||
        deleteTimeOffRequestError ||
        createTimeOffRequestError ||
        getUserDataError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getCaregiverTimeOffRequestsError,
    getTimeOffRequestsTypesError,
    getUserDataError,
    setAlert,
    updateTimeOffRequestError,
    deleteTimeOffRequestError,
    createTimeOffRequestError,
  ]);
  const closeAddRequestDialog = useCallback(() => {
    setOpenAddRequestDialogType(null);
  }, [setOpenAddRequestDialogType]);

  const showCheckPossibilityView = useCallback(
    () => setOpenAddRequestDialogType(timeOffRequestTypes.checkPossibility),
    [setOpenAddRequestDialogType],
  );

  const startCheckOPtimizer = ptoData => {
    setCheckData(ptoData);
    setOpenCheckOptomizer(true);
  };

  const rejectPendingRequest = async ptoData => {
    await updateTimeOffRequest(ptoData);
    refetchTimeOffRequests();
  };

  const deletePendingRequest = async ptoData => {
    await deleteTimeOffRequest(ptoData);
    refetchTimeOffRequests();
  };

  const confirmRequestStatusChanges = async ptoData => {
    setOpenAddRequestDialogType(null);
    setCloseActionDialogs(true);
    const editedPto = [];
    const addedPto = [];
    for (let pto in ptoData) {
      delete ptoData[pto].requestDateEnd;
      delete ptoData[pto].requestDateStart;
      delete ptoData[pto].requestTimeEnd;
      delete ptoData[pto].requestTimeStart;
      delete ptoData[pto].createdDate;
      if (ptoData[pto]?.id) {
        editedPto.push(ptoData[pto]);
      } else {
        addedPto.push(ptoData[pto]);
      }
    }
    setEditedRequestData(undefined);
    if (editedPto.length) {
      updateTimeOffRequest(editedPto);
    }
    if (addedPto.length) {
      createTimeOffRequest(addedPto);
    }
    refetchTimeOffRequests();
  };

  const setStatusToPending = async ptoData => {
    const tempPtoData = { ...ptoData };
    tempPtoData.status = cancelButtonStatus;
    setOpenAddRequestDialogType(null);
    updateTimeOffRequest(tempPtoData);
    refetchTimeOffRequests();
  };

  const memoizedProviderValue = useMemo(
    () => ({
      rejectPendingRequest,
      deletePendingRequest,
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes,
      startCheckOPtimizer,
      setCloseActionDialogs,
      closeActionDialogs,
      editedRequestData,
      setEditedRequestData,
      setCancelButtonName,
      setSubmitButtonName,
      setCancelButtonStatus,
    }),
    // eslint-disable-next-line
    [
      closeAddRequestDialog,
      creatingRequestInitialData,
      openAddRequestDialogType,
      showCheckPossibilityView,
      timeOffRequests,
      timeOffRequestsTypes,
      setCloseActionDialogs,
      closeActionDialogs,
      editedRequestData,
      setCancelButtonName,
      setSubmitButtonName,
      setCancelButtonStatus
    ],
  );

  const editPtoRequest = data => {
    setEditedRequestData(data);
    setCancelButtonName('Set to Pending');
    setSubmitButtonName('Approve');
    setOpenAddRequestDialogType(timeOffRequestTypes.settingParams);
  };

  const columns = getColumns(editPtoRequest);

  return (
    <TimeOffRequestsContext.Provider value={memoizedProviderValue}>
      <Stack sx={{ maxWidth: '100%' }}>
        {!!openAddRequestDialogType && (
          <CreatePtoAndCallouts
            caregiverData={{ ...userData, id }}
            startCheckOptimizer={startCheckOPtimizer}
            setCancelButtonName={setCancelButtonName}
            setSubmitButtonName={setSubmitButtonName}
            setCancelButtonStatus={setCancelButtonStatus}
          />
        )}
        <OptimizerCheck
          data={[checkData]}
          dataObjName="ptoObj"
          submitFunction={confirmRequestStatusChanges}
          cancelFunction={setStatusToPending}
          open={openCheckOptomizer}
          openToggle={setOpenCheckOptomizer}
          cancelButtonName={cancelButtonName}
          submitButtonName={submitButtonName}
        />
        <ViewActions
          addRequest={() => setOpenAddRequestDialogType(timeOffRequestTypes.settingParams)}
        />
        <Box>
          {tabSubView === 'current' ? (
            <CustomTable
              apiRef={apiRef}
              columns={columns}
              customStyles={getTimeOffRequestsTableStyles(!timeOffRequests?.length)}
              headerHeight={34}
              isLoading={isFetching}
              noRowsOverlay={() => getNoData({ title: 'No PTO or Callouts found' })}
              onRowClick={() => {}}
              rows={timeOffRequests || []}
              withoutPagination
            />
          ) : (
            <PtoHistory />
          )}
        </Box>
      </Stack>
    </TimeOffRequestsContext.Provider>
  );
}

export default PtoAndCallouts;
