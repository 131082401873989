import { Box, Stack } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTeamsQuery } from '../../../../api/Administration/api';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  SEARCH_INPUT_DEBOUNCE_DELAY,
  viewTypeButtons,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { getNoData } from '../../../../shared/utils/common';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import CustomTable from '../../../../components/Table';
import AddTeamModalHeader from './components/AddTeamModalHeader';
import TeamsHistory from './components/TeamsHistory';
import { DEFAULT_SORTING_MODEL, teamColumns } from './constants';

export default function AdministrationTeams() {
  const { tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const [sortOption, setSortOption] = useState(DEFAULT_SORTING_MODEL);
  const [clientsFilters, setClientsFilters] = useState({ teamName: '' });
  const [teamName, setTeamName] = useState();
  const [territory, setTerritory] = useState();
  const [chosenTerritoryID, setChosenTerritoryID] = useState();
  const [order, setOrder] = useState(1);
  const updateSorting = useCallback(item => setSortOption(item[0]), []);
  const {
    data: locations,
    error: getLocationsListError,
    isFetching,
  } = useGetTeamsQuery({
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (getLocationsListError) {
      const errorData = getLocationsListError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getLocationsListError, setAlert]);

  const debouncedSearch = useDebouncedCallback(e => {
    setClientsFilters({ [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filteredList = locations?.filter(team => {
    const userName = team?.name?.toLowerCase();
    const filterValue = clientsFilters?.teamName?.toLowerCase();
    const territoryMatches = chosenTerritoryID ? team?.territory === chosenTerritoryID : true;
    const isActive = team?.status !== "Inactive";
    return userName.includes(filterValue) && territoryMatches && isActive;
  });
  const changeSubView = value => navigate(`/administration/teams/${value}`);

  return (
    <Stack>
      {tabSubView === 'current' ? (
        <Box>
          <Box sx={{ width: '100%', display: 'flex', gap: 2 }}>
            <ButtonsGroup
              buttons={viewTypeButtons}
              changeSelected={value => {
                changeSubView(value);
                setClientsFilters({ teamName: '' });
              }}
              selected={tabSubView}
            />
            <AddTeamModalHeader
              teamName={teamName}
              setTeamName={setTeamName}
              teamData={locations}
              order={order}
              setOrder={setOrder}
              debouncedSearch={debouncedSearch}
              chosenTerritoryID={chosenTerritoryID}
              setChosenTerritoryID={setChosenTerritoryID}
              territory={territory}
              setTerritory={setTerritory}
            />
          </Box>
          <Box sx={{ mt: '24px' }}>
            <CustomTable
              columns={teamColumns}
              rows={filteredList}
              apiRef={apiRef}
              headerHeight={40}
              isLoading={isFetching}
              rowHeight={50}
              pageSize={PAGE_SIZE}
              setSortModelState={updateSorting}
              sortModel={[sortOption]}
              pageSizeOptions={PAGE_SIZE_OPTIONS}
              noRowsOverlay={() =>
                getNoData({
                  title: 'No teams in This Territory',
                })
              }
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <ButtonsGroup
            buttons={viewTypeButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
          />
          <TeamsHistory />
        </Box>
      )}
    </Stack>
  );
}
