import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import {
  useCreateTeamByIdMutation,
  useGetTerritoryQuery,
} from '../../../../../../api/Administration/api';
import { func, number, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { COLORS } from '../../../../../../shared/styles/Theme';
import { isValidText } from '../../../../../../shared/utils/common';
import AlertContext from '../../../../../../components/Alert';
import CustomDialog from '../../../../../../components/Dialog';
import SearchFilter from '../../../../../../components/FiltersComponents/Search';
import CustomSelect from '../../../../../../components/Select';
import { TEAM_LOCAL_STORAGE_KEYS } from '../../constants';
import AddTeamModal from '../AddTeamModal';
import { territorySelectStyles } from '../AddTeamModal/styles';
import { addModalBoxStyles, addTeamButtonStyles } from './styles';

export default function AddTeamModalHeader({
  teamData = [],
  debouncedSearch = () => {},
  order = 0,
  setOrder = () => {},
  setTeamName = () => {},
  teamName = '',
  chosenTerritoryID = '',
  territory = "",
  setTerritory = () => {},
  setChosenTerritoryID = () => {},
}) {
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [color, setColor] = useState('#FFFFFF');
  const [fontColor, setFontColor] = useState('Black');
  const [openExistingTeamDialog, setOpenExistingTeamDialog] = useState(false)
  const [createTeamById, { error, isLoading  }] = useCreateTeamByIdMutation();
  const { data: territoryList, error: getTerritoryListError } =
    useGetTerritoryQuery();

  useEffect(() => {
    if (territoryList && Array.isArray(territoryList)) {
      const sortedTerritory = [...territoryList].sort(
        (a, b) => a.order - b.order,
      );
      const territoryIDInitialValue = localStorage.getItem(
        TEAM_LOCAL_STORAGE_KEYS.territoryID,
      )
        ? JSON.parse(
            localStorage.getItem(TEAM_LOCAL_STORAGE_KEYS.territoryID),
          )
        : sortedTerritory?.[0]?.id;

      setChosenTerritoryID(territoryIDInitialValue);
    }
  }, [setChosenTerritoryID, territoryList]);

  const onSelectTerritory = (params) => {
    const { id } = params

    setChosenTerritoryID(id)

    localStorage.setItem(
      TEAM_LOCAL_STORAGE_KEYS.territoryID,
      JSON.stringify(id),
    );
  };
  
  useEffect(() => {
    const errorData = error || getTerritoryListError;
    if (errorData) {
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [error, getTerritoryListError, setAlert]);

  const createTeam = () => {
    const teamExists = (teamData ?? []).some(item => item.name === teamName);
  
    if (teamExists) {
      setOpenExistingTeamDialog(true);
    } else {
      createTeamById({
        name: teamName,
        order,
        territory: territory,
        color: {
          color,
          fontColor,
        },
        status: "Active",
        type: "team",
      });
      setOpenDialog(false);
      setTeamName('');
    }
  };
  

  const cancelModal = () => {
    setTeamName('');
    setOpenDialog(false);
    setOrder(1);
    setChosenTerritoryID(chosenTerritoryID);
    setColor('#ffffff');
  };

  return (
    <>
      {isLoading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'all',
          }}
        >
          <CircularProgress />
        </Box>
      )}
  
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          pointerEvents: isLoading ? 'none' : 'auto',
        }}
      >
        <Box sx={addModalBoxStyles}>
          <CustomSelect
            addStyles={territorySelectStyles}
            id="territory1"
            isLoading={isLoading} 
            isMultiselect={false}
            options={territoryList?.map((ter) => ({ id: ter.id, name: ter.name }))} 
            placeHolder="Territory"
            selectedValue={chosenTerritoryID}
            setValue={onSelectTerritory}
          />
          <SearchFilter
            changeValue={debouncedSearch}
            name="teamName"
            placeholder="Team Name"
          />
        </Box>
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          disableElevation
          sx={addTeamButtonStyles}
          disabled={isLoading}  
        >
          TEAM
        </Button>
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={cancelModal}
          submitButtonName="Save"
          disabledSubmit={
            teamName?.trim()?.length === 0 || !isValidText(teamName) || isLoading 
          }
          submitCallback={createTeam}
          title="Add Team"
          openDialog={openDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 20px 13px' }}>
            <AddTeamModal
              teamName={teamName}
              setTeamName={setTeamName}
              teamData={teamData}
              order={order}
              setOrder={setOrder}
              color={color}
              fontColor={fontColor}
              setColor={setColor}
              setFontColor={setFontColor}
              territoryOptions={territoryList}
              territory={territory}
              setTerritory={setTerritory}
            />
          </Box>
        </CustomDialog>

        <CustomDialog
          submitButtonName="OK"
          submitCallback={() => setOpenExistingTeamDialog(false)}
          title="Team Name Conflict"
          openDialog={openExistingTeamDialog}
        >
          <Box sx={{ width: '100%', padding: '0 28px 28px 28px' }}>
            <Typography variant="body1" sx={{ color: COLORS.blue[900] }}>
              <span>This team name is already in use. Please select a different one.</span>   
            </Typography>
          </Box>
        </CustomDialog>
      </Box>
    </>
  )
};
  

AddTeamModalHeader.propTypes = {
  // data: instanceOf(Array),
  debouncedSearch: func,
  order: number,
  setOrder: func,
  setTeamName: func,
  teamName: string,
  chosenTerritory: string,
  setChosenTerritoryID: func,
};
