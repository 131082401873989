import { Box, Divider, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import CustomCheckbox from '../../../../pages/ClientDetails/components/CreateTask/components/Checkbox/Checkbox';
import React from 'react';
import {
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  backgroundColorSchemeMap,
  formatToPST,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { checkIsDayDisabled } from '../../../../shared/utils/common';
import { pluralize } from '../../../../shared/utils/pluralize';
import Badge from '../../../../components/Badge';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import HistoryBulletList from '../../../../components/HistoryBulletList';
import { getBadgeLabel, dayNumberToName } from './utils';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { scheduleEntryContainer, scheduleEntryDivider } from './styles';

export const availabilityScheduleModels = {
  current: '0',
  future: '1',
};

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];
export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: params => {
      const filteredData = params.row.data
        .filter(item => item?.oldValue?.availabilities || item?.newValue?.availabilities)
        .map(item => ({
          oldValue: { availabilities: item.oldValue?.availabilities || {} },
          newValue: { availabilities: item.newValue?.availabilities || {} },
        }));

      return filteredData.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: '16px',
          }}
        >
          <Typography>{formatToPST(params.row.dateChanged)}</Typography>
        </Box>
      ) : null;
    },
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: params => {
      const filteredData = params.row.data
        .filter(item => item?.oldValue?.availabilities || item?.newValue?.availabilities)
        .map(item => ({
          oldValue: { availabilities: item.oldValue?.availabilities || {} },
          newValue: { availabilities: item.newValue?.availabilities || {} },
        }));

      params.row.data = filteredData;

      return params.row.data.length > 0 ? <HistoryBulletList changes={params.row} /> : null;
    },
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    minWidth: 330,
    renderCell: params => {
      const filteredData = params.row.data
        .filter(item => item?.oldValue?.availabilities || item?.newValue?.availabilities)
        .map(item => ({
          oldValue: { availabilities: item.oldValue?.availabilities || {} },
          newValue: { availabilities: item.newValue?.availabilities || {} },
        }));

      return filteredData.length > 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            height: '100%',
            paddingTop: '16px',
          }}
        >
          <Typography>{params.row.updatedBy}</Typography>
        </Box>
      ) : null;
    },
  },
];

export const getAvailabilityColumns = ({ checkedRows }) => [
  {
    field: 'day',
    headerName: 'Weekday',
    minWidth: 330,
    sortable: false,
    renderCell: ({ row }) => (
      <CustomCheckbox
        isDisabled
        checked={checkedRows.includes(row.id)}
        label={
          <Box>
            <Typography sx={{ color: '#0D092B' }}>{dayNumberToName[row.day]}</Typography>
            <Badge
              colorScheme={
                moment(row.effectiveStartDate).isBefore(moment())
                  ? backgroundColorSchemeMap.info
                  : backgroundColorSchemeMap.active
              }
              label={getBadgeLabel(row?.schedule[0]?.effectiveStartDate)}
            />
          </Box>
        }
      />
    ),
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    sortable: false,
    minWidth: 330,
    renderCell: ({ row }) => (
      <Box sx={scheduleEntryContainer}>
        {row.schedule.map((scheduleEntry, scheduleEntryIndex) => (
          <Box key={`${row.id}-${scheduleEntryIndex}`}>
            {!checkedRows?.includes(row.id) ? (
              <Typography>-</Typography>
            ) : (
              <Typography>
                {moment(scheduleEntry?.startTime, 'HH:mm:ss').format(H12_TIME_FORMAT)}
              </Typography>
            )}
            {scheduleEntryIndex !== row.schedule.length - 1 && (
              <Divider sx={scheduleEntryDivider} />
            )}
          </Box>
        ))}
      </Box>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box sx={scheduleEntryContainer}>
        {row.schedule.map((scheduleEntry, scheduleEntryIndex) => (
          <Box key={`${row.id}-${scheduleEntryIndex}`}>
            {!checkedRows?.includes(row.id) ? (
              <Typography>-</Typography>
            ) : (
              <Typography>
                {moment(scheduleEntry?.endTime, 'HH:mm:ss').format(H12_TIME_FORMAT)}
              </Typography>
            )}
            {scheduleEntryIndex !== row.schedule.length - 1 && (
              <Divider sx={scheduleEntryDivider} />
            )}
          </Box>
        ))}
      </Box>
    ),
  },
  {
    field: 'workingHours',
    headerName: 'Available Hours',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box sx={scheduleEntryContainer}>
        {row.schedule.map((scheduleEntry, scheduleEntryIndex) => {
          const startTime = moment(scheduleEntry.startTime, 'h:mmA');
          const endTime = moment(scheduleEntry.endTime, 'h:mmA');
          const duration = moment.duration(endTime?.diff(startTime));
          const hours = parseInt(duration?.asHours().toString(), 10);
          const minutes = parseInt(duration?.asMinutes().toString(), 10) % 60;
          const range = minutes
            ? `${pluralize(hours, 'hour')} ${pluralize(minutes, 'minute')}.`
            : pluralize(hours, 'hour');

          return (
            <Box key={`${row.id}-${scheduleEntryIndex}`}>
              <Typography>
                {scheduleEntry.startTime !== null && scheduleEntry.endTime ? range : '-'}
              </Typography>
              {scheduleEntryIndex !== row.schedule.length - 1 && (
                <Divider sx={scheduleEntryDivider} />
              )}
            </Box>
          );
        })}
      </Box>
    ),
  },
];

export const getEditAvailabilityColumns = ({
  checkedRows,
  handleCheckboxChange,
  handleStartTimeChange,
  handleEndTimeChange,
  startDate,
  isPastAvailability,
  disabledDays,
  addScheduleEntry,
  removeScheduleEntry,
}) => [
  {
    field: 'day',
    headerName: 'Weekday',
    minWidth: 330,
    sortable: false,
    renderCell: ({ row }) => {
      const isDayDisabledSuAndSa = checkIsDayDisabled(row?.workingDays, disabledDays);
      return (
        <CustomCheckbox
          isDisabled={isPastAvailability || isDayDisabledSuAndSa}
          checked={checkedRows?.includes(row.id)}
          label={
            <Box>
              <Typography sx={{ color: COLORS.blue[900] }}>{dayNumberToName[row.day]}</Typography>
              <Badge
                colorScheme={
                  moment(startDate).isBefore(moment())
                    ? backgroundColorSchemeMap.info
                    : backgroundColorSchemeMap.active
                }
                label={getBadgeLabel(startDate)}
              />
            </Box>
          }
          onValueChange={() => handleCheckboxChange(row.id)}
        />
      );
    },
  },
  {
    field: 'startTime',
    headerName: 'Start Time',
    sortable: false,
    minWidth: 330,
    renderCell: ({ row }) => (
      <Box sx={{ width: '70%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {row?.schedule?.map((scheduleEntry, scheduleEntryIndex) => (
            <Box key={`start-time-${scheduleEntryIndex}`}>
              {isPastAvailability && <Typography>{scheduleEntry?.startTime}</Typography>}
              {checkedRows?.includes(row.id) && !isPastAvailability && (
                <CustomTimeSelect
                  isRequired
                  onSelectTimeValue={value =>
                    handleStartTimeChange(
                      row.id,
                      moment(value, H12_TIME_FORMAT),
                      scheduleEntryIndex,
                    )
                  }
                  placeHolder={`Start Time ${dayNumberToName[row.day]}`}
                  selectedTime={
                    moment(scheduleEntry.startTime, H24_TIME_FORMAT).format(H12_TIME_FORMAT) || ''
                  }
                />
              )}
            </Box>
          ))}
        </Box>
      </Box>
    ),
  },
  {
    field: 'endTime',
    headerName: 'End Time',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => {
      const isFirstIndexEndOfDay = moment(row?.schedule[0].endTime, H24_TIME_FORMAT).isAfter(
        moment('11:15 PM', H12_TIME_FORMAT),
        'minutes',
      );
      return (
        <Box sx={{ width: 'calc(85.5% - 0.19px)', paddingTop: '18px', paddingBottom: '18px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {row?.schedule?.map((scheduleEntry, scheduleEntryIndex) => (
              <Box key={`end-time-${scheduleEntryIndex}`}>
                {isPastAvailability && <Typography>{scheduleEntry?.endTime}</Typography>}
                {checkedRows?.includes(row.id) && !isPastAvailability && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '15px',
                      width:
                        (row?.schedule?.length === 2 && scheduleEntryIndex === 0) ||
                        (row?.schedule?.length === 1 && isFirstIndexEndOfDay)
                          ? 'calc(100% - 46px)'
                          : '100%',
                    }}
                  >
                    <CustomTimeSelect
                      isRequired
                      onSelectTimeValue={value =>
                        handleEndTimeChange(
                          row.id,
                          moment(value, H12_TIME_FORMAT),
                          scheduleEntryIndex,
                        )
                      }
                      placeHolder={`End Time ${dayNumberToName[row.day]}`}
                      selectedTime={
                        moment(scheduleEntry.endTime, H24_TIME_FORMAT).format(H12_TIME_FORMAT) || ''
                      }
                    />
                    {row?.schedule?.length === 1 && !isFirstIndexEndOfDay ? (
                      <IconButton onClick={() => addScheduleEntry(row.id)}>
                        {<AddIcon />}
                      </IconButton>
                    ) : (
                      row?.schedule?.length === 2 &&
                      scheduleEntryIndex === 1 && (
                        <IconButton onClick={() => removeScheduleEntry(row.id)}>
                          {<RemoveIcon />}
                        </IconButton>
                      )
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'workingHours',
    headerName: 'Working Hours',
    sortable: false,
    width: 330,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        {row?.schedule?.map((scheduleEntry, scheduleEntryIndex) => {
          const startTime = moment(scheduleEntry.startTime, 'h:mmA');
          const endTime = moment(scheduleEntry.endTime, 'h:mmA');
          const duration = moment.duration(endTime?.diff(startTime));
          const hours = parseInt(duration?.asHours().toString(), 10);
          const minutes = parseInt(duration?.asMinutes().toString(), 10) % 60;
          const range = minutes
            ? `${pluralize(hours, 'hour')} ${pluralize(minutes, 'minute')}`
            : pluralize(hours, 'hour');
          return (
            <Box key={`working-hours-${scheduleEntryIndex}`}>
              {checkedRows?.includes(row.id) && (
                <Typography>
                  {scheduleEntry.startTime !== null && scheduleEntry.endTime ? range : '-'}
                </Typography>
              )}
            </Box>
          );
        })}
      </Box>
    ),
  },
];
