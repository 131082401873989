import { COLORS } from '../../../../../shared/styles/Theme';

export const durationComponentWrapper = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  padding: '16px 20px',
  marginBottom: '16px',
  backgroundColor: COLORS.yellow[200],
  zIndex: 1,
};

export const infoRowWrapperStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  height: 'auto',
  mb: '16px',
  transition: 'height 500ms',
};

export const infoKeyField = {
  display: 'flex',
  maxWidth: '220px',
  width: '100%',
  color: COLORS.blue[500],
  alignItems: 'center',
  lineHeight: '20px',
};

export const infoRowEditableInfoWrapper = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const setupActionsWrapperStyles = {
  display: 'flex',
  width: '100%',
  height: '40px',
  mt: '12px',
  gap: '10px',
};

export const outlinedButtonStyles = {
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  '&.Mui-disabled': {
    backgroundColor: '#ffffff',
    color: COLORS.blue[500],
    opacity: 0.7,
  },
};

export const inputContainer = {
  display: 'flex',
  mb: '10px',
  width: '100%',
  alignItems: 'center',
  gap: '10px',
};
