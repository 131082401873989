import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { EditCareProgramContext } from '../../../../../../../pages/ClientDetails/context';
import {
  UPDATE_PROGRAM_POPUPS,
  VISITS_CHANGES_TYPES,
} from '../../../../../../../pages/ClientDetails/views/CareProgram/constants';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ISO_DATE_ONLY_FORMAT } from '../../../../../../../shared/constants';
import CustomDialog from '../../../../../../../components/Dialog';
import MultilineInput from '../../../../../../../components/Inputs/MultilineInput';
import SelectDate from './SelectDate';

function SmallDialogs() {
  const {
    closeDialog,
    effectiveDate,
    innerDialogType,
    removedVisit,
    setEffectiveDate,
    setRemovedVisit,
    setUpdatedCareProgram,
    showAddNewProgramVisit,
    showProgramVisitsView,
    updatedCareProgram,
  } = useContext(EditCareProgramContext);
  const [removingReason, setRemovingReason] = useState('');

  const removeVisit = useCallback(() => {
    const newProgramList = [...updatedCareProgram];
    const programIndex = newProgramList.findIndex(item => item.id === removedVisit.id);
    newProgramList[programIndex] = {
      ...removedVisit,
      deletionReason: removingReason,
      effectiveDateEnd: effectiveDate.format(ISO_DATE_ONLY_FORMAT),
      changesType: VISITS_CHANGES_TYPES.delete,
    };
    setUpdatedCareProgram(newProgramList);
    closeDialog();
    setRemovedVisit(null);
  }, [
    closeDialog,
    effectiveDate,
    removedVisit,
    removingReason,
    setRemovedVisit,
    setUpdatedCareProgram,
    updatedCareProgram,
  ]);
  const hasFeaturedVisits = updatedCareProgram.some(visit =>
    moment(visit.effectiveDateStart, ISO_DATE_ONLY_FORMAT).isAfter(moment()),
  );

  const getDialogs = useMemo(
    () => [
      {
        cancelCallback: closeDialog,
        child: (
          <SelectDate
            date={effectiveDate}
            selectDate={setEffectiveDate}
            showWarning
            warningTitle={
              hasFeaturedVisits ? 'New effective date will be applied to all visits' : null
            }
          />
        ),
        disabledSubmit: !effectiveDate,
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.effectiveDate,
        submitButtonName: 'proceed',
        submitCallback: updatedCareProgram.length ? showProgramVisitsView : showAddNewProgramVisit,
        title: 'Set Effective Date',
      },
      {
        cancelCallback: closeDialog,
        child: (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 28px 28px 28px',
            }}
          >
            <Typography variant="body1" sx={{ mb: '24px' }}>
              The visit will be removed. Do you want to proceed?
            </Typography>
            <MultilineInput
              changeDetails={event => setRemovingReason(event.target.value)}
              label="Reason"
              value={removingReason}
              maxLength={500}
            />
          </Box>
        ),
        isOpen: innerDialogType === UPDATE_PROGRAM_POPUPS.removeProgram,
        submitButtonName: 'Remove visit',
        submitCallback: removeVisit,
        title: 'Confirmation',
      },
    ],
    [
      closeDialog,
      effectiveDate,
      hasFeaturedVisits,
      innerDialogType,
      removeVisit,
      removingReason,
      setEffectiveDate,
      showAddNewProgramVisit,
      showProgramVisitsView,
      updatedCareProgram.length,
    ],
  );
  return getDialogs?.map(
    ({
      cancelCallback,
      child,
      disabledSubmit,
      isOpen,
      submitButtonName,
      submitCallback,
      title,
    }) => (
      <CustomDialog
        key={title}
        cancelButtonName="cancel"
        cancelCallback={cancelCallback}
        disabledSubmit={disabledSubmit}
        slotProps={{
          backdrop: {
            style: { backgroundColor: 'rgba(27, 29, 38, 0.6)' },
          },
        }}
        submitButtonName={submitButtonName}
        submitCallback={submitCallback}
        title={title}
        openDialog={isOpen}
      >
        {child}
      </CustomDialog>
    ),
  );
}

export default React.memo(SmallDialogs);
