import { Box, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetTerritoryQuery, useGetTeamsQuery } from '../../api/Administration/api';
import { useGetCaregiversListQuery } from '../../api/Caregivers/api';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { SEARCH_INPUT_DEBOUNCE_DELAY } from '../../shared/constants';
import { useDebouncedCallback } from '../../shared/hooks/useDebounce';
import { getNoData } from '../../shared/utils/common';
import AlertContext from '../../components/Alert';
import CustomTable from '../../components/Table';
import Filters from './components/Filters';
import {
  CAREGIVER_LOCAL_STORAGE_KEYS,
  CaregiversFilters,
  DEFAULT_SORT_MODAL,
  INITIAL_CAREGIVERS_FILTERS,
  INITIAL_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
  columns,
  getLocalStorageValues,
} from './constants';
import { customTableRowStyles } from './styles';
import { filterCaregivers } from './utils';

export default function CaregiversView() {
  const [caregiversFilters, setCaregiversFilters] = useState(INITIAL_CAREGIVERS_FILTERS);

  const [sortModel, setSortModel] = useState(DEFAULT_SORT_MODAL);
  const [paginationModel, setPaginationModel] = useState(() => {
    const storedPageSize = localStorage.getItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPageSize);
    return storedPageSize ? JSON.parse(storedPageSize) : INITIAL_PAGE_SIZE;
  });
  const { setAlert } = useContext(AlertContext);
  const apiRef = useGridApiRef();
  const {
    data: caregiversData,
    error: caregiversError,
    isLoading,
    status,
  } = useGetCaregiversListQuery();
  const navigate = useNavigate();
  const {
    data: territories,
    error: territoriesError,
    isLoading: isTerritoriesLoading,
  } = useGetTerritoryQuery();

  const { data: teams, error: teamsError, isLoading: istTeamsLoading } = useGetTeamsQuery();

  const [location, setLocation] = useState([]);

  useEffect(() => {
    if (territories?.length > 0 && teams?.length > 0) {
        const tempLocations = [];
        territories.forEach(territory => {
            const territoryIsActive = !territory.status || territory.status.toLowerCase() === 'active';

            if (territoryIsActive) {
                let thisTeams = [];
                teams.forEach(team => {
                    const teamIsActive = !team.status || team.status.toLowerCase() === 'active'; 
                    if (team.territory === territory.id && teamIsActive) {
                        thisTeams.push(team);
                    }
                });
                if (thisTeams.length > 0) {
                    tempLocations.push({
                        ...territory,
                        teams: thisTeams,
                    });
                }
            }
        });
        setLocation(tempLocations);
    }
  }, [territories, teams]);

  const locationsList = useMemo(() => [...location], [location]);

  useEffect(() => {
    if (territoriesError || teamsError || caregiversError) {
      const errorData = territoriesError || teamsError || caregiversError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [caregiversError, territoriesError, teamsError, setAlert]);
  useEffect(() => {
    setSortModel(DEFAULT_SORT_MODAL);
  }, [caregiversFilters]);

  const onRowClick = data => {
    navigate(`${data.id}`);
  };

  const debouncedSearch = useDebouncedCallback(e => {
    if (!e.target.value.length || e.target.value.length > 2) {
      setCaregiversFilters({
        ...caregiversFilters,
        [e.target.name]: e.target.value,
      });
    }
    // if (e.target.name === 'phoneNumber') {
    //   localStorage.setItem(
    //     CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPhoneNumber,
    //     JSON.stringify(e.target.value),
    //   );
    // }

    if (e.target.name === 'caregiverName') {
      localStorage.setItem(
        CAREGIVER_LOCAL_STORAGE_KEYS.caregiverSearch,
        JSON.stringify(e.target.value),
      );
    }
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const changeStatusFilters = useCallback(({ field, value }) => {
    setCaregiversFilters(prevState => ({ ...prevState, [field]: value }));
    localStorage.setItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverStatus, JSON.stringify(value));
  }, []);

  const changeTeamsFilters = useCallback(value => {
    localStorage.setItem(
      CAREGIVER_LOCAL_STORAGE_KEYS.caregiverTeamsFilter,
      JSON.stringify({ teams: value }),
    );
  }, []);

  const changePageSize = useCallback(model => {
    localStorage.setItem(CAREGIVER_LOCAL_STORAGE_KEYS.caregiverPageSize, JSON.stringify(model));
    setPaginationModel(model);
  }, []);

  useEffect(() => {
    getLocalStorageValues({
      locationsList,
      setCaregiversFilters,
      setPaginationModel,
    });
  }, [locationsList]);

  const updateSorting = useCallback(data => setSortModel(data[0]), []);
  const isFetching = status === 'pending';
  const filteredCaregivers = useMemo(
    () => filterCaregivers(caregiversFilters, caregiversData || []),
    [caregiversFilters, caregiversData],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      caregiversFilters,
      changeStatusFilters,
      changeTeamsFilters,
      debouncedSearch,
      isFetching,
      isLoading,
      isTerritoriesLoading,
      locationsList,
      setCaregiversFilters,
    }),
    [
      caregiversFilters,
      changeStatusFilters,
      debouncedSearch,
      isLoading,
      isFetching,
      locationsList,
      setCaregiversFilters,
      changeTeamsFilters,
      isTerritoriesLoading,
    ],
  );
  return (
    <CaregiversFilters.Provider value={memoizedProviderValue}>
      <Box sx={{ width: '100%', maxHeight: 'calc(100vh - 180px)' }}>
        <Typography variant="h1" sx={{ mb: '24px' }}>
          Caregivers
        </Typography>
        <Filters />
        <CustomTable
          apiRef={apiRef}
          columns={columns}
          customPaginationModel={paginationModel}
          customStyles={customTableRowStyles}
          getRowClassName={params =>
            !params.row?.primaryTeams?.length ? 'has-no-primary-teams' : ''
          }
          headerHeight={44}
          isLoading={isTerritoriesLoading || istTeamsLoading || isFetching}
          noRowsOverlay={() => getNoData({ title: 'Have no caregivers by this request data' })}
          onRowClick={onRowClick}
          page={paginationModel.page}
          pageSize={paginationModel.pageSize}
          pageSizeOptions={PAGE_SIZE_OPTIONS}
          rowHeight={50}
          rows={filteredCaregivers}
          setCustomPaginationModel={changePageSize}
          setSortModelState={updateSorting}
          sortModel={[sortModel]}
          withSavedPagination
        />
      </Box>
    </CaregiversFilters.Provider>
  );
}
