import { Box, Typography } from '@mui/material';
import { instanceOf } from 'prop-types';
import React from 'react';
import { ptoDetailContainer, ptoEventWrapper } from './styles';

export default function PTOEventComponent({ event = {} }) {
  return (
    <Box sx={ptoEventWrapper}>
      <Box sx={ptoDetailContainer}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: '600',
            fontSize: '14px',
          }}
        >
          {event.isCallout === true ? 'Callout' : 'PTO'}
          {event?.ptoTypeDetails?.name?.length > 0 ? ` (${event?.ptoTypeDetails?.name})` : ''}
        </Typography>
      </Box>
      {event.details && (
        <Box sx={ptoDetailContainer}>
          <Typography variant="body2">{event.details}</Typography>
        </Box>
      )}
    </Box>
  );
}

PTOEventComponent.propTypes = {
  event: instanceOf(Object),
};
