import { Box } from '@mui/material';
import { bool, func, instanceOf, string } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../../components/Dialog';
import SelectEffectiveDate from './SelectEffectiveDate';

function EffectiveDateModal({
  openEffectiveDateDialog = false,
  closeEffectiveDateDialog = () => {},
  submitEffectiveDateDialog = () => {},
  date = {},
  selectDate = () => {},
  title = '',
  tooltip = '',
  enableToday = false,
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeEffectiveDateDialog}
      submitButtonName="Proceed"
      submitCallback={submitEffectiveDateDialog}
      title={title}
      openDialog={openEffectiveDateDialog}
    >
      <Box sx={{ padding: '0 28px 20px 13px' }}>
        <SelectEffectiveDate
          date={date}
          selectDate={selectDate}
          tooltip={tooltip}
          enableToday={enableToday}
        />
      </Box>
    </CustomDialog>
  );
}

EffectiveDateModal.propTypes = {
  openEffectiveDateDialog: bool,
  closeEffectiveDateDialog: func,
  submitEffectiveDateDialog: func,
  date: instanceOf(Object),
  selectDate: func,
  title: string,
  tooltip: string,
  enableToday: bool,
};

export default EffectiveDateModal;
