/* eslint-disable no-param-reassign */
import { Box } from '@mui/material';
import {
  useGetHolidaysQuery,
  useGetTerritoryQuery,
  useGetTeamsQuery,
} from '../../api/Administration/api';
import { useGetAllTimeOffRequestsQuery } from '../../api/Scheduler/api';
import moment from 'moment-timezone';
import { bool, func, instanceOf, string } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { CALIFORNIA_TIME_ZONE } from '../../shared/constants';
import { VisitInfoTypeContext } from '../../shared/context';
// import { useWebSocket } from '../../shared/hooks/useWebsocket';
import { checkIfIsHoliday, combineTeamsAndTerritory } from '../../shared/utils/common';
import AlertContext from '../../components/Alert';
import CommonScheduling from './components/CommonScheduling';
import EditScheduling from './components/EditCalendar/EditScheduling';
import ExpandButton from './components/ExpandButton';
import PtoAndCallouts from './components/PtoAndCalloutsTable';
import CustomHeader from './components/SchedulingHeader/SchedulerCalendarHeader';
import SchedulingToolbar from './components/SchedulingToolbar';
import VisitsTableView from './components/VisitsTable';
import {
  DEFAULT_HEADER_HEIGHT,
  EXPANDED_HEADER_HEIGHT,
  HIDE_HEADER_HEIGHT,
  HIDE_HEADER_HEIGHT_EXPANDED,
  LOCAL_STORAGE_KEYS,
  TIME_CONTENT_HEIGHT,
  TIME_CONTENT_HEIGHT_EXPANDED,
  notAllocatedDescription,
} from './constants';
import './index.css';
import { hideContent, hideHeader } from './styles';
import { calculateResourceStatistics, getCaregiverConstraints } from './utils';
import { groupBy, sortBy } from 'lodash';
import { callOutInitialState } from './components/ScheduleCaregiverManagement/constants';

export default function Scheduling({
  currentDate = {},
  eventsLoading = false,
  reFetchCalendarEvents = () => {},
  schedulerEvents = null,
  setCurrentDate = () => {},
  today = {},
  todayDate = '',
  reOptimize = () => {},
}) {
  const { tab } = useParams();
  const eventWrapperRef = useRef();
  const { setAlert } = useContext(AlertContext);
  const { scheduleEvents, caregiversList, notAllocatedEvents, tableViewEvents } =
    schedulerEvents || {};
  const { onOpenVisitInfoTypeDialog, visitInfoData, handleNewVisitInfoData, closeDialog } =
    useContext(VisitInfoTypeContext);
  const { data: holidays, error: getHolidaysError } = useGetHolidaysQuery();
  const { data: territories, error: territoriesError } = useGetTerritoryQuery();
  const { data: teams, error: teamsError } = useGetTeamsQuery();
  const { data: allTimeOffRequests, error: ptoError } = useGetAllTimeOffRequestsQuery();
  const [location, setLocation] = useState([]);
  const [sortedCaregivers, setSortedCaregivers] = useState([]);

  useEffect(() => {
    if (territories?.length > 0 && teams?.length > 0) {
        const tempLocations = [];
        territories.forEach(territory => {
            const territoryIsActive = !territory.status || territory.status.toLowerCase() === 'active';

            if (territoryIsActive) {
                let thisTeams = [];
                teams.forEach(team => {
                    const teamIsActive = !team.status || team.status.toLowerCase() === 'active'; 
                    if (team.territory === territory.id && teamIsActive) {
                        thisTeams.push(team);
                    }
                });
                if (thisTeams.length > 0) {
                    tempLocations.push({
                        ...territory,
                        teams: thisTeams,
                    });
                }
            }
        });
        setLocation(tempLocations);
    }
  }, [territories, teams]);

  useEffect(() => {
    if (getHolidaysError || territoriesError || teamsError || ptoError) {
      const errorData = getHolidaysError || territoriesError || teamsError || ptoError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getHolidaysError, territoriesError, teamsError, ptoError, setAlert]);

  const locationsList = useMemo(() => [...location], [location]);

  const getInitialTeamsList = useMemo(() => {
    const allLocations = combineTeamsAndTerritory(locationsList);
    return localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.teamsFilter))
      : { teams: allLocations?.map(t => t) };
  }, [locationsList]);

  const [editMode, setEditMode] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [currentTimeMinutes, setCurrenTimeMinutes] = useState(moment().minutes());

  const [showDetails, setShowDetails] = useState(false);
  const [teamsFilter, setTeamsFiler] = useState(getInitialTeamsList);
  const [caregivers, setCaregivers] = useState(null);

  useEffect(() => {
    setTeamsFiler(getInitialTeamsList);
  }, [getInitialTeamsList]);

  const isDateHoliday = checkIfIsHoliday(
    currentDate || todayDate,
    holidays,
    currentDate || todayDate,
  );
  const isCalendarView = tab === 'calendar';
  // const { message } = useWebSocket();

  useEffect(() => {
    if (scheduleEvents) {
      const eventsToSet = [
        ...scheduleEvents,
        ...notAllocatedEvents.filter(
          notAllocatedEvent =>
            teamsFilter?.teams.findIndex(team => notAllocatedEvent?.team === team.id) > -1,
        ),
      ];
      if (allTimeOffRequests?.length) {
        for (const pto of allTimeOffRequests) {
          if (
            (moment(currentDate ?? todayDate).isSame(moment(pto?.from), 'date') ||
              moment(currentDate ?? todayDate).isSame(moment(pto?.to), 'date')) &&
            pto?.status === 'Approved'
          ) {
            eventsToSet.push({
              ...pto,
              start: new Date(pto.from),
              end: new Date(pto.to),
              type: 'PTO',
              resourceId: pto.caregiverId,
            });
          }
        }
      }
      setEventsList(eventsToSet);
      if (eventsToSet.length && visitInfoData?.id) {
        const updatedEvent = eventsToSet.find(event => event?.id === visitInfoData.id);
        if (updatedEvent) {
          handleNewVisitInfoData(updatedEvent);
        } else {
          closeDialog(true);
        }
      }
    }
    // eslint-disable-next-line
  }, [scheduleEvents, allTimeOffRequests, teamsFilter]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeIndicator = document.querySelector('.rbc-current-time-indicator');
      const currentTimeIndicatorLabel = document.querySelector('.custom-time-indicator-scheduling');
      const newTime = moment().tz(CALIFORNIA_TIME_ZONE).minutes();
      if (currentTimeMinutes !== newTime || !currentTimeIndicatorLabel) {
        setCurrenTimeMinutes(newTime);
        const currentTimeString = `${moment().tz(CALIFORNIA_TIME_ZONE).format('h:mm A')}(PST)`;
        currentTimeIndicator?.classList.add('custom-time-indicator-scheduling');
        document
          .querySelector('.custom-time-indicator-scheduling')
          ?.setAttribute('data-current-time', currentTimeString);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [currentTimeMinutes]);

  useEffect(() => {
    if (
      (caregiversList && caregiversList.length > 0) ||
      (notAllocatedEvents && notAllocatedEvents.length > 0)
    ) {
      const tempFilteredCaregivers = caregiversList?.filter(caregiver =>
        teamsFilter?.teams?.find(
          team =>
            caregiver?.primaryTeams?.includes(team.id) ||
            caregiver?.secondaryTeams?.includes(team.id),
        ),
      );

      const tempFilteredNotAllocatedCaregivers = notAllocatedEvents?.filter(item =>
        teamsFilter?.teams?.some(team => item?.team?.includes(team.id)),
      );

      const combinedCaregivers = [
        ...(tempFilteredCaregivers || []),
        ...(tempFilteredNotAllocatedCaregivers || []),
      ];

      setCaregivers(combinedCaregivers);
    }
  }, [caregiversList, notAllocatedEvents, teamsFilter]);

  const resourceMap = useMemo(() => {
    if (caregivers) {
      const sortedTeams = [];
      const groupedTeams = groupBy(teams, 'territoryName');
      const territoryKeys = Object.keys(groupedTeams ?? {}).sort((a, b) => a.localeCompare(b));
      for (const territoryKey of territoryKeys) {
        const sortedTerritoryTeams = [...(groupedTeams[territoryKey] ?? [])].sort((a, b) =>
          a?.name?.localeCompare(b?.name),
        );
        sortedTeams.push(...sortedTerritoryTeams);
      }
      const tempSortedCaregivers = [];
      for (const team of sortedTeams) {
        const filteredCaregivers = sortBy(
          caregivers.filter(caregiver => caregiver?.primaryTeams?.includes(team.id)),
          ['firstName'],
        );
        for (const filteredCaregiver of filteredCaregivers) {
          if (
            !tempSortedCaregivers.find(
              sortedCaregiver => sortedCaregiver.id === filteredCaregiver.id,
            )
          ) {
            tempSortedCaregivers.push(filteredCaregiver);
          }
        }
      }
      const caregiversWithoutTeams = sortBy(
        caregivers.filter(caregiver => !caregiver?.primaryTeams?.length),
        ['firstName'],
      );
      const hasNotAllocatedVisits = caregivers.some(
        caregiver => caregiver.caregiverId === 'NoCaregiver',
      );

      const addAllocatedVisits = [
        ...(hasNotAllocatedVisits ? [notAllocatedDescription] : []),
        ...tempSortedCaregivers,
        ...caregiversWithoutTeams,
      ];
      setSortedCaregivers([...addAllocatedVisits]);

      return addAllocatedVisits
        .filter(caregiver => caregiver.caregiverId !== 'NoCaregiver')
        .map((caregiver, index) => {
          const resourceStatistics = calculateResourceStatistics(
            caregiver,
            eventsList.filter(event => event.type !== 'PTO'),
            currentDate ?? todayDate,
            allTimeOffRequests,
          );
          const tempCaregiver = getCaregiverConstraints(caregiver, eventsList);

          return {
            resourceId: caregiver.id,
            resourceTitle: (
              <CustomHeader
                key={index}
                caregiver={tempCaregiver}
                editMode={editMode}
                isDateHoliday={isDateHoliday}
                selectedDate={currentDate}
                resourceStatistics={resourceStatistics}
              />
            ),
          };
        });
    }
  }, [
    caregivers,
    teams,
    eventsList,
    currentDate,
    todayDate,
    editMode,
    isDateHoliday,
    allTimeOffRequests,
  ]);

  const dayPropGetter = useCallback(() => {
    const dayProps = {};

    if (isDateHoliday) {
      dayProps.className = 'holiday';
      dayProps.style = {
        ...dayProps.style,
        background:
          'repeating-linear-gradient(135deg, #EAF1FF, #EAF1FF 6px, #fff 5px, #fff 12px) !important',
      };
    }
    return dayProps;
  }, [isDateHoliday]);

  const onNavigate = useCallback(
    newDate => {
      setCurrentDate(newDate);
    },
    [setCurrentDate],
  );

  const findIfAvailable = (slotTime, availabilities, date) => {
    let foundAvailability = false;
    for (let availability in availabilities || []) {
      const startTimeHours = moment(availabilities?.[availability]?.startTime, 'h:mm:ss');
      const endTimeHours = moment(availabilities?.[availability]?.endTime, 'h:mm:ss');
      let hours = startTimeHours?.hours();
      let minutes = startTimeHours?.minutes();
      if (minutes === 0) {
        hours = hours - 1;
        minutes = 59;
      } else {
        minutes = minutes - 1;
      }
      const startTime = moment(date).set({
        hour: hours,
        minute: minutes,
        second: 0,
        millisecond: 0,
      });
      const endTime = moment(date).set({
        hour: endTimeHours?.hours(),
        minute: endTimeHours?.minutes(),
        second: 0,
        millisecond: 0,
      });
      if (slotTime.isBetween(startTime, endTime)) {
        foundAvailability = true;
      }
    }
    return foundAvailability;
  };

  const onPto = (caregiverPto, slotTime) => {
    for (let pto in caregiverPto) {
      if (
        slotTime.isBetween(
          moment(caregiverPto[pto].from, 'YYYY-MM-DDTHH:mm:ss'),
          moment(caregiverPto[pto].to, 'YYYY-MM-DDTHH:mm:ss'),
        )
      ) {
        return true;
      }
    }
    return false;
  };

  const slotPropGetter = useCallback(
    (date, resourceId) => {
      let slotProps = {};
      if (resourceId) {
        const thisWeekday = moment(date).weekday();
        const slotTime = moment(date);
        if (resourceId === 1) {
          slotProps.style = {
            background: '#F7F9FF',
          };
        }
        const caregiver = caregivers?.find(care => care.id === resourceId);
        if (!isDateHoliday) {
          let thisAvailability = caregiver?.availabilities;
          if (caregiver?.pendingChanges?.availabilities) {
            for (let availDate in caregiver?.pendingChanges?.availabilities) {
              const availDateMoment = moment(availDate, 'YYYY-MM-DD');
              if (availDateMoment <= moment(date)) {
                thisAvailability = caregiver?.pendingChanges?.availabilities[availDate];
              }
            }
          }
          const oneTimeAvailabilities = caregiver?.oneTimeAvailabilities.filter(
            oneTimeAvailability =>
              moment(oneTimeAvailability.effectiveStartDate).isSame(moment(date), 'day'),
          );
          const regularFoundAvailability = findIfAvailable(
            slotTime,
            thisAvailability?.[thisWeekday],
            date,
          );
          const oneTimeFoundAvailability = findIfAvailable(slotTime, oneTimeAvailabilities, date);

          const caregiverPto = allTimeOffRequests?.filter(
            pto => pto.caregiverId === resourceId && pto.status === 'Approved' && !pto?.isCallout,
          );

          const caregiverCallouts = allTimeOffRequests?.filter(
            pto => pto.caregiverId === resourceId && pto.status === 'Approved' && pto?.isCallout,
          );
          const isOnPto = onPto(caregiverPto, slotTime);
          const isOnCallout = onPto(caregiverCallouts, slotTime);

          if (regularFoundAvailability) {
            slotProps.style = {
              background: '#fff',
            };
          }

          if (isOnPto) {
            slotProps.style = {
              background: 'rgba(255,140,0, 0.3)',
            };
          }

          if (oneTimeFoundAvailability) {
            slotProps.style = {
              background: 'rgb(243, 250, 247)',
            };
          }

          if (isOnCallout) {
            slotProps.style = {
              background: 'rgba(255,0,0, 0.3)',
            };
          }

          const startTime = moment(date).set({
            hour: 8,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const endTime = moment(date).set({
            hour: 19,
            minute: 59,
            second: 59,
            millisecond: 0,
          });

          if ((slotTime.isBefore(startTime) || slotTime.isAfter(endTime)) && !isOnCallout) {
            slotProps.style = {
              background: '#000',
            };
          }
        }
      }
      return slotProps;
    },
    [caregivers, isDateHoliday, allTimeOffRequests],
  );

  useEffect(() => {
    const timeContent = document.querySelector('.rbc-time-content');
    const timeHeader = document.querySelector('.rbc-time-header');
    if (showDetails && timeHeader) {
      timeHeader?.classList.add('custom-time-header-scheduling');
      timeHeader.style.minHeight = EXPANDED_HEADER_HEIGHT;
    } else if (timeHeader) {
      timeHeader?.classList.remove('custom-time-header-scheduling');
      timeHeader.style.minHeight = DEFAULT_HEADER_HEIGHT;
    }

    if (timeContent) {
      if (showDetails) {
        timeContent.style.maxHeight = TIME_CONTENT_HEIGHT_EXPANDED;
      } else {
        timeContent.style.maxHeight = TIME_CONTENT_HEIGHT;
      }
    }

    const expandedHeader = document.querySelectorAll('.expandedHeader');
    const collapsedHeader = document.querySelectorAll('.collapsedHeader');
    if (expandedHeader) {
      expandedHeader.forEach(headerStyle => {
        if (showDetails) {
          headerStyle.style.opacity = '1';
          headerStyle.style.height = 'auto';
          headerStyle.style.visibility = 'visible';
        } else {
          // headerStyle.style.display = 'none';
          headerStyle.style.opacity = '0';
          headerStyle.style.height = '0';
          headerStyle.style.visibility = 'hidden';
        }
      });
    }
    if (collapsedHeader) {
      collapsedHeader.forEach(headerStyle => {
        if (showDetails) {
          headerStyle.style.display = 'none';
        } else {
          headerStyle.style.display = 'block';
        }
      });
    }
  }, [caregivers, showDetails, tab, editMode, eventsList, onNavigate]);

  const onSelectEvent = useCallback(
    infoTypeData => {
      if (
        infoTypeData.title === 'Lunch break' ||
        infoTypeData.isShadowVisit ||
        infoTypeData.type === 'PTO'
      ) {
        return;
      }
      onOpenVisitInfoTypeDialog(infoTypeData);
    },
    [onOpenVisitInfoTypeDialog],
  );

  const minStartTime = moment().subtract(30, 'minute').toDate();
  const firstLastAvailability = { first: 6, last: 21 };

  const calendarComponent = useMemo(
    () => (
      <CommonScheduling
        currentDate={currentDate}
        dayPropGetter={dayPropGetter}
        eventsList={eventsList}
        firstVisit={firstLastAvailability.first}
        isDateHoliday={isDateHoliday}
        lastVisit={firstLastAvailability.last}
        minStartTime={minStartTime}
        onNavigate={onNavigate}
        onSelectEvent={onSelectEvent}
        resourceMap={resourceMap}
        slotPropGetter={slotPropGetter}
        reOptimize={reOptimize}
      />
    ), // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, eventsList, onNavigate, dayPropGetter, resourceMap],
  );

  const changeTeamsFilter = useCallback(newTeamsValue => {
    setTeamsFiler(newTeamsValue);
    localStorage.setItem(LOCAL_STORAGE_KEYS.teamsFilter, JSON.stringify(newTeamsValue));
  }, []);

  const editCalendarComponent = useMemo(
    () => (
      <EditScheduling
        currentDate={currentDate}
        dayPropGetter={dayPropGetter}
        eventsList={eventsList}
        filteredCaregivers={sortedCaregivers}
        firstVisit={firstLastAvailability.first}
        isDateHoliday={isDateHoliday}
        lastVisit={firstLastAvailability.last}
        notAllocatedEvents={notAllocatedEvents}
        onNavigate={onNavigate}
        resourceMap={resourceMap}
        setEditMode={setEditMode}
        setTeamsFiler={changeTeamsFilter}
        showDetails={showDetails}
        slotPropGetter={slotPropGetter}
        teamsFilter={teamsFilter}
        todayDate={todayDate}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDate, caregivers, editMode, eventsList, onNavigate, dayPropGetter, resourceMap],
  );

  const viewComponent = useMemo(() => {
    if (tab === 'calendar') {
      return <div style={{ width: '100%', position: 'relative' }}>{calendarComponent}</div>;
    }
    if (tab === 'visits') {
      return (
        <VisitsTableView
          date={currentDate || today}
          eventsArray={tableViewEvents}
          isDateHoliday={isDateHoliday}
          isLoading={eventsLoading}
          locationsList={locationsList}
          onNavigate={onNavigate}
          reOptimize={reOptimize}
          selectedDate={currentDate || today}
          setTeamFilter={changeTeamsFilter}
          teamsFilter={teamsFilter}
          reFetchCalendarEvents={reFetchCalendarEvents}
        />
      );
    }
    if (tab === 'pto & callouts') {
      return (
        <PtoAndCallouts
          teamsArray={locationsList}
          teamsFilter={teamsFilter}
          setTeamFilter={changeTeamsFilter}
        />
      );
    }
    return null;
  }, [
    reFetchCalendarEvents,
    calendarComponent,
    changeTeamsFilter,
    currentDate,
    eventsLoading,
    isDateHoliday,
    locationsList,
    onNavigate,
    reOptimize,
    tab,
    tableViewEvents,
    teamsFilter,
    today,
  ]);
  return (
    <div style={{ width: '100%', position: 'relative' }} ref={eventWrapperRef}>
      {isCalendarView && (
        <Box>
          <Box
            className="hideHeader"
            sx={{
              ...hideHeader,
              height: showDetails ? HIDE_HEADER_HEIGHT_EXPANDED : HIDE_HEADER_HEIGHT,
            }}
          />
          <Box className="hideContent" sx={hideContent} />
        </Box>
      )}
      {isCalendarView && (
        <SchedulingToolbar
          editMode={editMode}
          isDateHoliday={isDateHoliday}
          setEditMode={setEditMode}
          setTeamsFiler={changeTeamsFilter}
          teamsFilter={teamsFilter}
        />
      )}
      {isCalendarView && <ExpandButton setShowDetails={setShowDetails} showDetails={showDetails} />}
      {editMode ? editCalendarComponent : viewComponent}
    </div>
  );
}

Scheduling.propTypes = {
  currentDate: instanceOf(Object),
  eventsLoading: bool,
  reFetchCalendarEvents: func,
  schedulerEvents: instanceOf(Object),
  setCurrentDate: func,
  today: instanceOf(Object),
  todayDate: string,
  reOptimize: func,
};
