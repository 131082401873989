import { Box, Stack } from '@mui/material';
import { useGetTeamsQuery } from '../../../../api/Administration/api';
import {
  useGetCaregiverInfoQuery,
  useUpdateCaregiverInfoMutation,
} from '../../../../api/Caregivers/api';
import { useGetConfigQuery } from '../../../../api/Config/api';
import moment from 'moment';
import {
  CaregiverDetailsContext,
  CaregiverProfileContext,
} from '../../../../pages/CaregiverDetails/context';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import CaregiverHistoryTable from './CaregiverHistory';
import ProfileActions from './ProfileActions';
import ProfileDataRow from './ProfileDataRow';
import { detailsButtons, initialState, userInfoFieldsMap } from './constants';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';
import TeamEffectiveDateModal from './TeamEffectiveDateModal';

export default function CaregiverProfileInfo() {
  const { id, tabSubView } = useParams();
  const navigate = useNavigate();
  const { setAlert } = useContext(AlertContext);
  const [caregiverProfile, setCaregiverProfile] = useState(initialState);
  const [caregiverProfileBeforeEdit, setCaregiverProfileBeforeEdit] = useState(initialState);
  const [teamEffectiveDateOpen, setTeamEffectiveDateOpen] = useState(false);
  const [teamDate, setTeamDate] = useState(
    moment().add(1, 'day').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  );
  const [hasChanges, setHasChanges] = useState(false);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);
  const [pendingTeamChanges, setPendingTeamChanges] = useState({});
  const {
    data: profile,
    isFetching: isDataReFetching,
    isLoading: isDataLoading,
    error: getCaregiverInfoError,
    refetch: refetchCaregiverInfo,
  } = useGetCaregiverInfoQuery(id);
  const [profileData, setProfileData] = useState({});
  useEffect(() => {
    if (profile) {
      setProfileData(profile);
    }
  }, [profile]);

  const { data: skills, error: getSkillsLibraryError } = useGetConfigQuery([
    'skills',
    'genders',
    'languages',
  ]);
  const { data: teams, error: getLocationsListError } = useGetTeamsQuery();
  const [updateCaregiver, { error: updateCaregiverInfoError }] = useUpdateCaregiverInfoMutation();
  const { isEdit, onCloseProfileEditing, onEditProfile } = useContext(CaregiverDetailsContext);
  useEffect(() => {
    if (
      getCaregiverInfoError ||
      getSkillsLibraryError ||
      getLocationsListError ||
      updateCaregiverInfoError
    ) {
      const errorData =
        getCaregiverInfoError ||
        getSkillsLibraryError ||
        getLocationsListError ||
        updateCaregiverInfoError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getCaregiverInfoError,
    getSkillsLibraryError,
    getLocationsListError,
    updateCaregiverInfoError,
    setAlert,
  ]);

  const editingProfileDataLibrary = useMemo(
    () => ({
      skills,
      teams,
    }),
    [teams, skills],
  );

  const initializeCaregiverProfile = useCallback(() => {
    if (profileData) {
      setCaregiverProfile({
        primaryTeams: profileData?.primaryTeams,
        secondaryTeams: profileData?.secondaryTeams,
        skills: profileData?.skills || [],
        languages: profileData?.languages || [],
        gender: profileData?.gender || null,
        status: profileData?.status || '',
        pendingChanges: profileData?.pendingChanges ?? {},
      });
      setCaregiverProfileBeforeEdit({
        primaryTeams: profileData?.primaryTeams,
        secondaryTeams: profileData?.secondaryTeams,
        skills: profileData?.skills || [],
        languages: profileData?.languages || [],
        gender: profileData?.gender || null,
        status: profileData?.status || '',
        pendingChanges: profileData?.pendingChanges ?? {},
      });
    }
  }, [profileData]);

  useEffect(() => {
    let triggerOptimizer = false;
    if (profileData && Object.keys(profileData ?? {}).length) {
      if (
        profileData?.status !== profile?.status &&
        profileData?.status === 'Inactive' &&
        profile?.status === 'Active'
      ) {
        triggerOptimizer = true;
      } else if (profileData?.pendingChanges?.status !== profile?.pendingChanges?.status) {
        triggerOptimizer = true;
      } else if (
        profileData?.pendingChanges?.primaryTeams !== profile?.pendingChanges?.primaryTeams
      ) {
        triggerOptimizer = true;
      } else if (
        profileData?.pendingChanges?.secondaryTeams !== profile?.pendingChanges?.secondaryTeams
      ) {
        triggerOptimizer = true;
      }
      if (triggerOptimizer) {
        setCheckData([profileData]);
        setOpenCheckOptomizer(true);
      } else {
        setCaregiverProfile(profileData);
        setHasChanges(true);
      }
    }
    // eslint-disable-next-line
  }, [profileData]);

  const handleEditClick = useCallback(() => {
    initializeCaregiverProfile();
    onEditProfile();
    setHasChanges(false);
  }, [initializeCaregiverProfile, onEditProfile]);

  const updateCaregiverData = useCallback(
    async updateData => {
      if (Object.keys(pendingTeamChanges ?? {}).length) {
        setTeamEffectiveDateOpen(true);
      } else {
        await updateCaregiver(updateData);
        onCloseProfileEditing();
        setHasChanges(false);
        refetchCaregiverInfo();
      }
    },
    [pendingTeamChanges, updateCaregiver, onCloseProfileEditing, refetchCaregiverInfo],
  );

  const memoizedProviderValue = useMemo(
    () => ({
      caregiverProfile,
      editingProfileDataLibrary,
      handleEditClick,
      hasChanges,
      profileData,
      setProfileData,
      setCaregiverProfile,
      updateCaregiverData,
      teamDate,
      setTeamDate,
      caregiverProfileBeforeEdit,
      id,
      initializeCaregiverProfile,
      pendingTeamChanges,
      setPendingTeamChanges,
    }),
    [
      caregiverProfile,
      editingProfileDataLibrary,
      handleEditClick,
      hasChanges,
      profileData,
      updateCaregiverData,
      teamDate,
      caregiverProfileBeforeEdit,
      id,
      initializeCaregiverProfile,
      pendingTeamChanges,
    ],
  );

  const changeSubView = value => navigate(`/caregivers/${id}/profile/${value}`);

  const changeProfileData = () => {
    let tempPendingChanges = { ...pendingTeamChanges };
    const primaryKeyCheck = Object.keys(tempPendingChanges?.primaryTeamsDetails ?? {});
    const secondaryKeyCheck = Object.keys(tempPendingChanges?.secondaryTeamsDetails ?? {});

    if (primaryKeyCheck.length === 1) {
      tempPendingChanges = {
        ...tempPendingChanges,
        primaryTeams: {
          [moment(teamDate).format('YYYY-MM-DD')]:
            tempPendingChanges?.primaryTeams[primaryKeyCheck[0]],
        },
        primaryTeamsDetails: {
          [moment(teamDate).format('YYYY-MM-DD')]:
            tempPendingChanges?.primaryTeamsDetails[primaryKeyCheck[0]],
        },
      };
    }

    if (secondaryKeyCheck.length === 1) {
      tempPendingChanges = {
        ...tempPendingChanges,
        secondaryTeams: {
          [moment(teamDate).format('YYYY-MM-DD')]:
            tempPendingChanges?.secondaryTeams[secondaryKeyCheck[0]],
        },
        secondaryTeamsDetails: {
          [moment(teamDate).format('YYYY-MM-DD')]:
            tempPendingChanges?.secondaryTeamsDetails[secondaryKeyCheck[0]],
        },
      };
    }

    setPendingTeamChanges({});
    setCheckData([
      { ...profileData, pendingChanges: { ...profileData?.pendingChanges, ...tempPendingChanges } },
    ]);
    setOpenCheckOptomizer(true);
    setTeamEffectiveDateOpen(false);
  };

  const isDataFetching = isDataReFetching || isDataLoading;

  return (
    <CaregiverProfileContext.Provider value={memoizedProviderValue}>
      <OptimizerCheck
        data={checkData}
        dataObjName="caregiverObj"
        submitFunction={updateCaregiverData}
        open={openCheckOptomizer}
        openToggle={setOpenCheckOptomizer}
      />
      <Stack sx={{ maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonsGroup
            buttons={detailsButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
            disabled={isEdit || !profileData}
          />
          <ProfileActions />
        </Box>
        {isDataFetching ? (
          <Loader />
        ) : (
          <Box sx={{ maxWidth: '100%', mt: '32px' }}>
            <TeamEffectiveDateModal
              closeEffectiveDateDialog={() => {
                setTeamEffectiveDateOpen(false);
                setProfileData({
                  ...profileData,
                  pendingChanges: caregiverProfileBeforeEdit.pendingChanges,
                });
                setPendingTeamChanges({});
              }}
              openEffectiveDateDialog={teamEffectiveDateOpen && profileData?.status === 'Active'}
              submitEffectiveDateDialog={changeProfileData}
              date={teamDate}
              selectDate={setTeamDate}
            />
            {tabSubView === 'current' ? (
              userInfoFieldsMap?.map(({ name, type, key }) => (
                <ProfileDataRow
                  key={name}
                  isEdit={isEdit}
                  name={name}
                  profileData={profileData}
                  propKey={key}
                  type={type}
                />
              ))
            ) : (
              <CaregiverHistoryTable />
            )}
          </Box>
        )}
      </Stack>
    </CaregiverProfileContext.Provider>
  );
}
