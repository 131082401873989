import { Box } from '@mui/material';
import moment from 'moment';
import { func } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import {
  CONVERT_TO_UTC_FORMAT,
  H12_TIME_FORMAT,
  ISO_DATE_ONLY_FORMAT,
  visitArrivalTimeTypes,
  visitArrivalTimeTypesNames,
} from '../../../../shared/constants';
import { VisitInfoTypeContext } from '../../../../shared/context';
import ArrivalTypeSelector from '../../../../components/ArrivalTypeSelector';
import { CustomDatePicker } from '../../../../components/CustomDateAndTimePickers';
import CustomTimeSelect from '../../../../components/CustomDateAndTimePickers/CustomTimeSelect';
import ErrorAlert from '../../../../components/ErrorAlert';
import { customDatePickerStyles, timeInputStyles } from '../../styles';
import ArrivalWindow from './ArrivalWindow';
import SetupActions from './SetupActions';
import { visitDateAndTimeWrapperStyles } from './styles';
import OptimizerCheck from '../../../Optimizer/OptimizerCheck';
import { api } from '../../../../api/index';
import AlertContext from '../../../../components/Alert';

export default function VisitDateAndArrivalTimeSetup({ cancelEditing = () => {} }) {
  const {
    initialVisitData,
    isRecheckAvailable,
    temporaryVisitData,
    updateTemporaryVisitData,
    closeDialog,
    reFetchCalendarEvents,
  } = useContext(VisitInfoTypeContext);
  const [visitArrivalTimeType, setVisitArrivalTimeType] = useState('arrivalWindow');
  const [error, setError] = useState(null);
  const [openCheckOptimizer, setOpenCheckOptimizer] = useState(null);
  const { setAlert } = useContext(AlertContext);

  useEffect(() => {
    setVisitArrivalTimeType(temporaryVisitData?.isExactTime ? 'exactTime' : 'arrivalWindow');
  }, [temporaryVisitData?.isExactTime]);

  const changeExactTime = timeValue => {
    const newTimeValue = moment(
      `${temporaryVisitData?.date.substring(0, 10)} ${timeValue}`,
      CONVERT_TO_UTC_FORMAT,
    ).format('YYYY-MM-DDTHH:mm:ss');
    updateTemporaryVisitData({
      arrivalStart: newTimeValue,
      arrivalEnd: newTimeValue,
    });
  };

  const changeArrivalTimeRange = data => {
    const newArrivalStart = moment(
      `${temporaryVisitData?.date.substring(0, 10)} ${data.arrivalStart}`,
      CONVERT_TO_UTC_FORMAT,
    ).format('YYYY-MM-DDTHH:mm:ss');
    const newArrivalEnd = moment(
      `${temporaryVisitData?.date.substring(0, 10)} ${data.arrivalEnd}`,
      CONVERT_TO_UTC_FORMAT,
    ).format('YYYY-MM-DDTHH:mm:ss');
    updateTemporaryVisitData({
      arrivalStart: newArrivalStart,
      arrivalEnd: newArrivalEnd,
    });
  };

  const selectDate = date => {
    updateTemporaryVisitData({
      date: moment(date).startOf('day').format(ISO_DATE_ONLY_FORMAT),
    });
  };

  const closeDateEditing = () => {
    cancelEditing();
    updateTemporaryVisitData({
      arrivalStart: initialVisitData.arrivalStart,
      arrivalEnd: initialVisitData.arrivalEnd,
      date: initialVisitData.date,
    });
  };

  const tranformOptimizerData = (data, initData) => {
    const tempData = {};
    const tempInitData = {};
    if (data) {
      tempData.arrivalStart = data.arrivalStart;
      tempData.arrivalEnd = data.arrivalEnd;
      tempData.clientId = data.clientId;
      tempData.date = data.date;
      tempData.caregiverId = data.caregiverId;
      tempData.id = data.careprogramId;
      if (data.isExactTime){
        tempData.isExactTime = data.isExactTime;
      }
      if (initData && data.date !== initData.date) {
        tempInitData.arrivalStart = initData.arrivalStart;
        tempInitData.arrivalEnd = initData.arrivalEnd;
        tempInitData.clientId = initData.clientId;
        tempInitData.date = initData.date;
        tempInitData.caregiverId = initData.caregiverId;
        tempInitData.id = initData.careprogramId;
        if (initData.isExactTime){
          tempInitData.isExactTime = initData.isExactTime;
        }
        tempInitData.cancel = true;
      }
    }
    if (data && initData) {
      const payload = [tempData];
      if (data.date !== initData.date) {
        payload.push(tempInitData);
      }
      return payload;
    }
  };

  const onChangeArrivalTimeType = e => {
    const isExactTimeSelected = e.target.value === 'exactTime';
    const tempInitialVisitData = { ...initialVisitData };
    if (isExactTimeSelected) {
      tempInitialVisitData.arrivalEnd = tempInitialVisitData.arrivalStart;
      tempInitialVisitData.isExactTime = true;
    } else {
      tempInitialVisitData.isExactTime = false;
    }
    updateTemporaryVisitData(tempInitialVisitData);
  };

  const maxDatePickerDate = moment().startOf('week').add(13, 'days');

  const recheckAvailability = () => {
    setOpenCheckOptimizer(true);
  };

  const submitFunction = async data => {
    closeDialog(true);
    reFetchCalendarEvents();
    const payload = {
      id: data[0].id,
    };
    const careProgramData = await api('GET', 'crud', 'careProgram', payload);
    if (careProgramData.error) {
      setAlert({
        careProgramData,
        type: 'error',
      });
    } else {
      const newDateChange = { arrivalStart: data[0].arrivalStart, arrivalEnd: data[0].arrivalEnd };
      if( data[0]?.isExactTime) {
        newDateChange.isExactTime = true;
      }
      let dateChanges = {};
      if (careProgramData?.data?.dateChanges) {
        dateChanges = { ...careProgramData?.data?.dateChanges };
      }
      dateChanges[data[0].date.slice(0, 10)] = newDateChange;
      if (data.length === 2) {
        const oldDateChange = { canceled: true };
        dateChanges[data[1].date.slice(0, 10)] = oldDateChange;
      }
      careProgramData.data.dateChanges = dateChanges;
      careProgramData.data.oneTimeChange = true;
      const response = await api('PATCH', 'crud', 'careProgram', careProgramData?.data);
      if (response.error) {
        setAlert({
          response,
          type: 'error',
        });
      }
    }
  };

  return (
    <Box sx={visitDateAndTimeWrapperStyles}>
      <ArrivalTypeSelector
        arrivalTimeOptions={visitArrivalTimeTypes}
        arrivalTimeType={visitArrivalTimeType}
        changeArrivalTimeType={e => onChangeArrivalTimeType(e)}
      />
      <Box sx={{ display: 'flex', width: '100%', mt: '12px', gap: '16px' }}>
        <CustomDatePicker
          customStyles={customDatePickerStyles}
          changeDate={newValue => selectDate(newValue)}
          date={moment(temporaryVisitData?.date, ISO_DATE_ONLY_FORMAT)}
          isDisablePast
          label="Visit Scheduled Date"
          maxDate={maxDatePickerDate}
        />
        {visitArrivalTimeType === visitArrivalTimeTypesNames.exactTime ? (
          <CustomTimeSelect
            customStyles={timeInputStyles}
            isRequired
            onSelectTimeValue={changeExactTime}
            placeHolder="Time"
            selectedTime={moment(temporaryVisitData?.arrivalStart).format(H12_TIME_FORMAT)}
          />
        ) : (
          <ArrivalWindow
            arrivalEnd={moment(temporaryVisitData?.arrivalEnd).format(H12_TIME_FORMAT)}
            arrivalStart={moment(temporaryVisitData?.arrivalStart).format(H12_TIME_FORMAT)}
            changeArrivalTimeRange={changeArrivalTimeRange}
            customStyles={timeInputStyles}
            gap={16}
            isRequired
            setError={setError}
          />
        )}
      </Box>
      {error && <ErrorAlert error={error} />}
      <SetupActions
        disableCheckAvailability={!isRecheckAvailable || !!error}
        onCancel={closeDateEditing}
        recheckAvailability={recheckAvailability}
      />
      <OptimizerCheck
        data={tranformOptimizerData(temporaryVisitData, initialVisitData)}
        dataObjName="visitChangeObj"
        submitFunction={submitFunction}
        open={openCheckOptimizer}
        openToggle={setOpenCheckOptimizer}
      />
    </Box>
  );
}

VisitDateAndArrivalTimeSetup.propTypes = {
  cancelEditing: func,
};
