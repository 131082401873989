import { Box, Stack } from '@mui/material';
import { useGetTeamsQuery } from '../../../../api/Administration/api';
import { useGetCaregiversListQuery } from '../../../../api/Caregivers/api';
import { useGetUserDataByIdQuery, useUpdateClientsInfoMutation } from '../../../../api/Clients/api';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import AlertContext from '../../../../components/Alert';
import ButtonsGroup from '../../../../components/ButtonsGroup';
import Loader from '../../../../components/Loader';
import { ClientDetailsContext, ClientProfilesContext } from '../../context';
import ClientProfileHistoryTable from './ClientProfileHistory';
import EditProfileActions from './EditProfileActions';
import InfoView from './InfoView';
import { detailsButtons } from './constants';
import OptimizerCheck from '../../../../components/Optimizer/OptimizerCheck';

export default function ClientsProfileInfo() {
  const { id, tabSubView } = useParams();
  const { setAlert } = useContext(AlertContext);
  const navigate = useNavigate();
  const [hasChanges, setHasChanges] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'day'));

  const { isEdit, onCloseProfileEditing, onEditProfile } = useContext(ClientDetailsContext);

  const { data: teams, error: getLocationsListError } = useGetTeamsQuery();
  const { data: caregivers, error: getCaregiversListError } = useGetCaregiversListQuery({
    refetchOnMountOrArgChange: true,
    index: 'active-index',
    pk: 'active',
    pkValue: 'True',
    filters: JSON.stringify([
      {
        key: 'status',
        value: 'Active',
        operation: '=',
        preOperation: 'AND',
      },
    ]),
  });

  const [clientData, setClientData] = useState(null);
  const [checkData, setCheckData] = useState(null);
  const [openCheckOptomizer, setOpenCheckOptomizer] = useState(false);
  const {
    data: originalClientData,
    isLoading: isDataLoading,
    isFetching: isDataReFetching,
    error: userDataError,
  } = useGetUserDataByIdQuery(id);

  useEffect(() => {
    if (originalClientData) {
      setClientData(originalClientData);
    }
  }, [originalClientData]);

  const [updateClientsInfo, { error: updateClientsInfoError }] = useUpdateClientsInfoMutation();

  useEffect(() => {
    if (
      userDataError ||
      updateClientsInfoError ||
      getCaregiversListError ||
      getLocationsListError
    ) {
      const errorData =
        userDataError || updateClientsInfoError || getCaregiversListError || getLocationsListError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getCaregiversListError,
    getLocationsListError,
    setAlert,
    updateClientsInfoError,
    userDataError,
  ]);

  const editProfile = () => {
    onEditProfile();
    setHasChanges(false);
  };

  const updateClientData = () => {
    updateClientsInfo(clientData);
    onCloseProfileEditing();
    setHasChanges(false);
  };

  useEffect(() => {
    let triggerOptimizer = false;
    if (clientData) {
      if (clientData?.status === 'Active' && originalClientData?.status === 'Suspended') {
        triggerOptimizer = true;
      } else if (
        clientData?.pendingChanges?.status !== originalClientData?.pendingChanges?.status
      ) {
        triggerOptimizer = true;
      } else if (clientData?.pendingChanges?.team !== originalClientData?.pendingChanges?.team) {
        triggerOptimizer = true;
      } else if (clientData?.blockedCaregivers !== originalClientData?.blockedCaregivers) {
        triggerOptimizer = true;
      }
      if (triggerOptimizer) {
        setCheckData([clientData]);
        setOpenCheckOptomizer(true);
      }
    }
    // eslint-disable-next-line
  }, [clientData]);

  const memoizedProviderValue = useMemo(
    () => ({
      clientData,
      setClientData,
      date,
      setDate,
      caregivers,
      teams,
      originalClientData,
    }),
    [clientData, setClientData, date, setDate, caregivers, teams, originalClientData],
  );
  const isDataFetching = isDataReFetching || isDataLoading;

  const cancelEdit = () => {
    onCloseProfileEditing();
    setClientData(originalClientData);
  };

  const changeSubView = value => navigate(`/clients/${id}/profile/${value}`);

  return (
    <ClientProfilesContext.Provider value={memoizedProviderValue}>
      <OptimizerCheck
        data={checkData}
        dataObjName="clientObj"
        submitFunction={updateClientData}
        open={openCheckOptomizer}
        openToggle={setOpenCheckOptomizer}
        cancelFunction={() => setClientData(originalClientData)}
        cancelConfirmation={true}
      />
      <Stack sx={{ maxWidth: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ButtonsGroup
            buttons={detailsButtons}
            changeSelected={changeSubView}
            selected={tabSubView}
            disabled={isEdit || !clientData}
          />
          {tabSubView === 'current' && (
            <EditProfileActions
              cancelEditing={cancelEdit}
              checkBlockedCareGivers={updateClientData}
              editProfile={editProfile}
              hasChanges={hasChanges}
              isEdit={isEdit}
              updateClientData={updateClientData}
              clientData={clientData}
            />
          )}
        </Box>
        {isDataFetching && <Loader />}
        {clientData && (
          <Box sx={{ maxWidth: '100%', mt: '32px' }}>
            {tabSubView === 'current' ? (
              <InfoView isEdit={isEdit} clientData={clientData} />
            ) : (
              <ClientProfileHistoryTable />
            )}
          </Box>
        )}
      </Stack>
    </ClientProfilesContext.Provider>
  );
}
