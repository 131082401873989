import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import { func, instanceOf, number, string } from 'prop-types';
import React, { useCallback, useState } from 'react';
import { isValidText } from '../../../../../../shared/utils/common';
import ErrorAlert from '../../../../../../components/ErrorAlert';
import CustomInput from '../../../../../../components/Inputs';
import CustomSelect from '../../../../../../components/Select';
import {
  colorBoxStyles,
  colorValueStyles,
  customRadioStyles,
  modalBoxStyles,
  muiColorInputStyles,
  teamSelectStyles,
} from './styles';

export default function AddTeamModal({
  color = '#ffffff',
  teamData = [],
  order = 0,
  fontColor = '',
  setColor = () => {},
  setOrder = () => {},
  setFontColor = () => {},
  setTeamName = () => {},
  setTerritory = () => {},
  teamName = '',
  territory = '',
  territoryOptions = [],
}) {
  const handleChange = event => {
    setFontColor(event.target.value);
  };
  const options = Array.from(
    {
      length: teamData.filter(item => item.territory === territory)?.length,
    },
    (_, index) => index + 1,
  );
  const onSelectChange = params => {
    const { name } = params;

    setOrder(parseInt(name, 10) || 0);
  };
  const onSelectTerritory = params => {
    const { id } = params;

    setTerritory(id);
    // setChosenTerritoryID(id)
  };
  const [error, setError] = useState(null);

  const handleChangeTeamName = event => {
    setTeamName(event.target.value);
    setError(null);
  };
  const handleBlurEmail = () => {
    if (!isValidText(teamName)) {
      setError('Team Name is invalid');
    }
    if (teamName?.length === 0 && error) {
      setError(null);
    }
  };

  const handleChangeColor = useCallback(
    newValue => {
      setColor(newValue);
    },
    [setColor],
  );
  return (
    <Box sx={modalBoxStyles}>
      <CustomSelect
        addStyles={teamSelectStyles}
        id="order"
        isLoading={false}
        isMultiselect={false}
        options={
          options.length > 0 ? options.map(num => ({ id: num, name: num })) : [{ id: 1, name: 1 }]
        }
        placeHolder="Order With Territory"
        selectedValue={order}
        setValue={onSelectChange}
      />
      <Box sx={{ display: 'flex', gap: 2, maxHeight: '40px' }}>
        <CustomInput
          changeTaskName={handleChangeTeamName}
          id="team"
          label="Team"
          value={teamName}
          onBlur={handleBlurEmail}
        />
        <CustomSelect
          addStyles={teamSelectStyles}
          id="territory"
          isLoading={false}
          isMultiselect={false}
          options={territoryOptions.map(ter => ({ id: ter.id, name: ter.name }))}
          placeHolder="Territory"
          selectedValue={territory}
          setValue={onSelectTerritory}
        />
      </Box>
      {error && <ErrorAlert error={error} />}
      <Box sx={colorBoxStyles}>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>Team Color</Typography>
        <Box sx={{ position: 'relative' }}>
          <MuiColorInput
            sx={muiColorInputStyles}
            format="hex"
            value={color}
            onChange={handleChangeColor}
            placeholder="TeamColor"
            isAlphaHidden
          />
          <Typography sx={{ color: fontColor, ...colorValueStyles }}>{color}</Typography>
        </Box>
      </Box>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={fontColor}
          onChange={handleChange}
          sx={customRadioStyles}
        >
          <FormControlLabel value="White" control={<Radio />} label="White text" />
          <FormControlLabel value="Black" control={<Radio />} label="Dark text" />
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

AddTeamModal.propTypes = {
  color: string,
  teamData: instanceOf(Array),
  order: number,
  fontColor: string,
  setColor: func,
  setOrder: func,
  setFontColor: func,
  setTeamName: func,
  teamName: string,
  setTerritory: func,
  territory: string,
  territoryOptions: instanceOf(Array),
};
