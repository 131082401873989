import { Box, Typography } from '@mui/material';
import { bool, func } from 'prop-types';
import React from 'react';
import CustomDialog from '../../../../../components/Dialog';

function ClientReactivationDialog({
  openReactivationModal = false,
  closeReactivationModal = () => {},
  confirmReactivation = () => {},
}) {
  return (
    <CustomDialog
      cancelButtonName="Cancel"
      cancelCallback={closeReactivationModal}
      submitButtonName="Confirm"
      submitCallback={confirmReactivation}
      title="Reactivation Confirmation"
      openDialog={openReactivationModal}
    >
      <Box sx={{ padding: '0 25px 20px 25px', maxWidth: '100%' }}>
        <Typography>This cannot be undone. Would you like to proceed?</Typography>
      </Box>
    </CustomDialog>
  );
}

ClientReactivationDialog.propTypes = {
  openReactivationModal: bool,
  closeReactivationModal: func,
  confirmReactivation: func,
};

export default ClientReactivationDialog;
