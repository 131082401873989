import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import {
  SHORT_DATE_FORMAT,
  TIME_OFF_REQUEST_DATE_FORMAT,
  TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
} from '../../../../shared/constants';
import { convertUtcToTeamTimeZoneMomentObject } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import RequestsActions from './components/RequestsActions';
import { badgeAndActionsCellStyles, lastUpdateCellWrapper } from './styles';
import { getBadgeColorScheme } from './utils';
import EditIconButton from '../../../../components/VisitInfoTypeProvider/components/EditIconButton';

export const timeOffRequestTypes = {
  settingParams: 'settingParams',
  checkPossibility: 'checkPossibility',
};

export const viewTypeButtons = [
  { name: 'Current', infoType: 'current' },
  { name: 'History', infoType: 'history' },
];

export const getColumns = editPtoRequest => [
  {
    field: 'id',
    headerName: 'Date Submitted',
    minWidth: 170,
    flex: 1,
    renderCell: ({ row }) =>
      row?.dateCreated ? convertUtcToTeamTimeZoneMomentObject(row?.dateCreated).format(SHORT_DATE_FORMAT) : '-',
    sortable: false,
  },
  {
    field: 'from',
    headerName: 'Start Date & Time',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row.allDay
        ? `${moment(row?.from)?.format(SHORT_DATE_FORMAT)} (All day)`
        : moment(row?.from)?.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'to',
    headerName: 'End Date & Time',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row.allDay
        ? `${moment(row?.to)?.format(SHORT_DATE_FORMAT)} (All day)`
        : moment(row?.to)?.format(TIME_OFF_REQUEST_DATE_FORMAT),
    sortable: false,
  },
  {
    field: 'ptoTypeDetails',
    headerName: 'Request Type',
    minWidth: 150,
    flex: 1,
    renderCell: ({ row }) => (row?.isCallout ? 'Callout' : row?.ptoTypeDetails?.name),
    sortable: false,
  },
  {
    field: 'details',
    headerName: 'Details',
    minWidth: 240,
    sortable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 450,
    flex: 3,
    renderCell: ({ row, value }) => (
      <Box sx={badgeAndActionsCellStyles}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Badge colorScheme={getBadgeColorScheme(row?.status)} label={row?.status} />
        </Box>
        {row.status !== 'Denied' && moment(row.from).isSameOrAfter(moment(), 'date') && (
          <EditIconButton
            onEdit={() =>
              editPtoRequest({
                ...row,
                requestDateStart: moment(row.from).set({ hours: 0, minutes: 0, seconds: 0 }),
                requestTimeStart: row.allDay === true ? undefined : moment(row.from),
                requestDateEnd: moment(row.to).set({ hours: 23, minutes: 59, seconds: 59 }),
                requestTimeEnd: row.allDay === true ? undefined : moment(row.to),
              })
            }
            tooltip="Edit PTO Request"
          />
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <RequestsActions row={row} />
        </Box>
      </Box>
    ),
    sortable: false,
  },
  {
    field: 'dateCreated',
    headerName: 'Last Updated',
    minWidth: 350,
    flex: 1,
    renderCell: ({ row }) => (
      <Box sx={lastUpdateCellWrapper}>
        {row?.dateCreated ? (
          <Stack sx={{ width: '160px' }}>
            <Typography sx={{ maxWidth: '160px' }}>
              {row?.updatedBy ? row?.updatedBy : row?.createdByEmail ? row?.createdByEmail : 'N/A'}
            </Typography>
            <Typography sx={{ maxWidth: '100px' }}>
              {row?.dateCreated
                ? convertUtcToTeamTimeZoneMomentObject(row.dateCreated).format(
                    TIME_OFF_REQUEST_LAST_UPDATED_DATE_FORMAT,
                  )
                : ''}
            </Typography>
          </Stack>
        ) : (
          '-'
        )}
      </Box>
    ),
    sortable: false,
  },
];
