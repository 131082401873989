import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryDBMD } from '../../api/baseQueryBEMD';

export const REPORTING_API_KEY = 'reportingApi';
const reportingApi = createApi({
  reducerPath: REPORTING_API_KEY,
  baseQuery: baseQueryDBMD,
  endpoints: builder => ({
    getWeeklyReport: builder.query({
      query: params => ({
        url: 'crud/visits',
        method: 'GET',
        params: JSON.stringify({
          key: 'date',
          value: [params.dateFrom, params.dateTo],
          operation: 'BETWEEN',
          preOperation: 'AND',
        }),
      }),
      transformResponse: response => response,
      providesTags: () => [{ type: 'weeklyReport', id: 'ALL' }],
    }),
    downloadClientReport: builder.mutation({
      query: () => ({
        url: 'report/client',
        method: 'GET',
        responseType: 'blob',
      }),
      transformResponse: response => response,
    }),
  }),
});
export const { useGetWeeklyReportQuery, useDownloadClientReportMutation } = reportingApi;

export default reportingApi;
