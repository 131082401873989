import { CreateOneTimeVisitContext } from '../../../../../../pages/ClientDetails/context';
import { getCreateVisitConfirmButtonName } from '../../../../../../pages/ClientDetails/utils';
import { func, node } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import CustomDialog from '../../../../../../components/Dialog';
import UnsavedChangesContent from '../../../../../../components/Dialog/UnsavedChangesContent';
import FullWidthDialog from '../../../../../../components/FullWidthDialog';
import { createOneTimeVisitInitialData, createOneTimeVisitSteps } from '../../constants';
import { checkIsCreateVisitStepConfirmButtonDisabled } from '../../utils';
import { CreateOneTimeVisitMultistep } from './CreateOneTimeVisitMultistep';

export default function CreateOneTimeVisitProvider({
  children = <div />,
  getCalendarData = () => {},
  setManualLoading = () => {},
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [step, setStep] = useState(0);
  const [stepDataLoading, setStepDataLoading] = useState(false);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [visitParams, setVisitParams] = useState(createOneTimeVisitInitialData);
  const [availabilityScheduling, setAvailabilityScheduling] = useState(null);
  const [availableVisitTasks, setAvailableVisitTasks] = useState(null);
  const [isSelectedTimeOutOfRange, setIsSelectedTimeOutOfRange] = useState(false);
  const [alternativeArrivalWindows, setAlternativeArrivalWindows] = useState(null);
  const [pastTimeError, setPastTimeError] = useState(null);
  const [availabilityScheduledVisits, setAvailabilityScheduledVisits] = useState(null);

  const alertUser = useCallback(
    e => {
      if (!openDialog) {
        return true;
      }
      e.preventDefault();
      e.returnValue = '';
      return true;
    },
    [openDialog],
  );

  const clearCreationData = () => {
    setStep(0);
    setCompletedSteps([]);
    setAvailabilityScheduling(null);
    setAvailableVisitTasks([]);
    setVisitParams(createOneTimeVisitInitialData);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  const addOneTimeVisit = () => setOpenDialog(true);

  const handleChangesAdded = useCallback(() => {
    const {
      arrivalEnd,
      arrivalStart,
      dates,
      duration,
      exactTimeSlot,
      genderPreference,
      skills,
      visitType,
    } = visitParams;
    const isChangesAdded =
      step > 0 ||
      !!arrivalStart ||
      !!arrivalEnd ||
      !!dates?.length ||
      !!duration ||
      !!exactTimeSlot ||
      !!genderPreference ||
      !!skills?.length ||
      !!Object.keys(visitType).length;
    return isChangesAdded;
  }, [step, visitParams]);

  const closeDialog = useCallback(() => {
    const isChangesAdded = handleChangesAdded();
    if (isChangesAdded) {
      setOpenConfirmationModal(true);
    } else {
      clearCreationData();
      setOpenDialog(false);
    }
  }, [handleChangesAdded]);

  const submitStep = useCallback(() => {
    if (step === createOneTimeVisitSteps.length - 1) {
      clearCreationData();
      setOpenDialog(false);
      return '';
    }
    if (!completedSteps.includes(step)) {
      setCompletedSteps([...completedSteps, step]);
    }
    if (step === 0 && completedSteps.length > 1) {
      setCompletedSteps([step]);
    }
    if (step === 2) {
      setStepDataLoading(true);
    }
    setStep(prevState => prevState + 1);
    return '';
  }, [completedSteps, step]);

  const goToEditTasks = useCallback(() => {
    navigate(`/clients/${id}/care-plan/current`);
    closeDialog();
  }, [closeDialog, id, navigate]);

  const memoizedProviderValue = useMemo(
    () => ({
      addOneTimeVisit,
      availabilityScheduling,
      availableVisitTasks,
      closeDialog,
      completedSteps,
      goToEditTasks,
      id,
      isSelectedTimeOutOfRange,
      pastTimeError,
      setAvailabilityScheduling,
      setAvailableVisitTasks,
      setIsSelectedTimeOutOfRange,
      setPastTimeError,
      setStep,
      setStepDataLoading,
      setVisitParams,
      step,
      stepDataLoading,
      visitParams,
      submitStep,
      alternativeArrivalWindows,
      setAlternativeArrivalWindows,
      availabilityScheduledVisits,
      setAvailabilityScheduledVisits,
      getCalendarData,
      setManualLoading,
      handleChangesAdded,
    }),
    [
      availabilityScheduling,
      availableVisitTasks,
      closeDialog,
      completedSteps,
      goToEditTasks,
      id,
      isSelectedTimeOutOfRange,
      pastTimeError,
      setPastTimeError,
      setStepDataLoading,
      step,
      stepDataLoading,
      visitParams,
      submitStep,
      alternativeArrivalWindows,
      setAlternativeArrivalWindows,
      availabilityScheduledVisits,
      setAvailabilityScheduledVisits,
      getCalendarData,
      setManualLoading,
      handleChangesAdded,
    ],
  );

  return (
    <CreateOneTimeVisitContext.Provider value={memoizedProviderValue}>
      <>
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={() => setOpenConfirmationModal(false)}
          submitButtonName="confirm"
          submitCallback={() => {
            clearCreationData();
            setOpenDialog(false);
            setOpenConfirmationModal(false);
          }}
          title="Confirmation"
          openDialog={openConfirmationModal}
        >
          <UnsavedChangesContent />
        </CustomDialog>
        <FullWidthDialog
          backButtonCallback={closeDialog}
          cancelButtonName="cancel"
          cancelCallback={closeDialog}
          disabledSubmit={checkIsCreateVisitStepConfirmButtonDisabled({
            alternativeArrivalWindows,
            isSelectedTimeOutOfRange,
            pastTimeError,
            step,
            stepDataLoading,
            visitData: visitParams,
          })}
          submitButtonName={getCreateVisitConfirmButtonName(step, true)}
          submitCallback={submitStep}
          title="Create Visit"
          openDialog={openDialog}
        >
          <CreateOneTimeVisitMultistep />
        </FullWidthDialog>
      </>
      {children}
    </CreateOneTimeVisitContext.Provider>
  );
}

CreateOneTimeVisitProvider.propTypes = {
  children: node,
  getCalendarData: func,
  setManualLoading: func,
};
