import { Box, Typography } from '@mui/material';
import React, { createContext } from 'react';
import { backgroundColorSchemeMap } from '../../shared/constants';
import { COLORS } from '../../shared/styles/Theme';
import { combineTeamsAndTerritory } from '../../shared/utils/common';
import Badge from '../../components/Badge';
import { tableRowOverflowEllipsis, tableRowOverflowHidden } from './styles';

export const ClientsFilters = createContext({});

export const DEFAULT_SORT_MODAL = { field: 'firstName', sort: 'asc' };

export const CLIENTS_STATUS_CHANGING_ACTIONS = {
  resume: 'resume',
  activation: 'reActivate',
  suspend: 'suspend',
  deactivate: 'deactivate',
};

export const CLIENTS_STATUS_CHANGING_ACTIONS_TITLES = {
  resume: 'Resume Client’s Services',
  activation: 'Reactivate Client',
  suspend: 'Suspend Client’s Services',
  deactivate: 'Deactivate Client',
};

export const CLIENTS_STATUSES = [
  { id: 'active', name: 'Active' },
  { id: 'suspended', name: 'Suspended' },
  { id: 'inactive', name: 'Inactive' },
];

export const CLIENTS_STATUSES_MAP = {
  active: 'Active',
  suspended: 'Suspended',
  inactive: 'Inactive',
};
export const INITIAL_CLIENTS_FILTERS = {
  clientName: '',
  teams: [],
  // phoneNumber: '',
  status: [
    { id: 'active', name: 'Active' },
    { id: 'suspended', name: 'Suspended' },
  ],
};
export const CLIENT_LOCAL_STORAGE_KEYS = {
  clientStatus: 'CLIENT_STATUS',
  clientTeamsFilter: 'TEAMS_FILTER',
  clientSearch: 'CLIENT_SEARCH',
  // clientPhoneNumber: 'CLIENT_PHONE_NUMBER',
  clientPageSize: 'CLIENT_PAGE_SIZE',
};

const PAGE_SIZE = 20;

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const INITIAL_PAGE_SIZE = { pageSize: PAGE_SIZE, page: 0 };

const teamsComparator = (a, b) => {
  if (a === null && b === null) {
    return 0;
  }
  if (a === null) {
    return -1;
  }
  if (b === null) {
    return 1;
  }
  return a?.name.localeCompare(b?.name);
};

const columns = [
  {
    field: 'firstName',
    headerName: 'First name',
    minWidth: 140,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    minWidth: 140,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 110,
    flex: 1,
    renderCell: ({ value }) => (
      <Badge
        colorScheme={
          CLIENTS_STATUSES_MAP[value?.toLowerCase()] === 'Active'
            ? backgroundColorSchemeMap.active
            : backgroundColorSchemeMap.inactive
        }
        label={CLIENTS_STATUSES_MAP[value?.toLowerCase()]}
      />
    ),
  },
  {
    field: 'clientType',
    headerName: 'Type',
    minWidth: 200,
    flex: 1,
    sortComparator: (a, b) => a?.localeCompare(b),
    renderCell: ({ row }) => (
      <Box sx={tableRowOverflowHidden}>
        {row?.clientType ? (
          <Typography sx={tableRowOverflowEllipsis}>{row?.clientType}</Typography>
        ) : (
          <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>
            No Client Type
          </Typography>
        )}
      </Box>
    ),
  },
  {
    field: 'territoryName',
    headerName: 'Territory',
    minWidth: 150,
    sortable: false,
    flex: 1,
    renderCell: ({ row }) =>
      row?.territoryName ? (
        <Typography>{row?.territoryName}</Typography>
      ) : (
        <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>No Territory</Typography>
      ),
  },
  {
    field: 'teamDetails',
    headerName: 'Team',
    minWidth: 200,
    flex: 1,
    renderCell: ({ row }) =>
      row?.teamDetails?.name ? (
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '16px',
              height: '16px',
              borderRadius: '3px',
              backgroundColor: row?.teamDetails?.color?.color,
              mr: '10px',
            }}
          />
          <Typography>{row?.teamDetails.name}</Typography>
        </Box>
      ) : (
        <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>No team</Typography>
      ),
    sortComparator: teamsComparator,
  },
  {
    field: 'address',
    headerName: 'Address',
    minWidth: 400,
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      const address = [row?.street, row?.city, row?.state, row?.zipCode, row?.country].filter(
        addressLine => addressLine && addressLine.length > 0,
      );
      return (
        <Box sx={tableRowOverflowHidden}>
          {address.length ? (
            <Typography sx={tableRowOverflowEllipsis}>{address.join(', ')}</Typography>
          ) : (
            <Typography sx={{ color: COLORS.blue[500], fontStyle: 'italic' }}>
              No Address
            </Typography>
          )}
        </Box>
      );
    },
  },
  // {
  //   field: 'phone',
  //   headerName: 'Phone Number',
  //   minWidth: 150,
  //   sortable: false,
  //   renderCell: ({ row }) => {
  //     const numericPhoneNumber = row?.phone?.replace(/\D/g, '');

  //     const formattedPhoneNumber = numericPhoneNumber
  //       ? `(${numericPhoneNumber?.slice(0, 3)}) ${numericPhoneNumber?.slice(
  //           3,
  //           6,
  //         )}-${numericPhoneNumber?.slice(6)}`
  //       : '';
  //     return <Box>{formattedPhoneNumber || ''}</Box>;
  //   },
  // },
];

export { columns, PAGE_SIZE, PAGE_SIZE_OPTIONS };
export const getLocalStorageValues = ({ locationsList, setClientsFilters, setPaginationModel }) => {
  if (locationsList) {
    const initialLocalState = JSON.parse(
      localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientTeamsFilter) ?? '{}',
    );
    const teamsInitialValue = initialLocalState?.teams ? initialLocalState.teams : locationsList;
    const allTeamsValue = initialLocalState?.teams
      ? teamsInitialValue
      : combineTeamsAndTerritory(teamsInitialValue);
    setClientsFilters(prevValue => ({
      ...prevValue,
      teams: allTeamsValue,
    }));
  }
  const statusInitialValue = localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientStatus)
    ? JSON.parse(localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientStatus))
    : INITIAL_CLIENTS_FILTERS.status;
  setClientsFilters(prevValue => ({
    ...prevValue,
    status: statusInitialValue,
  }));
  const searchInitialValue = localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientStatus)
    ? JSON.parse(localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientSearch))
    : INITIAL_CLIENTS_FILTERS.clientName;
  setClientsFilters(prevValue => ({
    ...prevValue,
    clientName: searchInitialValue,
  }));
  // const phoneNumberInitialValue = localStorage.getItem(
  //   CLIENT_LOCAL_STORAGE_KEYS.clientStatus,
  // )
  //   ? JSON.parse(
  //       localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientPhoneNumber),
  //     )
  //   : INITIAL_CLIENTS_FILTERS.phoneNumber;
  // setClientsFilters((prevValue) => ({
  //   ...prevValue,
  //   phoneNumber: phoneNumberInitialValue,
  // }));
  const pageSize = localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientPageSize)
    ? JSON.parse(localStorage.getItem(CLIENT_LOCAL_STORAGE_KEYS.clientPageSize))
    : INITIAL_PAGE_SIZE;
  setPaginationModel(pageSize);
};
