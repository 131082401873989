import { useGetWeekendsSchedulingDaysQuery } from '../../../../api/Administration/api';
import moment from 'moment';
import { bool, func, instanceOf, oneOfType } from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { H12_TIME_FORMAT } from '../../../../shared/constants';
import AlertContext from '../../../../components/Alert';
import CustomDialog from '../../../../components/Dialog';
import { AddTaskContext } from '../../context';
import Content from './components/Content';
import { taskInitialState, noEmptyFields } from './constants';

export default function AddTaskProvider({
  children = [],
  hasPredefinedData = false,
  tasksListData = {},
  createTasksList = () => {},
  updateCarePlanTasks = () => {},
  configData = {},
  isLoadingConfigs = {},
}) {
  const { id } = useParams();
  const { setAlert } = useContext(AlertContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [hasTheSameTask, setHasTheSameTask] = useState(false);
  const { data: disabledWeekDays, error: getWeekdaysError } = useGetWeekendsSchedulingDaysQuery();

  const [taskData, setTaskData] = useState(taskInitialState);

  useEffect(() => {
    if (getWeekdaysError) {
      const errorData = getWeekdaysError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [getWeekdaysError, setAlert]);

  const addNewTask = useCallback(
    visit => {
      let taskPredefinedData = {
        ...taskInitialState,
      };

      if (visit) {
        const day = visit.date ? moment(visit.date).format('dddd') : visit.day;
        const timeOfDays =
          visit.timeOfDays ||
          taskData.timeOfDays.find(({ from, to }) =>
            moment(visit.exactTimeSlot || visit.arrivalStart, H12_TIME_FORMAT).isBetween(
              moment(from, H12_TIME_FORMAT),
              moment(to, H12_TIME_FORMAT),
              'minutes',
              '[]',
            ),
          )?.name;
        taskPredefinedData = {
          ...configData,
          days: [day],
          timeOfDays: timeOfDays ? [timeOfDays] : [],
          visitTypes: [visit.visitType],
        };
      }
      setOpenDialog(true);
      setTaskData(taskPredefinedData);
    },
    [taskData, configData],
  );

  const editTask = useCallback(
    editedTaskId => {
      const editableTask = tasksListData.find(task => task.id === editedTaskId);
      setOpenDialog(true);
      setTaskData(editableTask);
    },
    [tasksListData],
  );

  const applyTaskData = () => {
    const toSaveTaskData = {
      ...taskData,
    };

    if (taskData.days.length) {
      toSaveTaskData.days = taskData.days;
    }
    if (taskData.timeOfDays.length) {
      toSaveTaskData.timeOfDays = taskData.timeOfDays;
    }
    if (taskData.visitTypes.length) {
      toSaveTaskData.visitTypes = taskData.visitTypes;
    }

    const newTask = {
      ...toSaveTaskData,
      name: toSaveTaskData?.name?.trim(),
      clientId: id,
    };

    let updateTask = tasksListData.length
      ? tasksListData.find(item => item.id === newTask.id)
        ? newTask
        : null
      : null;

    if (updateTask) {
      updateCarePlanTasks({
        ...updateTask,
      });
    } else {
      createTasksList({
        ...newTask,
      });
    }

    setOpenDialog(false);
  };

  const changeTaskData = data => {
    if (hasTheSameTask) setHasTheSameTask(false);
    setTaskData(data);
  };

  const isDisabledSave = useMemo(() => {
    const hasEmptyFields = noEmptyFields.some(
      fieldKey => !taskData[fieldKey]?.toString().trim().length,
    );
    if (hasEmptyFields) return true;
    if (!hasEmptyFields && tasksListData.length) {
      const createdTasks = taskData?.id
        ? tasksListData.filter(taskObject => taskObject.id !== taskData?.id)
        : tasksListData;
      const currentTaskData = { ...taskData };
      delete currentTaskData.id;
      const stringifiedTasksObjects = createdTasks.map(taskObject => {
        const createdTaskObject = { ...taskObject };
        delete createdTaskObject.id;
        return JSON.stringify(createdTaskObject, Object.keys(createdTaskObject).sort());
      });
      const theSameTaskAlreadyCreated = stringifiedTasksObjects.includes(
        JSON.stringify(currentTaskData, Object.keys(currentTaskData).sort()),
      );
      setHasTheSameTask(theSameTaskAlreadyCreated);
      return theSameTaskAlreadyCreated;
    }
    if (taskData?.id) {
      const editableTask = tasksListData.find(task => task.id === taskData.id);
      return JSON.stringify(editableTask) === JSON.stringify(taskData);
    }
    return false;
  }, [taskData, tasksListData]);

  const closeDialog = () => setOpenDialog(false);

  const memoizedProviderValue = useMemo(
    () => ({
      addNewTask,
      editTask,
    }),
    [addNewTask, editTask],
  );

  return (
    <AddTaskContext.Provider value={memoizedProviderValue}>
      {openDialog && (
        <CustomDialog
          cancelButtonName="Cancel"
          cancelCallback={closeDialog}
          disableOnCloseByClickOutside
          disabledSubmit={isDisabledSave}
          openDialog={openDialog}
          submitButtonName="Save"
          submitCallback={applyTaskData}
          title={taskData.id ? 'Edit Task' : 'Add Task'}
        >
          <Content
            disabledDays={disabledWeekDays?.schema}
            loader={isLoadingConfigs}
            configData={configData}
            hasPredefinedData={hasPredefinedData}
            hasTheSameTask={hasTheSameTask}
            setTaskData={changeTaskData}
            taskData={taskData}
          />
        </CustomDialog>
      )}
      {children}
    </AddTaskContext.Provider>
  );
}

AddTaskProvider.propTypes = {
  children: oneOfType([instanceOf(Array), instanceOf(Object)]),
  hasPredefinedData: bool,
  tasksListData: instanceOf(Object),
  createTasksList: func,
  updateCarePlanTasks: func,
};
