export const taskInitialState = {
  categoryTypes: '',
  days: [],
  details: '',
  isCustom: false,
  levelOfAssistance: '',
  name: '',
  timeOfDays: [],
  visitTypes: [],
  priority: '',
};

export const noEmptyFields = ['categoryTypes', 'details', 'levelOfAssistance', 'name', 'priority'];

export const optionalEmptyFields = ['days', 'timeOfDays', 'visitTypes'];
