import { Box, Typography } from '@mui/material';
import moment from 'moment';
import CareProgramTableDaysSchedule from '../../../../pages/ClientDetails/views/CareProgram/components/CareProgramTableDaysSchedule';
import React from 'react';
import {
  ISO_DATE_ONLY_FORMAT,
  SHORT_DATE_FORMAT,
  backgroundColorSchemeMap,
  H12_TIME_FORMAT,
  H24_TIME_FORMAT,
  formatToPST,
} from '../../../../shared/constants';
import { COLORS } from '../../../../shared/styles/Theme';
import { getIconComponent } from '../../../../shared/utils/common';
import Badge from '../../../../components/Badge';
import VisitActions from './components/VisitActions';
import HistoryBulletList from '../../../../components/HistoryBulletList';

export const VISITS_CHANGES_TYPES = {
  delete: 'delete',
  edit: 'edit',
  changedStartingDate: 'changedStartingDate',
};

export const PAGE_SIZE = 10;

export const HISTORY_UPDATE_FORMAT = 'ddd D MMM YYYY, h:mm:ss A';

export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

export const UPDATE_PROGRAM_POPUPS = {
  addNewVisit: 'addNewVisit',
  careProgramSummary: 'careProgramSummary',
  effectiveDate: 'effectiveDate',
  programView: 'programView',
  removeProgram: 'removeProgram',
};

export const detailsButtons = [
  { name: 'Table', infoType: 'table' },
  { name: 'Calendar', infoType: 'calendar' },
  { name: 'History', infoType: 'history' },
];

export const visitsSummaryData = [
  {
    name: 'Weekly Visits',
    key: 'weeklyVisits',
  },
  {
    name: 'Weekly Hours',
    key: 'weeklyHours',
  },
  {
    name: 'Weekly Cost',
    key: 'WeeklyCost',
  },
  {
    name: 'Effective Hourly Rate',
    key: 'effectiveHourlyRate',
  },
];

export const hourlyRates = {
  FIFTY: 50,
  FIFTY_FIVE: 55,
  SIXTY: 60,
  SIXTY_FIVE: 65,
  SEVENTY: 70,
};

export const getEffectiveDateBadge = ({ dateEnd, dateStart }) => {
  const getBadgeLabel = () => {
    if (!dateStart && !dateEnd) {
      return '';
    }
    if (dateStart && !dateEnd) {
      return `${
        moment(dateStart, ISO_DATE_ONLY_FORMAT).isSameOrBefore(moment()) ? 'Started' : 'Starting'
      } ${moment(dateStart, ISO_DATE_ONLY_FORMAT).format(SHORT_DATE_FORMAT)}`;
    }
    if (dateEnd) {
      return `Ending ${moment(dateEnd, ISO_DATE_ONLY_FORMAT).format(SHORT_DATE_FORMAT)}`;
    }
    return '';
  };

  const getColorScheme = () => {
    if (!dateStart && !dateEnd) {
      return 'upcoming';
    }
    if (dateStart && !dateEnd && moment(dateStart, ISO_DATE_ONLY_FORMAT).isSameOrBefore(moment())) {
      return 'info';
    }
    if (dateStart && !dateEnd) {
      return 'active';
    }
    if (!dateStart && dateEnd) {
      return 'error';
    }
    if (dateStart && dateEnd) {
      return 'error';
    }
    return '';
  };

  return <Badge colorScheme={backgroundColorSchemeMap[getColorScheme()]} label={getBadgeLabel()} />;
};

export const getTableColumns = editMode => [
  {
    field: 'visitTypeDetails',
    headerName: 'Visit Type',
    flex: 1,
    maxWidth: 320,
    sortable: false,
    renderCell: ({ row, value }) => (
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ marginRight: '8px' }}>{row.updateId}.</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            {getIconComponent(value?.name)}
            <Typography variant="h5" sx={{ ml: '8px' }}>
              {value?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '8px',
              ml: '28px',
              gap: '10px',
            }}
          >
            <Typography variant="h5">
              {getEffectiveDateBadge({
                dateEnd: row?.effectiveDateEnd,
                dateStart: row?.effectiveDateStart,
              })}
            </Typography>
            <Box
              sx={{
                width: '2px',
                height: '2px',
                borderRadius: '50%',
                backgroundColor: COLORS.blue[500],
              }}
            />
            <Typography sx={{ color: COLORS.blue[500] }}>
              {row?.duration !== null ? `${row?.duration} min` : '0 min'}
            </Typography>
          </Box>
        </Box>
      </Box>
    ),
  },
  {
    field: 'days',
    headerName: 'Days of the Week',
    flex: 2,
    sortable: false,
    renderCell: ({ value }) => <CareProgramTableDaysSchedule selectedDays={value} />,
  },
  {
    field: 'arrivalWindow',
    headerName: 'Arrival Window',
    flex: 1,
    sortable: false,
    renderCell: ({ row }) => {
      const arrivalStart = row?.days[Object.keys(row?.days)[0]]['arrivalStart'];
      const arrivalEnd = row?.days[Object.keys(row?.days)[0]]['arrivalEnd'];
      return (
        <Typography>
          {moment(arrivalStart, H24_TIME_FORMAT).format(H12_TIME_FORMAT)}
          {arrivalStart === arrivalEnd
            ? ''
            : ` - ${moment(arrivalEnd, H24_TIME_FORMAT).format(H12_TIME_FORMAT)}`}
        </Typography>
      );
    },
  },
  ...(editMode
    ? [
        {
          field: '',
          headerName: '',
          sortable: false,
          width: 150,
          renderCell: ({ row }) => (
            <VisitActions visit={row} visitEditTypes={VISITS_CHANGES_TYPES} />
          ),
        },
      ]
    : []),
];

export const historyColumns = [
  {
    field: 'dateTime',
    headerName: 'Date & Time',
    sortable: false,
    minWidth: 280,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{formatToPST(params.row.dateChanged)}</Typography>
      </Box>
    ),
  },
  {
    field: 'changes',
    headerName: 'Changes',
    minWidth: 740,
    sortable: false,
    renderCell: params => <HistoryBulletList changes={params.row} />,
  },
  {
    field: 'user',
    headerName: 'User',
    sortable: false,
    width: 330,
    renderCell: params => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          height: '100%',
          paddingTop: '16px',
        }}
      >
        <Typography>{params.row.updatedBy}</Typography>
      </Box>
    ),
  },
];

export const events = [
  {
    duration: 60,
    title: 'Quick Assist Visit',
    type: 'quick assist',
    repeated: true,
    arrivalTimeStart: moment('2023-07-10T10:00:00').toDate(),
    start: moment('2023-07-10T11:00:00').toDate(),
  },
  {
    duration: 90,
    title: 'Quick Assist Visit',
    type: 'quick assist',
    repeated: true,
    arrivalTimeStart: moment('2023-07-11T14:00:00').toDate(),
    start: moment('2023-07-11T15:45:00').toDate(),
  },
  {
    duration: 60,
    title: 'Quick Assist Visit',
    type: 'quick assist',
    repeated: true,
    arrivalTimeStart: moment('2023-07-12T10:00:00').toDate(),
    start: moment('2023-07-12T11:00:00').toDate(),
  },
  {
    duration: 40,
    title: 'Quick Assist Visit',
    type: 'quick assist',
    arrivalTimeStart: moment('2023-07-13T10:00:00').toDate(),
    start: moment('2023-07-13T11:00:00').toDate(),
  },
  {
    duration: 70,
    title: 'Quick Assist Visit',
    type: 'quick assist',
    arrivalTimeStart: moment('2023-07-11T10:00:00').toDate(),
    start: moment('2023-07-11T11:00:00').toDate(),
  },
];

export const createVisitSteps = [
  'Visit Parameters',
  'Recurrent Availability',
  'Scheduling Conflicts',
];

export const createVisitInitialData = {
  arrivalWindowDuration: '60',
  days: [],
  duration: null,
  genderPreference: null,
  repeat: 'Weekly',
  preferredLanguages: [],
  preferredSkills: [],
  timeOfDays: [],
  visitType: {},
};

export const arrivalWindowDurationOptions = [
  { id: '0', name: '0' },
  { id: '15', name: '15' },
  { id: '30', name: '30' },
  { id: '45', name: '45' },
  { id: '60', name: '60' },
  { id: '90', name: '90' },
  { id: '120', name: '120' },
];

export const convertDaysToNames = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export const convertNumbersToDays = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 5,
};
