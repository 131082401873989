import { Box } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid';
import { useGetCarePlanHistoryQuery } from '../../../../../src/api/CarePlan/api';
import { useGetClientsProfileHistoryQuery } from '../../../../api/Clients/api';
import { useGetCareProgramHistoryQuery } from '../../../../api/CareProgram/api';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  HISTORY_UPDATE_FORMAT,
  SEARCH_INPUT_DEBOUNCE_DELAY,
} from '../../../../shared/constants';
import { useDebouncedCallback } from '../../../../shared/hooks/useDebounce';
import { mergeArraysWithActivity } from '../../../../shared/utils/common';
import Activity from '../../../../components/Activity';
import { INITIAL_ACTIVITY_FILTERS } from '../../../../components/Activity/constants';
import AlertContext from '../../../../components/Alert';
import { activityOptions } from './constants';

function ClientActivity() {
  const { id } = useParams();
  const apiRef = useGridApiRef();

  const [visitsFilters, setVisitsFilters] = useState(INITIAL_ACTIVITY_FILTERS);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const { setAlert } = useContext(AlertContext);

  const { data: profileData, isLoading: isProfileLoading, error: getProfileError } = useGetClientsProfileHistoryQuery(id);
  const { data: carePlanHistoryData, isLoading: isCarePlanLoading, error: getClientHistoryError } = useGetCarePlanHistoryQuery(id);
  const { data: careProgramData, isLoading: isCareProgramLoading, error: getCareProgramHistoryError } = useGetCareProgramHistoryQuery(id);
  
  useEffect(() => {
    if (getProfileError || getClientHistoryError || getCareProgramHistoryError) {
      const errorData =
        getProfileError || getClientHistoryError || getCareProgramHistoryError;
      setAlert({
        errorData,
        type: 'error',
      });
    }
  }, [
    getProfileError,
    getClientHistoryError,
    getCareProgramHistoryError,
    setAlert,
  ]);

  const mergedList = [
    ...mergeArraysWithActivity(profileData, 'Profile Updates'),
    ...mergeArraysWithActivity(careProgramData, 'Care Program'),
    ...mergeArraysWithActivity(carePlanHistoryData, 'Care Plan'),
  ];

const updatedEvents = mergedList
  ?.map((item, index) => {
    return {
      ...item,
      dateChanged: moment(item.dateChanged).format(HISTORY_UPDATE_FORMAT),
      id: index + 1,
    };
  })
  .filter(Boolean);

  const sortedUpdatedEvents = updatedEvents?.sort((a, b) => {
    const dateA = new Date(a.dateChanged);
    const dateB = new Date(b.dateChanged);
    return dateB - dateA;
  });

  useEffect(() => {
    setFilteredEvents(sortedUpdatedEvents);
    // eslint-disable-next-line
  }, [profileData, careProgramData, carePlanHistoryData]);

  useEffect(() => {
    setVisitsFilters((prevValue) => ({
      ...prevValue,
      activity: activityOptions.map((name) => name),
    }));
  }, [setVisitsFilters]);

  const debouncedSearch = useDebouncedCallback((e) => {
    setVisitsFilters({ ...visitsFilters, [e.target.name]: e.target.value });
  }, SEARCH_INPUT_DEBOUNCE_DELAY);

  const filterEvents = useCallback(() => {
    if (!updatedEvents) return [];
  
    return updatedEvents.filter((item) => {
      const { data, activity } = item;
  
      if (
        visitsFilters.activity &&
        !visitsFilters.activity?.includes(activity)
      ) {
        return false;
      }
  
      return data.some((change) => {
        const { oldValue, newValue } = change;
  
        const combinedValues = [
          ...(oldValue ? Object.values(oldValue) : []),
          ...(newValue ? Object.values(newValue) : []),
        ];
  
        return combinedValues.some((val) => {
          if (typeof val !== 'string') {
            val = String(val);
          }
          return val.toLowerCase().includes(visitsFilters.search.toLowerCase());
        });        
      });
    });
  }, [updatedEvents, visitsFilters]);  
  

  useEffect(() => {
    const filtered = filterEvents();
    setFilteredEvents(filtered);
    // eslint-disable-next-line
  }, [visitsFilters]);
  
  return (
    <Box sx={{ mt: '10px' }}>
      <Activity
        filterData={visitsFilters}
        setFilterData={setVisitsFilters}
        debouncedSearch={debouncedSearch}
        data={filteredEvents}
        activityOptions={activityOptions.map((name) => name)}
        apiRef={apiRef}
        client
        isLoading={isProfileLoading || isCarePlanLoading || isCareProgramLoading}
      />
    </Box>
  );
}

export default ClientActivity;
